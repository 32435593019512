import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Typography, useConfirmation } from '@hapvida/hapvida-core-components';

import { CompanyDetailMock } from '@services/CompanySetupService/dtos/GetCompanyDetailDTO';
import { UserService, AddAccountFormProps } from '@services/UserService';

import { useScreenLoading } from 'hooks/useScreenLoading';
import { toastifyApiErrors } from '@utils';
import { useValidModal } from 'hooks/useValidModal';
import { CompanySetupListRouter } from '@flows/private/companySetup/pages/CompanySetupList/router';

import { RegisterUserCompany } from './components/RegisterUserCompany';

export interface RegisterAccessProps {
  companyDetails?: CompanyDetailMock;
}

export const RegisterAccess = ({ companyDetails }: RegisterAccessProps) => {
  const { onScreenLoading } = useScreenLoading();
  const { showConfirmation } = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const navigateTo = useNavigate();
  const {
    isValidResponseServiceRef,
    validResponseService,
    invalidResponseService,
  } = useValidModal();

  const companyId = companyDetails?.id;

  const handleAccessAttachedSuccess = () => {
    showConfirmation({
      title: 'Acesso atrelado com sucesso',
      icon: 'check',
      type: 'success',
      confirmText: 'Prosseguir',
      onConfirm: () => navigateTo(CompanySetupListRouter.path),
      content: (
        <Typography variant="text">
          Enviamos um e-mail de confirmação para o usuário.
        </Typography>
      ),
    });
  };

  const onRegisterUserCompany = async (data: AddAccountFormProps) => {
    try {
      if (companyId) {
        onScreenLoading(true);
        const createAccount = new UserService();
        await createAccount.createUser({
          companyId,
          ...data,
        });
        validResponseService();
      }
    } catch (error) {
      invalidResponseService();
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      onScreenLoading(false);
      if (isValidResponseServiceRef.current === true) {
        handleAccessAttachedSuccess();
      }
    }
  };

  return (
    <RegisterUserCompany
      subtitle="Preencha os dados do administrador para o primeiro acesso ao Portal Empresas."
      titleForm="Detalhes do usuário administrador"
      title="Informações do usuário administrador"
      onSubmit={onRegisterUserCompany}
    />
  );
};

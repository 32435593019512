import { ButtonBase, styled } from '@mui/material';

export const CardButtonService = styled(ButtonBase)(() => ({
  justifyContent: 'space-between',
  background: '#FFFFFF',
  borderRadius: '8px',
  display: 'flex',
  padding: '16px',
  width: '100%',
  gap: '8px',
}));

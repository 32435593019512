import {
  DrawerFilterProvider,
  Header,
  useSidebar,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { CompanySetupListSubHeader } from './components';
import { CompanySetupListRouter } from './router';
import { CompanySetupListLayout } from './layout';

export function CompanySetupListPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <Header
        subHeader={<CompanySetupListSubHeader />}
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        onLogOut={logOut}
        user={user}
        route={{
          icon: CompanySetupListRouter.Icon,
          name: CompanySetupListRouter.name,
        }}
      >
        <CompanySetupListLayout />
      </Header>
    </DrawerFilterProvider>
  );
}

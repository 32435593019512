export const authCertificateServiceLinks = [
  {
    label: 'Hapvida',
    value:
      'https://webhap.hapvida.com.br/pls/webhap/pk_autentica_atestado_internet.login',
  },
  {
    label: 'CCG',
    value:
      'https://www.hapvida.com.br/pls/webccg/pk_autentica_atestado_internet.login',
  },
  {
    label: 'Clinipam',
    value:
      'https://sigo.sh.srv.br/pls/webcli/pk_autentica_atestado_internet.login',
  },
];

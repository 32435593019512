import { Mask } from '@hapvida/hapvida-core-components';

import { BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

const beneficiaryDependencyCode = (
  dependencyCode?: number,
  dependencyName?: string,
) => {
  if (dependencyCode && dependencyName) {
    return `${dependencyCode} - ${dependencyName}`;
  }
  return '-';
};

export const getBeneficiaryAdditionalInformationRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  const { dependencyCode, dependencyName, marriedAt } = beneficiary;

  return [
    {
      label: 'Código da dependência',
      value: beneficiaryDependencyCode(dependencyCode, dependencyName),
      xs: 4,
    },
    {
      label: 'Data casamento',
      value: Mask.date(marriedAt),
      xs: 8,
    },
  ];
};

import { Stack, Typography } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

import {
  getPartnerCompanyListRows,
  partnerCompanyListColumns,
} from '@flows/private/partnerCompany/pages/PartnerCompanyList/constants';
import { ListGrid } from '@components';
import { PartnerCompanyListMockProps } from 'infra/mock';
import { useMockPagination } from '@hooks';

interface PartnerCompanyListProps {
  partnerCompanyList: PartnerCompanyListMockProps;
}
export function PartnerCompanyList({
  partnerCompanyList,
}: Readonly<PartnerCompanyListProps>) {
  // TODO remover hook useMockPagination ao realizar a integração com a api
  const { currentPage, handlePageChange, paginatedData } = useMockPagination(
    partnerCompanyList.content,
    partnerCompanyList.totalPerPage,
  );
  const partnerCompanyListRows = getPartnerCompanyListRows(paginatedData);

  return (
    <Stack gap={3} paddingY={3} flex={1}>
      <Typography variant="subtitle1">
        Total de
        <Typography
          variant="subtitle1"
          display="inline"
          color={COLORS.PRIMARY.ORANGE.MAIN}
        >
          {` ${partnerCompanyList.total} contratos`}
        </Typography>
      </Typography>
      <ListGrid
        columns={partnerCompanyListColumns}
        rows={partnerCompanyListRows}
        paginationProps={{
          currentPage,
          handlePageChange,
          totalItems: partnerCompanyList.total,
          totalDescription: 'contratos',
          totalPages: Math.ceil(
            partnerCompanyList.total / partnerCompanyList.totalPerPage,
          ),
          totalPerPage: partnerCompanyList.totalPerPage,
        }}
        containerProps={{
          justifyContent: 'space-between',
          flex: 1,
        }}
      />
    </Stack>
  );
}

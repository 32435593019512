import { IAppRoute } from 'routes';

import { LinkExpired } from './index';

export const LinkExpiredRouter: IAppRoute = {
  Page: LinkExpired,
  path: '/link-expirado',
  name: 'Link Expirado',
  Icon: <></>,
  userTypes: [],
  disabled: false,
};

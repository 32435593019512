import {
  DrawerFilterProvider,
  Header,
  useSidebar,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { BeneficiaryListRouter } from '../BeneficiaryList/router';
import { BeneficiaryDetailsLayout } from './layout';
import { BeneficiaryDetailRouter } from './router';

export function BeneficiaryDetailsPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <Header
        subRoute={BeneficiaryListRouter.name}
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        onLogOut={logOut}
        user={user}
        route={{
          navigateBackPath: BeneficiaryListRouter.path,
          icon: BeneficiaryDetailRouter.Icon,
          name: BeneficiaryDetailRouter.name,
        }}
      >
        <BeneficiaryDetailsLayout />
      </Header>
    </DrawerFilterProvider>
  );
}

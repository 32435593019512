import { Paper, PaperProps, IconButton, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export function StyledCard({ ...props }: PaperProps) {
  return <Paper elevation={0} {...props} sx={{ p: 3, ...props.sx }} />;
}

export const OutlinedIconButton = styled(IconButton)(() => ({
  border: `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,
  color: COLORS.MONOCHROMATIC.GRAY4,
  borderRadius: '50%',
}));

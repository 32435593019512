import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { COLORS, Modal, SelectInput } from '@hapvida/hapvida-core-components';

import { changeFilesServiceLinks } from './constants';
import { ServiceCard } from '../ServiceCard';

interface ChangeFilesFormProps {
  redirectPortal: string;
}

export function ChangeFilesServiceCard() {
  const [openModal, setOpenModal] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<ChangeFilesFormProps>({
    mode: 'all',
    defaultValues: {
      redirectPortal: '',
    },
  });

  useEffect(() => {
    if (openModal) reset();
  }, [openModal]);

  const handleOpenChangeFilesService = (data: ChangeFilesFormProps) => {
    window.open(data.redirectPortal);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <ServiceCard title="Troca de arquivos" onClick={handleOpenModal} />
      <Modal
        mainAction={handleSubmit(handleOpenChangeFilesService)}
        handleCloseModal={handleCloseModal}
        mainButtonText="Acessar portal"
        disableMainAction={!isDirty}
        secondaryButtonText="Fechar"
        contentProps={{ gap: 1.5 }}
        title="Troca de arquivos"
        open={openModal}
        type="default"
        icon="alert"
      >
        <Typography variant="body2">
          A funcionalidade de troca de arquivos estará disponível em breve. No
          momento, você pode realizar a troca de arquivos em uma nova página.
        </Typography>

        <SelectInput
          label="Escolha para qual portal você gostaria de ser redirecionado:"
          options={changeFilesServiceLinks.map(redirectKey => ({
            value: redirectKey.value,
            key: redirectKey.label,
          }))}
          color={COLORS.MONOCHROMATIC.GRAY4}
          placeholder="Selecione"
          name="redirectPortal"
          paddingInput="8px"
          control={control}
          required
        />
      </Modal>
    </>
  );
}

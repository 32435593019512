import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from 'contexts/AuthContext';
import { IAppDynamicRoute } from 'routes';

import { CompanyDetailsPage } from './index';

export const CompanyDetailsRouter: IAppDynamicRoute = {
  Page: CompanyDetailsPage,
  path: '/dados-empresa/:id',
  dynamicPath: ({ id }) => `/dados-empresa/${id}`,
  name: '',
  isPrivate: true,
  Icon: <Icon name="home-work" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabled: false,
};

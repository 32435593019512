import React from 'react';

import IDragAndDropFile from '@hapvida/hapvida-core-components/src/components/DragAndDrop/dtos/IDragDropFile';

import ConfirmationLayout from './layout';

type ConfirmationProps = {
  onStepSubmit(): Promise<void>;
  movimentationFile: IDragAndDropFile;
  companyId: string;
};

const ConfirmationStep: React.FC<ConfirmationProps> = ({
  onStepSubmit,
  movimentationFile,
  companyId,
}) => {
  return (
    <ConfirmationLayout
      onStepSubmit={onStepSubmit}
      movimentationFile={movimentationFile}
      companyId={companyId}
    />
  );
};

export default ConfirmationStep;

import { useState } from 'react';

export function useMockPagination<T>(mock: T[], pageSize: number) {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedData = mock.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  return {
    paginatedData,
    currentPage,
    handlePageChange,
  };
}

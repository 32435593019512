import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { Header, useSidebar } from '@hapvida/hapvida-core-components';

import { CompanyListRouter } from '@flows/private/companies/pages/CompanyList/router';
import { HomeRouter } from '@flows/private/home/router';
import { CompanyStatusEnum } from '@constants';
import { useAuth } from 'hooks/useAuth';

import { CompanySetupListRouter } from '../CompanySetupList/router';
import { CompanySetupDetailsRouter } from './router';
import { CompanySetupDetailsLayout } from './layout';
import { useCompanySetupDetailsState } from './hooks';

export function CompanySetupDetailsPage() {
  const { companySetupDetailsState } = useCompanySetupDetailsState();
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { user, logOut } = useAuth();

  const companySetupDetailsData = companySetupDetailsState.data?.content;

  const navigate = useNavigate();

  useEffect(() => {
    const status = companySetupDetailsData?.status;

    const validStatusList = [
      CompanyStatusEnum.ACTIVE,
      CompanyStatusEnum.CANCELED,
      CompanyStatusEnum.PENDING_ACCESS,
    ];

    const statusRedirect = status && validStatusList.includes(status);

    if (statusRedirect) {
      navigate(HomeRouter.path);
    }
  }, [companySetupDetailsData?.status]);

  return (
    <Header
      subRoute={CompanyListRouter.name}
      toggleDrawer={toggleDrawer}
      drawerOpen={drawerOpen}
      onLogOut={logOut}
      user={user}
      route={{
        icon: CompanySetupDetailsRouter.Icon,
        name: companySetupDetailsData?.name?.legal,
        navigateBackPath: CompanySetupListRouter.path,
      }}
    >
      <CompanySetupDetailsLayout />
    </Header>
  );
}

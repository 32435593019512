import { IconName } from '@hapvida/hapvida-core-components';

import { ProfileResourceEnum } from '@constants';

interface ProfileResourceInfosProps {
  icon: IconName;
  title: string;
  permissionsResource: string[];
}

export const ProfileResourceInfos: Record<
  ProfileResourceEnum,
  ProfileResourceInfosProps
> = {
  [ProfileResourceEnum.MOVEMENT]: {
    title: 'Movimentações',
    icon: 'clipboard-text-outline',
    permissionsResource: ['Nova movimentação', 'Lista de movimentações'],
  },
  [ProfileResourceEnum.BENEFICIARIES]: {
    title: 'Beneficiários',
    icon: 'clipboard-pulse',
    permissionsResource: ['Exportar beneficiários', 'Detalhes do beneficiário'],
  },
  [ProfileResourceEnum.FINANCIAL]: {
    title: 'Financeiro',
    icon: 'money',
    permissionsResource: ['Lista de faturas', 'Baixar faturas'],
  },
  [ProfileResourceEnum.USERS]: {
    title: 'Usuários',
    icon: 'account-multiple',
    permissionsResource: ['Criar novo usuário', 'Excluir usuário'],
  },
  [ProfileResourceEnum.COMPANIES]: {
    title: 'Matriz e Coligadas',
    icon: 'home-work',
    permissionsResource: ['Lista de empresas', 'Editar empresas'],
  },
  [ProfileResourceEnum.MORE_SERVICES]: {
    title: 'Mais Serviços',
    icon: 'table-view',
    permissionsResource: ['Redirecionamento para ferramentas externas'],
  },
};

import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from '@contexts';
import { IAppRoute } from '@routes';

import { UserListPage } from '.';

export const UserListRouter: IAppRoute = {
  Page: UserListPage,
  path: '/usuarios',
  name: 'Usuários',
  isPrivate: true,
  Icon: <Icon name="account-multiple" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabled: false,
  disabledOnRoute: '/empresas',
};

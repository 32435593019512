import { DrawerFilterProvider, Header } from '@hapvida/hapvida-core-components';

import { useSidebar } from '@hapvida/hapvida-core-components/src/components/Sidebar';

import { useAuth } from 'hooks/useAuth';

import { BackofficeUserListLayout } from './layout';
import { BackofficeUserListRouter } from './router';

export function BackofficeUserListPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <Header
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        onLogOut={logOut}
        user={user}
        route={{
          icon: BackofficeUserListRouter.Icon,
          name: BackofficeUserListRouter.name,
        }}
      >
        <BackofficeUserListLayout />
      </Header>
    </DrawerFilterProvider>
  );
}

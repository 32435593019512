import { CompanyStatusEnum, COMPANY_STATUS_NAME } from '@constants';

export const companySetupDrawerFilterValues: {
  [filterName: string]: {
    label: string;
    value: string;
  }[];
} = {
  status: [
    {
      label: COMPANY_STATUS_NAME[CompanyStatusEnum.ACTIVE],
      value: CompanyStatusEnum.ACTIVE,
    },
    {
      label: COMPANY_STATUS_NAME[CompanyStatusEnum.REGISTER_ACCESS],
      value: CompanyStatusEnum.REGISTER_ACCESS,
    },
    {
      label: COMPANY_STATUS_NAME[CompanyStatusEnum.PENDING_ACCESS],
      value: CompanyStatusEnum.PENDING_ACCESS,
    },
    {
      label: COMPANY_STATUS_NAME[CompanyStatusEnum.CANCELED],
      value: CompanyStatusEnum.CANCELED,
    },
  ],
};

import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { CollapseCard } from '@hapvida/hapvida-core-components';

import { UserDetailsRouter } from '@flows/private/users/pages/UserDetails/router';
import { useAuth } from 'hooks/useAuth';

import { useHomeState } from '../../hooks';
import { Pendencies } from './components';

export function HomeAlerts() {
  const { pendenciesState } = useHomeState();
  const navigate = useNavigate();
  const { user } = useAuth();

  const outdatedData = user && (!user?.document || !user?.name);

  const goToEditAccountData = (id: string) => {
    navigate(UserDetailsRouter.dynamicPath({ id }));
  };

  if (!pendenciesState.isSuccess) {
    return null;
  }

  const { pendencies, pendenciesTotalCount } = pendenciesState.data;

  if (outdatedData) {
    return (
      <CollapseCard
        variant="info"
        insideCollapse={false}
        title="Atenção! Percebemos que seus dados estão desatualizados."
        subtitle="Por favor, complete os campos de nome, telefone e demais informações necessárias na página de detalhes do usuário. Clique aqui para atualizar agora. Obrigado!"
      >
        <Button
          onClick={() => goToEditAccountData(user?.id)}
          variant="contained"
          color="secondary"
        >
          Atualizar dados
        </Button>
      </CollapseCard>
    );
  }

  if (!outdatedData && pendenciesTotalCount > 0) {
    return (
      <CollapseCard
        variant="danger"
        title={`Existem ${pendenciesTotalCount} pendências em sua conta`}
        subtitle="Por aqui, você tem a possibilidade de ver de forma rápida todas as pendências da sua conta. Não deixe de resolvê-las."
      >
        <Pendencies pendencies={pendencies} />
      </CollapseCard>
    );
  }

  return null;
}

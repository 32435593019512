import { useMemo } from 'react';

import { ICoreRoute } from '@hapvida/hapvida-core-components';

import { MENU_LIST } from 'templates/private/components/Sidebar/components/SidebarRouteList/constants';
import { BeneficiaryListRouter } from '@flows/private/beneficiaries/pages/BeneficiaryList/router';
import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';
import { CompanyListRouter } from '@flows/private/companies/pages/CompanyList/router';
import { ServiceListRouter } from '@flows/private/services/pages/ServiceList/router';
import { UserListRouter } from '@flows/private/users/pages/UserList/router';
import BillListRouter from '@flows/private/financial/pages/List/router';
import { HomeRouter } from '@flows/private/home/router';
import { CompanyStatusEnum } from '@constants';
import { useAuth } from 'hooks/useAuth';

export function useRoutes() {
  const { selectedCompany, user } = useAuth();

  const companyPendingAccessDisabledRoutes: string[] = [
    BillListRouter.path,
    MovementListRouter.path,
    BeneficiaryListRouter.path,
  ];

  const allMenuRoutes: string[] = [
    BillListRouter.path,
    MovementListRouter.path,
    BeneficiaryListRouter.path,
    HomeRouter.path,
    UserListRouter.path,
    ServiceListRouter.path,
    CompanyListRouter.path,
  ];

  const isCompanyActive =
    selectedCompany && selectedCompany.status === CompanyStatusEnum.ACTIVE;

  const isCompanyPendingAccess =
    selectedCompany &&
    selectedCompany.status === CompanyStatusEnum.PENDING_ACCESS;

  const verifyDisabledRoutes = () => {
    if (isCompanyActive) {
      return [];
    }

    if (isCompanyPendingAccess) {
      return companyPendingAccessDisabledRoutes;
    }

    return allMenuRoutes;
  };

  const disabledRoutes = verifyDisabledRoutes();

  const handleRouteDisabled = (route?: ICoreRoute): boolean => {
    if (
      (route as ICoreRoute)?.path &&
      disabledRoutes?.find(el => el === (route as ICoreRoute)?.path)
    ) {
      return true;
    }

    if (route?.disabled) {
      return true;
    }

    if (route?.disabledOnRoute) {
      const currentRoute = window.location.pathname;

      return currentRoute.includes(route.disabledOnRoute);
    }

    return false;
  };

  const filteredGroupRoutes = useMemo(
    () =>
      selectedCompany
        ? MENU_LIST.map(({ routes, ...rest }) => ({
            ...rest,
            routes: routes?.filter(
              ({ userTypes }) =>
                user?.companyType && userTypes.includes(user.companyType),
            ),
          })).filter(
            ({ routes, modal }) =>
              (routes && routes?.length > 0) ||
              (user?.companyType &&
                modal?.userTypes.includes(user.companyType)),
          )
        : [],
    [user?.companyType, selectedCompany],
  );

  return { handleRouteDisabled, filteredGroupRoutes };
}

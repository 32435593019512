import { Typography } from '@mui/material';

import { USER_PROFILE_NAME, UserProfileEnum } from '@constants';

import { PermissionItem } from './PermissionItem';
import { Container } from './styles';

interface PermissionsListProps {
  userProfile: UserProfileEnum;
}

export function PermissionsList({
  userProfile,
}: Readonly<PermissionsListProps>) {
  return (
    <Container elevation={0}>
      <Typography variant="h6">Módulos e permissões</Typography>

      <Typography variant="body2">
        Essas são as permissões disponíveis para o acesso{' '}
        <strong>{USER_PROFILE_NAME[userProfile]}:</strong>
      </Typography>

      <PermissionItem
        text="Todas as informações estarão disponíveis."
        title="Página inicial"
        iconName="home"
      />

      <PermissionItem
        text="Todas as informações dos beneficiários estarão disponíveis."
        iconName="clipboard-pulse"
        title="Meus beneficiários"
      />

      <PermissionItem
        text="Todas as informações das movimentações estarão disponíveis."
        iconName="clipboard-text-outline"
        title="Minhas movimentações"
      />

      <PermissionItem
        text="Todas as informações das faturas das empresas estarão disponíveis."
        title="Financeiro"
        iconName="money"
      />

      <PermissionItem
        text="Todas as informações sobre os usuários estarão disponíveis."
        iconName="account-multiple"
        title="Usuários"
      />

      <PermissionItem
        text="Todas as informações sobre as empresas do grupo estarão disponíveis."
        title="Empresas"
        iconName="home-work"
      />
    </Container>
  );
}

import { Header, useSidebar } from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { CompanyListRouter } from '../CompanyList/router';
import { CompanyDetailsRouter } from './router';
import { useCompanyDetailsState } from './hooks';
import { CompanyDetailsLayout } from './layout';

export function CompanyDetailsPage() {
  const { companyDetailsState } = useCompanyDetailsState();
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { user, logOut } = useAuth();

  return (
    <Header
      subRoute={CompanyListRouter.name}
      toggleDrawer={toggleDrawer}
      drawerOpen={drawerOpen}
      onLogOut={logOut}
      user={user}
      route={{
        icon: CompanyDetailsRouter.Icon,
        name: companyDetailsState.data?.company?.name?.legal,
        navigateBackPath: CompanyListRouter.path,
      }}
    >
      <CompanyDetailsLayout />
    </Header>
  );
}

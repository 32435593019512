import { Box, styled as muiStyled, Stack } from '@mui/material';

export const PageContainer = muiStyled(Box)(() => ({
  display: 'flex',
}));

export const MainContent = muiStyled(Box)(() => ({
  flexGrow: 1,
  minWidth: 0,
}));

export const StyledStack = muiStyled(Stack)`
	flex-direction: column;
	position: relative;
	overflow-y: auto;
	display: flex;
	height: 100vh;
	flex: 1;
`;

import { Typography } from '@mui/material';
import { useState } from 'react';
import { FormState, UseFormHandleSubmit, UseFormReset } from 'react-hook-form';

import { Button, Modal } from '@hapvida/hapvida-core-components';

import { CircularProgress } from '@hapvida/hapvida-core-components/src/components/CircularProgress';

import { UserForm } from '@flows/private/users/pages/UserDetails/components/UserEditForm/types';
import { useUserDetailsState } from '@flows/private/users/pages/UserDetails/hooks';
import { useUserActionsState } from '@flows/private/users/pages/UserDetails/components/UserEditForm/hooks';
import { UserActionsOptionsEnum } from '@flows/private/users/pages/UserDetails/components/UserEditForm/constants/userActionsOptions';

import { useEditUser } from './hooks';

interface SubmitFormUserButtonProps {
  formState: FormState<UserForm>;
  handleSubmit: UseFormHandleSubmit<UserForm>;
  reset: UseFormReset<UserForm>;
}

export function SubmitFormUserButton({
  formState,
  handleSubmit,
  reset,
}: Readonly<SubmitFormUserButtonProps>) {
  const [updateConfirmationOpen, setUpdateConfirmationOpen] = useState(false);
  const [updatedUserSuccessfully, setUpdatedUserSuccessfully] = useState(false);

  const { userDetailsState } = useUserDetailsState();

  const { getIsDisabled, getIsLoading } = useUserActionsState();

  const {
    handleUpdateUser,
    handleCloseUpdatedSuccessfully,
    updatedSuccessfullyOpen,
  } = useEditUser({ formState });

  if (!userDetailsState.isSuccess) {
    return null;
  }

  const userData = userDetailsState.data.content;

  const toggleUpdatedUserSuccessfully = () =>
    setUpdatedUserSuccessfully(!updatedUserSuccessfully);
  const toggleUpdateConfirmation = () =>
    setUpdateConfirmationOpen(!updateConfirmationOpen);

  const { dirtyFields, isDirty, isValid } = formState;

  const isDisabled =
    getIsDisabled(UserActionsOptionsEnum.EDIT) || !isValid || !isDirty;

  const isLoading = getIsLoading(UserActionsOptionsEnum.EDIT);

  const handleSubmitForm = async (data: UserForm) => {
    if (data.email !== userData?.email) {
      toggleUpdateConfirmation();
    } else {
      const hasUpdated = await handleUpdateUser(data);
      if (hasUpdated) {
        if (!dirtyFields.email) {
          toggleUpdatedUserSuccessfully();
        }
        reset(data);
      }
    }
  };

  const handleConfirmedUpdate = () => {
    handleSubmit(handleUpdateUser)();
    toggleUpdateConfirmation();
  };

  return (
    <>
      <Button
        disabled={isDisabled || isLoading}
        endIcon={isLoading ? <CircularProgress size={16} /> : undefined}
        variant="contained"
        color="success"
        type="button"
        onClick={handleSubmit(handleSubmitForm)}
      >
        Atualizar acesso
      </Button>
      <Modal
        handleCloseModal={toggleUpdatedUserSuccessfully}
        title="Dados do usuário editados com sucesso"
        mainAction={toggleUpdatedUserSuccessfully}
        open={updatedUserSuccessfully}
        mainButtonText="Fechar"
        type="success"
        icon="check"
      >
        <Typography variant="body2">
          Atualizamos o usuário selecionado com as novas informações editadas
        </Typography>
      </Modal>
      <Modal
        title="Edição do e-mail do usuário com acesso pendente"
        handleCloseModal={toggleUpdateConfirmation}
        mainAction={handleConfirmedUpdate}
        open={updateConfirmationOpen}
        secondaryButtonText="Fechar"
        mainButtonText="Continuar"
        icon="alert"
      >
        <Typography variant="body2">
          Percebemos que o acesso desse usuário ainda está pendente e você está
          tentando editar o e-mail do usuário. Isso gerará o reenvio de um novo
          e-mail de primeiro acesso para o endereço atualizado. Deseja
          continuar?
        </Typography>
      </Modal>
      <Modal
        handleCloseModal={handleCloseUpdatedSuccessfully}
        title="Confirmação de edição de e-mail enviada"
        mainAction={handleCloseUpdatedSuccessfully}
        open={updatedSuccessfullyOpen}
        mainButtonText="Fechar"
        type="success"
        icon="check"
      >
        <Typography variant="body2">
          Um alerta de confirmação foi enviado para o novo e-mail do usuário.
          Peça para o usuário verificar a caixa de entrada para prosseguir com o
          primeiro acesso.
        </Typography>
      </Modal>
    </>
  );
}

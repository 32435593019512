import { Stack } from '@mui/material';

import { partnerCompanyListMock } from 'infra/mock';

import { PartnerCompanyList } from './components';

export function PartnerCompanyListLayout() {
  return (
    <Stack minHeight="100%">
      <PartnerCompanyList partnerCompanyList={partnerCompanyListMock} />
    </Stack>
  );
}

import { ButtonBaseProps, Typography } from '@mui/material';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

import { CardButtonService } from './styles';

interface ServiceCardProps extends ButtonBaseProps {
  title: string;
}

export function ServiceCard({ title, ...buttonBaseProps }: ServiceCardProps) {
  return (
    <CardButtonService {...buttonBaseProps}>
      <Typography variant="body2">{title}</Typography>
      <Icon
        name="open-new-window"
        size={28}
        color={COLORS.MONOCHROMATIC.GRAY4}
      />
    </CardButtonService>
  );
}

import { Mask } from '@hapvida/hapvida-core-components';

import { BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

export const getBeneficiaryInactiveRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  const { holderInactive, cancellationAt } = beneficiary;
  const contributionAt = holderInactive?.contributionAt;
  return [
    {
      label: 'Inativo Beneficiário Titular',
      value: '-',
      xs: 4,
    },
    {
      label: 'Data Inicio Inatividade',
      value: Mask.date(cancellationAt),
      xs: 4,
    },
    {
      label: 'Data de Inicio de Contribuição',
      value: Mask.date(contributionAt),
      xs: 4,
    },
  ];
};

import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import CompanyService from '@services/CompanyService';
import { CompaniesListProps } from '@services/CompanyService/dtos';

import { useFilterParams } from '@hooks';
import { toastifyApiErrors } from '@utils';
import { useAuth } from 'hooks/useAuth';

export function useCompaniesListState() {
  const { currentPage, searchParam } = useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useAuth();

  const companyId = selectedCompany?.id ?? '';

  const companyService = useMemo(() => new CompanyService(), []);

  const companyListState = useQuery<CompaniesListProps>({
    queryKey: ['companyListState', searchParam, currentPage, companyId],
    queryFn: () =>
      companyService.fetchCompaniesList({
        id: companyId,
        ...searchParam,
        offset: 10 * (currentPage - 1),
        limit: 10,
      }),
    enabled: Boolean(companyId),
    onError: (error: unknown) => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshCompanyListStates = () => {
    companyListState.remove();

    companyListState.refetch();
  };

  return { companyListState, refreshCompanyListStates };
}

import { Grid } from '@mui/material';

import {
  AuthCertificateServiceCard,
  ChangeFilesServiceCard,
  FinancialRedirectCard,
} from './components';

export function ServiceListLayout() {
  return (
    <Grid container spacing={3} py={3}>
      <Grid item xs={12} md={6}>
        <ChangeFilesServiceCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <AuthCertificateServiceCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <FinancialRedirectCard />
      </Grid>
    </Grid>
  );
}

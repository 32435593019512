import { IAppRoute } from 'routes';

import PasswordRecoveryPageStep from '.';

const PasswordRecoveryRouter: IAppRoute = {
  Page: PasswordRecoveryPageStep,
  path: '/recuperacao-de-senha',
  name: 'Recuperação de senha',
  Icon: <></>,
  userTypes: [],
  disabled: false,
};

export default PasswordRecoveryRouter;

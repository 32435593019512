import { TableBody, Box } from '@mui/material';

import { COLORS } from '../../../themes/colors';

import BaseTable, { TableViewProps } from '../../BaseTable';
import { PulsarAnimationLoading } from '../../PulsarAnimation';

import { Container, Message } from './styles';

import Rows from './components/Rows';
import { TableOrderParams } from '../../../constants';

type TableHeadParams = {
  id: number;
  label: string;
  name: string;
  lastLabel?: string;
  cellWidth?: number | string;
};

type Dependent = {
  id: number | string;
  itHasError?: boolean;
  errorMessage?: string;
  components: object;
};

type Data = {
  id: number | string;
  main: {};
  itHasError?: boolean;
  errorMessage?: string;
  dependents: Dependent[];
};

interface CollapseProps {
  loading?: boolean;
  loadingMessage?: string;

  data: Data[];
  noDataImage?: string;
  noDataMessage?: string;

  from?: number | string;
  to?: number | string;
  count?: number;

  currentPage?: number;

  TableHeadColumns: TableHeadParams[];
  tableOrder?: TableOrderParams;

  onOrderChange?(params: TableOrderParams): void;
  onPageChange?(event: unknown, newPage: number): void;
  onEdit?: (id: string) => void;
  onEditChild?: (id: string) => void;

  width?: string;
  hasCellAction?: boolean;
  baseTableProps?: Omit<
    TableViewProps,
    'from' | 'to' | 'count' | 'TableHeadColumns'
  >;

  showFooter?: boolean;
  rowColor?: string;
}

const Collapse = ({
  data,
  TableHeadColumns,
  tableOrder,
  loading,
  loadingMessage = 'Loading...',
  noDataMessage = 'Sem dados',
  noDataImage,
  onOrderChange,
  onPageChange,
  baseTableProps,
  onEdit,
  onEditChild,
  from,
  to,
  count,
  width,
  hasCellAction,
  showFooter,
  rowColor,
  currentPage = 1,
}: CollapseProps) => {
  return (
    <>
      {loading && (
        <Container>
          <PulsarAnimationLoading
            width="30px"
            height="30px"
            color={COLORS.PRIMARY.ORANGE.MAIN}
          />
          <br />
          <Message>{loadingMessage}</Message>
        </Container>
      )}

      {!loading && data.length === 0 && (
        <Container>
          <Box sx={{ marginBottom: 5 }}>
            <img src={noDataImage} alt="Sem dados" />
          </Box>
          <Message>{noDataMessage}</Message>
        </Container>
      )}

      {!loading && data.length > 0 && (
        <BaseTable
          {...baseTableProps}
          width={width}
          count={count ?? 0}
          from={from ?? 0}
          to={to ?? 0}
          currentPage={currentPage}
          tableOrder={tableOrder}
          onOrderChange={onOrderChange}
          onPageChange={onPageChange}
          TableHeadColumns={TableHeadColumns}
          hasCellAction={hasCellAction}
          showFooter={showFooter}
        >
          <TableBody>
            {data &&
              data.map(item => (
                <Rows
                  key={item.id}
                  item={item}
                  TableHeadColumns={TableHeadColumns}
                  onEdit={onEdit}
                  onEditChild={onEditChild}
                  hasCellAction={hasCellAction}
                  rowColor={rowColor}
                />
              ))}
          </TableBody>
        </BaseTable>
      )}
    </>
  );
};

export default Collapse;

import {
  FilterKeyEnum,
  DrawerFilter,
  Gender,
} from '@hapvida/hapvida-core-components';

import { useCompany } from '@hooks';

export function OrganizationalGroupDrawerFilter() {
  const { loadCompanyFilterList } = useCompany();

  return (
    <DrawerFilter
      filterKey={FilterKeyEnum.ORGANIZATIONAL_GROUP}
      searchFieldProps={{
        placeholder: 'Empresa, CNPJ ou contrato',
      }}
      loadNextPage={loadCompanyFilterList}
      title="Matriz e coligadas"
      gender={Gender.Feminine}
      label="empresas"
      filters={[]}
    />
  );
}

import { FC, ReactNode, useState } from 'react';
import { Typography } from '@hapvida/hapvida-core-components';

import {
  Image,
  StyledPaper,
  LightTooltip,
  MessageWrapper,
  StyledTypography,
  StyledSubTitle,
} from './styles';
import { Divider, Stack } from '@mui/material';
import Icon from '../Icon';
import { COLORS } from '../../themes/colors';

interface NavigationCardProps {
  enableImage: string;
  disabledImage: string;
  messageOne?: string | ReactNode;
  messageTwo?: string | ReactNode;
  disabled?: boolean;
  disabledMessageOne?: ReactNode;
  disabledMessageTwo?: ReactNode;
  handlerAction?: () => void;
  fullWidth?: boolean;
  enableImage2x?: string;
  disabledImage2x?: string;
}

const NavigationCard: FC<NavigationCardProps> = ({
  enableImage,
  disabledImage,
  messageOne,
  messageTwo,
  disabled = false,
  disabledMessageOne,
  disabledMessageTwo,
  handlerAction,
  fullWidth = false,
  enableImage2x,
  disabledImage2x,
}) => {
  const [tooltipOpenState, setTooltipOpenState] = useState<boolean>(false);

  const srcImage = disabled ? disabledImage : enableImage;
  const srcSetImage = disabled ? disabledImage2x : enableImage2x;
  const openTooltip =
    !!(disabledMessageOne || disabledMessageTwo) && tooltipOpenState;

  return (
    <LightTooltip
      open={openTooltip}
      title={
        <Stack
          gap={1}
          sx={{
            padding: '25px 20px',
            background: 'white',
            borderRadius: '8px',
          }}
        >
          {disabledMessageOne && (
            <Stack direction="row" gap={1} alignItems="center">
              <Icon
                name="alert"
                color={COLORS.SECONDARY.YELLOW['+1']}
                size={32}
              />
              <Divider orientation="vertical" flexItem />
              <Typography variant="text">{disabledMessageOne}</Typography>
            </Stack>
          )}
          {disabledMessageTwo && (
            <Typography variant="text">{disabledMessageTwo}</Typography>
          )}
        </Stack>
      }
      arrow
      disableHoverListener={!disabled}
    >
      <StyledPaper
        disabled={disabled}
        onClick={() => !disabled && handlerAction && handlerAction()}
        onMouseOver={() => disabled && setTooltipOpenState(true)}
        onMouseLeave={() => setTooltipOpenState(false)}
        fullWidth={fullWidth}
      >
        <Stack alignItems="center">
          <Image src={srcImage} srcSet={srcSetImage} />
        </Stack>
        <MessageWrapper fullWidth={fullWidth}>
          {messageOne && (
            <StyledTypography disabled={disabled} variant="subtitle1">
              {messageOne}
            </StyledTypography>
          )}
          {messageTwo && (
            <StyledSubTitle disabled={disabled} variant="text">
              {messageTwo}
            </StyledSubTitle>
          )}
        </MessageWrapper>
      </StyledPaper>
    </LightTooltip>
  );
};

export default NavigationCard;

import { IAppRoute } from 'routes';

import { SalesChannelPage } from '.';

export const SalesChannelRouter: IAppRoute = {
  path: '/canal-de-venda',
  name: 'Canal de venda',
  Page: SalesChannelPage,
  isPrivate: true,
  disabled: false,
  userTypes: [],
  Icon: <></>,
};

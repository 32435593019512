import TableCell from '@mui/material/TableCell';
import { COLORS } from '../../../../../themes/colors';

import Icon from '../../../../Icon';

import { ErrorRow, ErrorMessageBox, Message } from './styles';

interface ErrorLineProps {
  colspan: number;
  errorMessage: string;
}

const ErrorLine = ({ colspan, errorMessage }: ErrorLineProps) => {
  return (
    <ErrorRow>
      <TableCell colSpan={colspan + 1}>
        <ErrorMessageBox>
          <Icon name="alert-circle" color={COLORS.ERROR.MAIN} size={24} />
          <Message>{errorMessage}</Message>
        </ErrorMessageBox>
      </TableCell>
    </ErrorRow>
  );
};

export default ErrorLine;

import {
  SummaryCardNew,
  Loading,
  COLORS,
} from '@hapvida/hapvida-core-components';

import {
  BENEFICIARIES_SUMMARY_ITEMS,
  BENEFICIARY_TYPE_COLOR,
  BeneficiaryTypeEnum,
} from '@constants';

import { useBeneficiariesState } from '../../hooks';

const TOTAL_BENEFICIARIES_SUMMARY_ITEMS = [
  {
    color: BENEFICIARY_TYPE_COLOR.Retired,
    key: 'totalBeneficiaries',
    label: 'Total de beneficiários',
  },
  ...BENEFICIARIES_SUMMARY_ITEMS,
];

export function BeneficiariesSummaryCard() {
  const { beneficiariesSummaryState } = useBeneficiariesState();

  if (beneficiariesSummaryState?.isLoading) {
    return (
      <SummaryCardNew.Container success>
        <Loading minHeight={64} alignItems="center" justifyContent="center" />
      </SummaryCardNew.Container>
    );
  }

  if (beneficiariesSummaryState.isError) {
    return null;
  }

  const summaryValues = {} as Record<
    BeneficiaryTypeEnum | 'totalBeneficiaries',
    number
  >;

  Object.values(BeneficiaryTypeEnum).forEach(beneficiaryType => {
    summaryValues[beneficiaryType] = 0;
  });

  beneficiariesSummaryState.data.beneficiaries.forEach(beneficiary => {
    summaryValues[beneficiary.type] += beneficiary.totalBeneficiaries;
  });

  summaryValues.totalBeneficiaries = Object.values(summaryValues).reduce(
    (acc, curr) => acc + curr,
    0,
  );

  return (
    <SummaryCardNew.Container success>
      <SummaryCardNew.Content
        perLine={TOTAL_BENEFICIARIES_SUMMARY_ITEMS.length}
        startIconColor={COLORS.SECONDARY.GREEN['+1']}
        summary={TOTAL_BENEFICIARIES_SUMMARY_ITEMS}
        summaryValues={summaryValues}
        startIconName="heart"
        pillColor
      />
    </SummaryCardNew.Container>
  );
}

import { Collapse } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { useFilterParams } from '@hooks';
import { MOVEMENTS_PARAMS_ID } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/constants';

import { TableContainer, TableTitle } from './styles';
import { beneficiaryMovementListTableHeadColumns } from './constants';
import { getBeneficiaryMovementListTableRows } from './utils';
import { useBeneficiaryMovementsState } from './hooks';

export function BeneficiaryMovementsCard() {
  const { beneficiaryMovementsState } = useBeneficiaryMovementsState();

  const beneficiaryMovements = beneficiaryMovementsState.data;

  const { tableOrder, handleTableOrderChange, handlePageChange, currentPage } =
    useFilterParams({ paramsId: MOVEMENTS_PARAMS_ID });

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  if (!beneficiaryMovementsState.isSuccess) {
    return null;
  }

  if (!beneficiaryMovements || beneficiaryMovements.movements?.length === 0)
    return null;

  const beneficiaryMovementListTableRows = getBeneficiaryMovementListTableRows(
    beneficiaryMovements.movements,
  );

  return (
    <TableContainer>
      <TableTitle>Movimentações</TableTitle>
      <Collapse
        count={
          beneficiaryMovements
            ? Math.ceil(beneficiaryMovements.total / 10)
            : undefined
        }
        from={
          (currentPage - 1) * 10 + (beneficiaryMovements?.movements.length ?? 0)
        }
        to={beneficiaryMovements?.total}
        currentPage={currentPage}
        data={beneficiaryMovementListTableRows}
        TableHeadColumns={beneficiaryMovementListTableHeadColumns}
        onOrderChange={handleTableOrderChange}
        onPageChange={handleChangePage}
        tableOrder={tableOrder}
        rowColor={COLORS.MONOCHROMATIC.GRAY1}
      />
    </TableContainer>
  );
}

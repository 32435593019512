import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import CompanyService from '@services/CompanyService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from 'hooks/useAuth';

export function useCompanyDetailsState() {
  const { id = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useAuth();

  const companyId = selectedCompany?.id ?? '';

  const companyService = useMemo(() => new CompanyService(), []);

  const companyDetailsState = useQuery({
    queryKey: ['companyDetailsState', id],
    queryFn: () =>
      companyService.fetchCompanyDetail({
        id,
      }),
    enabled: Boolean(id),
    onError: (error: unknown) => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const companyDetailsSummaryState = useQuery({
    queryKey: ['companyDetailsSummaryState', companyId, id],
    queryFn: () =>
      companyService.fetchCompanyBeneficiaryNumbers({
        companyId,
        afiliateId: id,
      }),
    enabled: Boolean(companyId) && Boolean(id) && companyDetailsState.isSuccess,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshCompanyDetailsStates = () => {
    companyDetailsState.remove();
    companyDetailsSummaryState.remove();

    companyDetailsState.refetch();
    companyDetailsSummaryState.refetch();
  };

  return {
    companyDetailsState,
    companyDetailsSummaryState,
    refreshCompanyDetailsStates,
  };
}

import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormState } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { UserService } from '@services/UserService';

import { toastifyApiErrors } from '@utils';
import { UserForm } from '@flows/private/users/pages/UserDetails/components/UserEditForm/types';
import { useUserActionsState } from '@flows/private/users/pages/UserDetails/components/UserEditForm/hooks';
import { UserActionsOptionsEnum } from '@flows/private/users/pages/UserDetails/components/UserEditForm/constants/userActionsOptions';
import { UserQueryKeysEnum } from '@constants';

interface EditUserProps {
  formState: FormState<UserForm>;
}

export function useEditUser({
  formState: { dirtyFields },
}: Readonly<EditUserProps>) {
  const [updatedSuccessfullyOpen, setUpdatedSuccessfullyOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { changeCurrentAction } = useUserActionsState();
  const queryClient = useQueryClient();

  const handleCloseUpdatedSuccessfully = () =>
    setUpdatedSuccessfullyOpen(false);

  const handleUpdateUser = useCallback(
    async (data: UserForm) => {
      try {
        changeCurrentAction(UserActionsOptionsEnum.EDIT);
        const values = {
          document: data.document,
          profile: data.profile,
          email: data.email,
          phone: data.phone,
          name: data.name,
        };

        if (id) {
          const userService = new UserService();

          await userService.updateUser(id, values);

          const usersQueryKey = [UserQueryKeysEnum.USERS_STATE];
          queryClient.removeQueries({ queryKey: usersQueryKey, exact: true });
          await queryClient.refetchQueries({
            queryKey: usersQueryKey,
          });

          const userDetailsQueryKey = [UserQueryKeysEnum.USER_DETAILS_STATE];
          queryClient.removeQueries({
            queryKey: userDetailsQueryKey,
            exact: true,
          });
          await queryClient.refetchQueries({
            queryKey: userDetailsQueryKey,
          });

          if (dirtyFields.email) setUpdatedSuccessfullyOpen(true);

          return true;
        }
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      } finally {
        changeCurrentAction(undefined);
      }
      return false;
    },
    [enqueueSnackbar, id, changeCurrentAction, dirtyFields],
  );

  return {
    handleCloseUpdatedSuccessfully,
    updatedSuccessfullyOpen,
    handleUpdateUser,
  };
}

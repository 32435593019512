import { DrawerFilterProvider, Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes';
import { UserType } from '@contexts';

import { MovementDetailsPage } from './index';

const MovimentationDetailRouter: IAppDynamicRoute = {
  Page: () => (
    <DrawerFilterProvider>
      <MovementDetailsPage />
    </DrawerFilterProvider>
  ),
  path: '/movimentacoes/:movementId',
  dynamicPath: ({ movementId }) => `/movimentacoes/${movementId}`,
  Icon: <Icon name="clipboard-text-outline" size={32} />,
  userTypes: [UserType.Company, UserType.Backoffice],
  name: 'Movimentações',
  isPrivate: true,
};

export default MovimentationDetailRouter;

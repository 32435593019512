import { Stack, Typography } from '@mui/material';

import { RadioInput, COLORS } from '@hapvida/hapvida-core-components';

import { RadioWrapper } from './styles';
import { ModalContentProps, ExportTypes } from './types';

export function ModalContent({
  hasProcessedWithReview,
  control,
  totalLives,
  reviewLives,
}: ModalContentProps) {
  return (
    <Stack paddingTop={0.5} gap={3}>
      {hasProcessedWithReview ? (
        <>
          <Typography variant="body2">
            Selecione os beneficiários de acordo com o que achar necessário.
            Lembre-se que sua exportação irá gerar um arquivo de extensão{' '}
            <b>.csv</b>
          </Typography>

          <RadioInput
            control={control}
            name="exportType"
            options={[
              {
                value: ExportTypes.ALL_LIVES.VALUE,
                key: ExportTypes.ALL_LIVES.KEY,
                element: (
                  <RadioWrapper>
                    <Typography variant="body2">
                      Exportar todos os beneficiários
                    </Typography>
                    <Typography variant="smallText">
                      <Typography fontWeight={700} variant="smallText">
                        {totalLives}
                      </Typography>{' '}
                      beneficiários
                    </Typography>
                  </RadioWrapper>
                ),
              },
              {
                value: ExportTypes.ONLY_REVIEW_LIVES.VALUE,
                key: ExportTypes.ONLY_REVIEW_LIVES.KEY,
                element: (
                  <RadioWrapper>
                    <Typography variant="body2">
                      Exportar apenas críticas
                    </Typography>
                    <Typography variant="smallText">
                      <Typography
                        variant="smallText"
                        color={COLORS.SECONDARY.RED['+1']}
                        fontWeight={700}
                      >
                        {reviewLives}
                      </Typography>{' '}
                      {reviewLives === 1
                        ? 'beneficiário criticado'
                        : 'beneficiários criticados'}
                    </Typography>
                  </RadioWrapper>
                ),
              },
            ]}
          />
        </>
      ) : (
        <Typography variant="body2">
          Vamos exportar os beneficiários do seu arquivo na extensão
          <b> .csv</b>
        </Typography>
      )}
    </Stack>
  );
}

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export enum BeneficiaryTypeEnum {
  HOLDER = 'Holder',
  RESPONSIBLE = 'Responsible',
  DEPENDENT = 'Dependent',
  RETIRED = 'Retired',
  AGGREGATE = 'Aggregate',
  FIRED = 'Fired',
}

export const BENEFICIARY_TYPE_NAME: Record<BeneficiaryTypeEnum, string> = {
  [BeneficiaryTypeEnum.RESPONSIBLE]: 'Responsável',
  [BeneficiaryTypeEnum.DEPENDENT]: 'Dependente',
  [BeneficiaryTypeEnum.AGGREGATE]: 'Agregado',
  [BeneficiaryTypeEnum.RETIRED]: 'Aposentado',
  [BeneficiaryTypeEnum.FIRED]: 'Demitido',
  [BeneficiaryTypeEnum.HOLDER]: 'Titular',
};

export const BENEFICIARY_TYPE_PLURAL_NAME: Record<BeneficiaryTypeEnum, string> =
  {
    [BeneficiaryTypeEnum.RESPONSIBLE]: 'Responsáveis',
    [BeneficiaryTypeEnum.DEPENDENT]: 'Dependentes',
    [BeneficiaryTypeEnum.AGGREGATE]: 'Agregados',
    [BeneficiaryTypeEnum.RETIRED]: 'Aposentados',
    [BeneficiaryTypeEnum.HOLDER]: 'Titulares',
    [BeneficiaryTypeEnum.FIRED]: 'Demitidos',
  };

export const BENEFICIARY_TYPE_COLOR: Record<BeneficiaryTypeEnum, string> = {
  [BeneficiaryTypeEnum.DEPENDENT]: COLORS.PRIMARY.ORANGE['+1'],
  [BeneficiaryTypeEnum.RESPONSIBLE]: COLORS.PRIMARY.BLUE.MAIN,
  [BeneficiaryTypeEnum.AGGREGATE]: COLORS.SECONDARY.BLUE.MAIN,
  [BeneficiaryTypeEnum.RETIRED]: COLORS.MONOCHROMATIC.GRAY1,
  [BeneficiaryTypeEnum.FIRED]: COLORS.MONOCHROMATIC.GRAY4,
  [BeneficiaryTypeEnum.HOLDER]: COLORS.PRIMARY.BLUE.MAIN,
};

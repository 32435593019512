import { IAppRoute } from 'routes';

import { AuthenticatingPage } from '.';

export const AuthenticatingRouter: IAppRoute = {
  Page: AuthenticatingPage,
  path: '/autenticando',
  name: '',
  Icon: <></>,
  userTypes: [],
  disabled: false,
};

import { BeneficiaryListRouter } from '@flows/private/beneficiaries/pages/BeneficiaryList/router';
import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';
import { CompanyListRouter } from '@flows/private/companies/pages/CompanyList/router';
import { ServiceListRouter } from '@flows/private/services/pages/ServiceList/router';
import { UserListRouter } from '@flows/private/users/pages/UserList/router';
import BillListRouter from '@flows/private/financial/pages/List/router';
import { HomeRouter } from '@flows/private/home/router';
import { IAppGroupRoute } from 'routes';

export const MENU_LIST: Array<IAppGroupRoute> = [
  {
    id: 1,
    routes: [HomeRouter],
  },
  {
    id: 2,
    routes: [BeneficiaryListRouter],
  },
  {
    id: 3,
    routes: [MovementListRouter],
  },
  {
    id: 4,
    routes: [BillListRouter],
  },
  {
    id: 5,
    routes: [UserListRouter],
  },
  {
    id: 6,
    routes: [CompanyListRouter],
  },
  {
    id: 7,
    routes: [ServiceListRouter],
  },
];

import React from 'react';
import { Grid } from '@mui/material';

import { useFilterParams } from '@hooks';
import { SearchField } from '@components';

import { Container } from './styles';
import { useCompaniesListState } from '../../hooks';

export function CompanyListSubHeader() {
  const { hasFilters } = useFilterParams();
  const { companyListState } = useCompaniesListState();

  const { isError, isLoading } = companyListState;

  const totalCompaniesLength = companyListState.data?.total ?? 0;

  const isResultEmpty = totalCompaniesLength === 0;

  const isEmpty = isResultEmpty && !hasFilters;

  if ((!hasFilters && isLoading) || isError || isEmpty) return null;

  const disableButtonSearch = isLoading && hasFilters;
  return (
    <Container container item alignItems="center">
      <Grid item xs={12}>
        <SearchField
          placeholder="Empresa, CNPJ ou contrato"
          currentResultLength={totalCompaniesLength}
          disableSearch={disableButtonSearch}
        />
      </Grid>
    </Container>
  );
}

import { Mask } from '@hapvida/hapvida-core-components';

import { BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

export const getBeneficiaryAddressRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  return [
    {
      label: 'Número do CEP',
      value: Mask.cep(beneficiary.address?.zipCode) || '-',
      xs: 12,
    },
    {
      label: 'Logradouro',
      value: beneficiary.address?.street,
      xs: 4,
    },
    {
      label: 'Número',
      value: beneficiary.address?.number,
      xs: 4,
    },
    {
      label: 'Complemento',
      value: beneficiary.address?.complement,
      xs: 4,
    },
    {
      label: 'Bairro',
      value: beneficiary.address?.district,
      xs: 4,
    },
    {
      label: 'Cidade',
      value: beneficiary.address?.city,
      xs: 4,
    },
    {
      label: 'Estado',
      value: beneficiary.address?.stateCode,
      xs: 4,
    },
  ];
};

import { Stack, styled, Button } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const BoxItemAll = styled(Stack)(() => ({
  padding: '10px 16px',
  background: `${COLORS.DISABLED.BACKGROUND}`,
}));

export const PartnerCompaniesStack = styled(Stack)(() => ({
  maxHeight: '512px',
  overflowY: 'scroll',
}));

export const ButtonClearSelection = styled(Button)(() => ({
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: 0,
  height: '20px',
}));

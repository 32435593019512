import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from 'contexts/AuthContext';
import { IAppRoute } from 'routes';

import { CompanyListPage } from '.';

export const CompanyListRouter: IAppRoute = {
  Page: CompanyListPage,
  path: '/dados-empresa',
  name: 'Matriz e coligadas',
  isPrivate: true,
  Icon: <Icon name="home-work" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabledOnRoute: '/empresas',
};

import { Stack } from '@mui/material';
import { useEffect } from 'react';

import {
  ErrorLoadingPage,
  FilterSummary,
  Loading,
  NoSearchResults,
} from '@hapvida/hapvida-core-components';

import { useFilterParams } from 'hooks';
import { EmptyResult } from '@components';
import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';

import { useCompanySetupListState, useSelectCompany } from './hooks';
import { CompanySetupListViewList, NewCompanyModal } from './components';

export function CompanySetupListLayout() {
  const { handleSelectCompany } = useSelectCompany();
  const { hasFilters } = useFilterParams();
  const { companySetupListState, refreshCompanySetupListStates } =
    useCompanySetupListState();
  const { user } = useAuth();

  const userName = user?.name ?? '';

  const isBackoffice = user?.companyType === UserType.Backoffice;

  useEffect(() => {
    handleSelectCompany();
  }, []);
  if (companySetupListState.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (companySetupListState.isError) {
    return <ErrorLoadingPage onReloadClick={refreshCompanySetupListStates} />;
  }

  const companySetupListData = companySetupListState.data.pages
    .map(item => item.companies)
    .flat();

  const totalCompanySetupListLength =
    companySetupListState.data.pages[0].total ?? 0;

  const isEmpty = totalCompanySetupListLength === 0 && !hasFilters;

  if (isEmpty) {
    return (
      <EmptyResult
        message={`${userName}, nenhuma empresa está disponível para você.`}
      />
    );
  }

  const isSearchResultEmpty = totalCompanySetupListLength === 0 && hasFilters;

  const filterSummaryLabel = `${totalCompanySetupListLength} ${
    totalCompanySetupListLength === 1 ? 'empresa' : 'empresas'
  }`;

  return (
    <Stack paddingTop={3} gap={3}>
      <FilterSummary totalLabel={filterSummaryLabel} alwaysShowTitle />

      {isSearchResultEmpty && <NoSearchResults padding={5} />}

      {!isSearchResultEmpty && (
        <CompanySetupListViewList
          companySetupListData={companySetupListData}
          hasNextPage={Boolean(companySetupListState.hasNextPage)}
          loadNextPage={companySetupListState.fetchNextPage}
        />
      )}

      {!isBackoffice && <NewCompanyModal companies={companySetupListData} />}
    </Stack>
  );
}

import { Stack, Typography, useTheme } from '@mui/material';

import {
  Sidebar as SidebarCore,
  useSidebar,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';

import {
  BackofficeManagement,
  CompanyButton,
  SidebarRouteList,
} from './components';

export function Sidebar() {
  const { drawerOpen } = useSidebar();
  const { user } = useAuth();
  const theme = useTheme();

  const isBackoffice = user?.companyType === UserType.Backoffice;

  return (
    <SidebarCore.Root>
      <SidebarCore.Header isColorful={!isBackoffice} />
      <SidebarCore.Content gap={5}>
        {isBackoffice && <BackofficeManagement />}

        <Stack gap={3}>
          <Stack gap={2}>
            {isBackoffice && drawerOpen && (
              <Typography
                color={theme.palette.text.secondary}
                textTransform="uppercase"
                variant="caption"
              >
                Gestão empresa
              </Typography>
            )}

            <CompanyButton />
          </Stack>
          <SidebarRouteList />
        </Stack>
      </SidebarCore.Content>
    </SidebarCore.Root>
  );
}

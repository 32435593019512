import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';

interface SimpleTextProps {
  text?: string | ReactElement;
}

const SimpleText: React.FC<SimpleTextProps> = ({ text }: SimpleTextProps) => {
  return <Typography fontSize={14}>{text || '-'}</Typography>;
};

export default SimpleText;

export type SummaryItens = {
  label: string;
  color?: string;
  key: string;
};

export const SUMMARY_DATA: SummaryItens[] = [
  {
    label: 'Total de beneficiários',
    color: '#F8F9FA',
    key: 'totalLives',
  },
  {
    label: 'Titulares',
    color: '#0054B8',
    key: 'holders',
  },
  {
    label: 'Dependentes',
    color: '#F49E00',
    key: 'dependents',
  },
  {
    label: 'Agregados',
    color: '#00A1CF',
    key: 'aggregates',
  },
  {
    label: 'Demitidos',
    color: '#495057',
    key: 'fired',
  },
  {
    label: 'Aposentados',
    color: '#F8F9FA',
    key: 'retirees',
  },
];

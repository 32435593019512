import { Typography } from '@mui/material';

import { SimpleFile, DataGrid } from '@hapvida/hapvida-core-components';

import { DataGridItem } from '@hapvida/hapvida-core-components/src/components/DataGrid';

import { BillDetails, FileData } from '@services/FinancialService';

import { FileGroupProps } from '@utils';

import { useBillData } from '../../hooks';
import { ButtonLink, InvoiceButton } from './components';

interface BillDetailsCardProps {
  reportFiles: FileGroupProps;
  billingFile?: FileData;
  bill: BillDetails;
}

export function BillDetailsCard({
  reportFiles,
  billingFile,
  bill,
}: BillDetailsCardProps) {
  const { commonData } = useBillData(bill);

  const handleOpenFile = (file?: FileData) => {
    if (file?.path) window.open(file.path, '_blank');
  };

  const handleOpenFiles = (files?: FileData[]) => {
    files?.forEach(file => handleOpenFile(file));
  };

  const { csvFiles, pdfFiles, txtFiles } = reportFiles;

  const gridRows: DataGridItem[] = [
    ...commonData,
    {
      label: 'Boleto',
      xs: 3,
      value: billingFile ? (
        <SimpleFile
          onDownload={() => handleOpenFile(billingFile)}
          filename={billingFile.fileName}
        />
      ) : (
        <Typography variant="body2">-</Typography>
      ),
    },
    {
      label: 'Nota Fiscal',
      xs: 9,
      value: <InvoiceButton bill={bill} />,
    },
    {
      label: 'Relatório analítico (PDF)',
      xs: 3,
      value:
        pdfFiles && pdfFiles.length > 0 ? (
          <ButtonLink
            onClick={() => handleOpenFiles(pdfFiles)}
            text="Relatório.pdf"
          />
        ) : (
          <Typography variant="body2">-</Typography>
        ),
    },
    {
      label: 'Relatório analítico (CSV)',
      xs: 3,
      value:
        csvFiles && csvFiles.length > 0 ? (
          <ButtonLink
            onClick={() => handleOpenFiles(csvFiles)}
            text="Relatório.csv"
          />
        ) : (
          <Typography variant="body2">-</Typography>
        ),
    },
    {
      label: 'Relatório analítico (TXT)',
      xs: 3,
      value:
        txtFiles && txtFiles.length > 0 ? (
          <ButtonLink
            onClick={() => handleOpenFiles(txtFiles)}
            text="Relatório.txt"
          />
        ) : (
          <Typography variant="body2">-</Typography>
        ),
    },
  ];

  return <DataGrid rows={gridRows} gridContainerProps={{ rowSpacing: 3 }} />;
}

import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { UserService, UserListProps } from '@services/UserService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';
import { UserQueryKeysEnum } from '@constants';

export function useUsersState() {
  const { currentPage, tableOrder, searchParam, mappedFilters } =
    useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useAuth();

  const userService = useMemo(() => new UserService(), []);

  const companyId = selectedCompany?.id ?? '';

  const usersState = useQuery<UserListProps>({
    queryKey: [
      UserQueryKeysEnum.USERS_STATE,
      currentPage,
      tableOrder,
      companyId,
      searchParam,
      mappedFilters,
    ],
    queryFn: () =>
      userService.getAccounts({
        ...tableOrder,
        ...searchParam,
        ...mappedFilters,
        companyId,
        offset: 9 * (currentPage - 1),
        limit: 9,
      }),
    enabled: Boolean(companyId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshUsersStates = () => {
    usersState.remove();
    usersState.refetch();
  };

  return {
    refreshUsersStates,
    usersState,
  };
}

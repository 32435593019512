import { Stack } from '@mui/material';
import { useEffect, useRef } from 'react';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import { UserListCard } from './components';
// TODO: códigos comentados para subida prévia de homologação. Será corrigido/validado na US 269725
// import { useUsersState } from './hooks';
import { userDrawerFilterValues } from './constants';

export function UserListLayout() {
  // const { usersState } = useUsersState();
  // const totalUsersLength = usersState?.data ? usersState.data.total : 0;
  // const filterSummaryLabel = `${totalUsersLength} ${
  //   totalUsersLength === 1 ? 'usuário' : 'usuários'
  // }`;

  const { getInitialFilter, setActiveFilters } = useDrawerFilter();
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      const initialUsersFilters = getInitialFilter({
        filtersConfig: userDrawerFilterValues,
      });

      setActiveFilters(initialUsersFilters);
    }
  }, []);

  return (
    <Stack paddingY={3} gap={3}>
      {/* <FilterSummary totalLabel={filterSummaryLabel} /> */}

      <UserListCard />
    </Stack>
  );
}

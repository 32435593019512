import {
  FilterKeyEnum,
  DrawerFilter,
  Company,
  Gender,
} from '@hapvida/hapvida-core-components';

import { HEALTH_OPERATOR_NAME, HealthOperatorEnum } from '@constants';

export function HealthOperatorDrawerFilter() {
  const healthOperatorFilters = Object.values(HealthOperatorEnum).map(
    healthOperator => {
      const content = (
        <Company company={HEALTH_OPERATOR_NAME[healthOperator]} />
      );

      return {
        label: HEALTH_OPERATOR_NAME[healthOperator],
        value: healthOperator,
        content,
      };
    },
  );

  return (
    <DrawerFilter
      filterKey={FilterKeyEnum.HEALTH_OPERATOR}
      filters={healthOperatorFilters}
      gender={Gender.Feminine}
      label="operadoras"
      title="Operadora"
    />
  );
}

import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Mask } from '@hapvida/hapvida-core-components';

import { UserProfileEnum } from '@constants';
import { useUserDetailsState } from '@flows/private/users/pages/UserDetails/hooks';

import {
  DeleteUserButton,
  PermissionsList,
  SubmitFormUserButton,
  EditFormFields,
} from './components';
import { UserForm } from './types';
import { schema } from './validation';
import { UserActionsStateProvider } from './hooks';

export function UserEditForm() {
  const { userDetailsState } = useUserDetailsState();

  if (!userDetailsState.isSuccess) {
    return null;
  }

  const userData = userDetailsState.data.content;

  const userForm = useForm<UserForm>({
    defaultValues: {
      profile: userData?.profile ?? UserProfileEnum.ADM,
      modules: {
        quotations: true,
        companies: true,
        brokers: true,
        users: true,
      },
      phone: userData?.phone ? Mask.phone(userData?.phone) : '',
      document: userData?.document ?? '',
      email: userData?.email ?? '',
      name: userData?.name ?? '',
    },
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { formState, control, watch, reset, handleSubmit } = userForm;

  const userProfile = watch('profile');

  return (
    <UserActionsStateProvider>
      <Grid component={'form'} container spacing={3} my={3}>
        <EditFormFields formState={formState} control={control} />

        <Grid item xs={12}>
          <PermissionsList userProfile={userProfile} />
        </Grid>

        <Grid item xs={12} container justifyContent="space-between">
          <DeleteUserButton />
          <SubmitFormUserButton
            formState={formState}
            reset={reset}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </UserActionsStateProvider>
  );
}

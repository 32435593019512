import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';

import {
  Icon,
  Mask,
  Pill,
  SummaryCardNew,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';
import { useGetFile } from '@hooks';
import { MOVEMENT_STATUS_COLOR, MOVEMENT_STATUS_NAME } from '@constants';

import { DateText, FieldsWrapper, FileDiv } from './styles';
import { useMovementDetailsState } from '../../hooks';
import { DataField } from './components';

export function MovementInfoCard() {
  const { movementInfoState } = useMovementDetailsState();
  const { selectedCompany } = useAuth();

  if (!movementInfoState.isSuccess) {
    return null;
  }

  const { movementDetails } = movementInfoState.data.content;

  return (
    <SummaryCardNew.Container>
      <Typography variant="subtitle1">Informações da movimentação</Typography>
      <FieldsWrapper>
        <DataField
          label="Cód. Movimentação"
          value={movementDetails.number}
          width="25%"
        />
        <DataField
          label="Data da carga"
          value={<DateText>{Mask.date(movementDetails.createdAt)}</DateText>}
          width="25%"
        />
        <DataField
          label="Data da efetivação"
          value={<DateText>{Mask.date(movementDetails.updatedAt)}</DateText>}
          width="25%"
        />
        <DataField
          label="Status"
          value={
            <Pill
              color={MOVEMENT_STATUS_COLOR[movementDetails.status]}
              text={MOVEMENT_STATUS_NAME[movementDetails.status]}
              fitContent
            />
          }
          width="25%"
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <DataField
          label="Arquivo"
          value={
            <>
              {movementDetails.uploadName !== undefined ? (
                <FileDiv>
                  <Icon name="file-check" size={18} />
                  <Tooltip title={movementDetails.uploadName} arrow>
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() =>
                        useGetFile(
                          `companies/${selectedCompany?.id}`,
                          movementDetails.uploadName,
                        )
                      }
                    >
                      {movementDetails.uploadName}
                    </Typography>
                  </Tooltip>
                </FileDiv>
              ) : (
                <Typography>-</Typography>
              )}
            </>
          }
          width="100%"
        />
      </FieldsWrapper>
    </SummaryCardNew.Container>
  );
}

import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Modal,
  NavigationCard,
  SelectInput,
} from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { usePrompt } from '@hooks';

import {
  MOVIMENTATION_STEPS_KEYS,
  MOVIMENTATION_STEPS_VALUES,
} from '../../constants/keys';
import {
  CardWrapper,
  ContainerWrapper,
  DescriptionModal,
  TextContent,
  TextTitle,
} from './styles';
import { schema } from './validation';
import { PortalRedirectLink } from './constants/portalRedirectLink';
import ImagesCardsNavigation from './assets';

interface NavigationIndivualMovementationProps {
  redirectPortal: string;
}

type StartMovimentationStepProps = {
  onStepUpdate(step: number, data: any): void;
};

const StartMovimentationLayout: React.FC<StartMovimentationStepProps> = ({
  onStepUpdate,
}) => {
  usePrompt();

  const [currentModalState, setCurrentModalState] = useState<boolean>(false);

  const onSubmit = () => {
    onStepUpdate(
      MOVIMENTATION_STEPS_VALUES[MOVIMENTATION_STEPS_KEYS.NEW_MOVIMENTATION],
      {},
    );
  };

  const handleCloseModal = () => {
    setCurrentModalState(false);
  };

  const {
    control,
    watch,
    formState: { isValid },
  } = useForm<NavigationIndivualMovementationProps>({
    mode: 'all',
    defaultValues: {
      redirectPortal: '',
    },
    resolver: yupResolver(schema),
  });

  const redirectPortalWatch = watch('redirectPortal');

  return (
    <>
      {currentModalState && (
        <Modal
          open={currentModalState}
          icon="alert"
          type="default"
          handleCloseModal={handleCloseModal}
          title="Movimentação via tela temporariamente no portal antigo"
          mainButtonText="Acessar portal antigo"
          disableMainAction={!isValid}
          secondaryButtonText="Fechar"
          mainAction={() => {
            window.open(redirectPortalWatch);
          }}
        >
          <Stack gap={2}>
            <DescriptionModal>
              Acesse o portal antigo para realizar suas movimentações
              manualmente. Estamos trabalhando para disponibilizar essa
              funcionalidade no novo portal em breve.
            </DescriptionModal>

            <SelectInput
              name="redirectPortal"
              control={control}
              label="Escolha para qual portal você gostaria de ser redirecionado:"
              placeholder="Selecione"
              options={PortalRedirectLink.map(redirectKey => ({
                key: redirectKey.label,
                value: redirectKey.value,
              }))}
              color={COLORS.MONOCHROMATIC.GRAY4}
              required
              paddingInput="8px"
            />
          </Stack>
        </Modal>
      )}

      <ContainerWrapper>
        <Typography variant="h5">Selecione o tipo de movimentação</Typography>
        <CardWrapper>
          <NavigationCard
            enableImage={ImagesCardsNavigation.LayoutImage}
            enableImage2x={ImagesCardsNavigation.LayoutImage2x}
            disabledImage={ImagesCardsNavigation.DisabledLayoutImage}
            disabledImage2x={ImagesCardsNavigation.DisabledLayoutImage2x}
            fullWidth
            messageOne={
              <TextTitle variant="subtitle1" fontWeight={400}>
                <b>Movimentação via arquivo</b> <i>(layout)</i>
              </TextTitle>
            }
            messageTwo={
              <TextContent variant="subtitle1" fontWeight={400}>
                Vou fazer uma movimentação que <b>incluí um ou mais </b>
                beneficiários.
              </TextContent>
            }
            handlerAction={() => onSubmit()}
          />
          <NavigationCard
            enableImage={ImagesCardsNavigation.IndividualImage}
            enableImage2x={ImagesCardsNavigation.IndividualImage2x}
            disabledImage={ImagesCardsNavigation.DisabledIndividualImage}
            disabledImage2x={ImagesCardsNavigation.DisabledIndividualImage2x}
            fullWidth
            messageOne={
              <TextTitle variant="subtitle1" fontWeight={400}>
                <b>Movimentação via tela</b> <i>(digitação)</i>
              </TextTitle>
            }
            messageTwo={
              <TextContent variant="subtitle1" fontWeight={400}>
                Vou fazer uma movimentação de beneficiário manualmente.
              </TextContent>
            }
            handlerAction={() => setCurrentModalState(true)}
          />
        </CardWrapper>
      </ContainerWrapper>
    </>
  );
};

export default StartMovimentationLayout;

import React from 'react';

import IDragAndDropFile from '@hapvida/hapvida-core-components/src/components/DragAndDrop/dtos/IDragDropFile';

import MovimentationFileLayout from './layout';

type MovimentationFileProps = {
  onStepUpdate(step: number, data: any): void;
  movimentationFile?: IDragAndDropFile;
};

const MovimentationFileStep: React.FC<MovimentationFileProps> = ({
  onStepUpdate,
  movimentationFile,
}) => {
  return (
    <MovimentationFileLayout
      onStepUpdate={onStepUpdate}
      movimentationFile={movimentationFile}
    />
  );
};

export default MovimentationFileStep;

import { createContext } from 'react';

import { CompanyProps, CurrentUserProps } from '@services/AuthService/dtos';

export enum UserType {
  Backoffice = 'Backoffice',
  Company = 'Company',
  Agency = 'Agency',
}

export interface AccountProps {
  selectedCompany?: CompanyProps;
  user?: CurrentUserProps;
  token?: string;
}

export interface AuthContextData extends AccountProps {
  logIn: (account: AccountProps) => void;

  logOut(): void;

  updateAccount: (account: AccountProps) => void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

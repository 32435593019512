import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import { Icon, Mask, Pill } from '@hapvida/hapvida-core-components';

import { MovimentationItem } from '@services/MovimentationService/dtos/ListMovimentationsDTO';

import { useGetFile } from '@hooks';
import { useAuth } from 'hooks/useAuth';
import {
  MOVEMENT_STATUS_COLOR,
  MOVEMENT_STATUS_NAME,
  MovementStatusEnum,
} from '@constants';

import MovimentationDetailRouter from '../../../../../MovementDetails/router';
import {
  CustomTableCell,
  CustomTableRow,
  DateText,
  EditIcon,
  IconDiv,
  NumberMovimentation,
} from './styles';
import MovimentType from '../MovimentType';

interface TableRowProps {
  movimentations: MovimentationItem[];
}

export function TableRows({ movimentations }: TableRowProps) {
  const navigateTo = useNavigate();
  const { selectedCompany } = useAuth();

  const movimentationHasError = (mov: MovimentationItem): boolean => {
    if (mov.status === MovementStatusEnum.ERROR) {
      return true;
    }
    return false;
  };

  const getBorder = (mov: MovimentationItem, index: number) =>
    movimentationHasError(mov) ||
    (index < movimentations.length - 1 &&
      movimentationHasError(movimentations[index + 1]));

  return (
    <>
      {movimentations?.map((movimentation, index) => (
        <CustomTableRow
          key={movimentation.id}
          hasErrorBorder={getBorder(movimentation, index)}
          hasErrorBg={movimentationHasError(movimentation)}
        >
          <CustomTableCell align="left">
            <Stack>
              <NumberMovimentation>
                <Typography>{movimentation.number}</Typography>
              </NumberMovimentation>

              {movimentation.uploadName && (
                <Tooltip title={movimentation?.uploadName} arrow>
                  <IconDiv
                    onClick={() =>
                      useGetFile(
                        `companies/${selectedCompany?.id}`,
                        movimentation.uploadName || '',
                      )
                    }
                  >
                    <Icon name="file-check" size={18} />
                    <Typography>{movimentation.uploadName}</Typography>
                  </IconDiv>
                </Tooltip>
              )}
            </Stack>
          </CustomTableCell>
          <CustomTableCell align="left">
            <MovimentType movimentType={movimentation.type} />
          </CustomTableCell>
          <CustomTableCell align="left">
            <DateText>{Mask.date(movimentation.createdAt, true)}</DateText>
          </CustomTableCell>
          <CustomTableCell align="left">
            <DateText>{Mask.date(movimentation.updatedAt, true)}</DateText>
          </CustomTableCell>
          <CustomTableCell align="left">
            <Pill
              color={MOVEMENT_STATUS_COLOR[movimentation.status]}
              text={MOVEMENT_STATUS_NAME[movimentation.status]}
              fitContent
            />
          </CustomTableCell>

          <CustomTableCell align="center">
            <EditIcon
              size={24}
              name="edit"
              variant="outlined"
              className="action-edit"
              key={movimentation.id}
              onClick={() => {
                navigateTo(
                  MovimentationDetailRouter.dynamicPath({
                    movementId: movimentation.id,
                  }),
                );
              }}
            />
          </CustomTableCell>
        </CustomTableRow>
      ))}
    </>
  );
}

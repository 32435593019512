import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import { StepLayout } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import {
  PASSWORD_RECOVERY_STEPS_KEYS,
  PASSWORD_RECOVERY_STEPS_ORDER,
  PASSWORD_RECOVERY_STEPS_VALUES,
} from './constants/keys';
import { steps } from './passwordRecoverySteps';

function PasswordRecoveryStepsPage() {
  const navigateTo = useNavigate();

  const routeParams = useParams<{ passwordDefinitionStep: string }>();

  const [currentStep, setCurrentStep] = useState(
    PASSWORD_RECOVERY_STEPS_VALUES[
      routeParams.passwordDefinitionStep ||
        PASSWORD_RECOVERY_STEPS_KEYS.PASSWORD_RECOVERY
    ],
  );

  useEffect(() => {
    if (routeParams.passwordDefinitionStep)
      setCurrentStep(
        PASSWORD_RECOVERY_STEPS_VALUES[routeParams.passwordDefinitionStep],
      );
  }, [routeParams]);

  const [stepList, setStepList] = useState([
    {
      label: 'Redefinição de senha',
      completed: true,
    },
    {
      label: 'Confirmação',
      completed: false,
    },
  ]);

  const handleStepChange = useCallback(
    (step: number) => {
      if (step === 0) return;

      setCurrentStep(step);
      navigateTo(
        `/recuperacao-de-senha/${PASSWORD_RECOVERY_STEPS_ORDER[step]}`,
      );
    },
    [navigateTo],
  );

  const handleStepUpdate = useCallback(
    (step: number) => {
      const updatedStepList: any = [...stepList];

      updatedStepList[step] = {
        ...stepList[step],
        completed: true,
      };

      setStepList(updatedStepList);
      setCurrentStep(step + 1);
      navigateTo(
        `/recuperacao-de-senha/${PASSWORD_RECOVERY_STEPS_ORDER[step + 1]}`,
      );
    },
    [navigateTo, stepList],
  );

  return (
    <StepLayout
      steps={stepList}
      hasHeader={false}
      currentStep={currentStep}
      onStepChange={(step: number) => handleStepChange(step)}
      sideBarTitle="Redefina sua senha"
      sideBarSubtitle="Digite uma nova senha para acessar o Portal Empresa Hapvida."
      firstStepColor={COLORS.WHITE}
      textContentColor={COLORS.MONOCHROMATIC.GRAY4}
      drawerFirstStep
    >
      <Stack
        marginBottom={4}
        gap={1}
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Typography variant="text">Já tem cadastro?</Typography>
        <a href={process.env.REACT_APP_LOGIN_URL}>
          <Typography variant="link" color={COLORS.PRIMARY.BLUE.MAIN}>
            Efetue o login
          </Typography>
        </a>
      </Stack>
      {steps[currentStep].step({
        onStepUpdate: handleStepUpdate,
      })}
    </StepLayout>
  );
}

export default PasswordRecoveryStepsPage;

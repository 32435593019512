import { styled as muiStyled } from '@mui/material/styles';

import styled from 'styled-components';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { Typography } from '@hapvida/hapvida-core-components';

import { COLORS } from '../../themes/colors';

interface Props {
  disabled: boolean;
  fullWidth?: boolean;
}

export const StyledPaper = muiStyled(Paper, {
  shouldForwardProp: props => props !== 'fullWidth',
})<Props>(({ disabled, fullWidth }) => ({
  '&.MuiPaper-root': {
    padding: fullWidth ? '20px 24px' : '28px 18px',
    boxShadow: 'none',
    borderRadius: '8px',
    boxSizing: 'border-box',
    pointerEvents: 'all',
    width: fullWidth ? '100%' : '169px',
    display: fullWidth ? 'flex' : 'block',
    alignItems: 'center',

    border: disabled
      ? `1px solid ${COLORS.DISABLED.BORDER}`
      : `1px solid ${COLORS.MONOCHROMATIC.GRAY3}`,

    background: disabled ? COLORS.DISABLED.BACKGROUND : COLORS.WHITE,
    '&:hover': {
      cursor: !disabled && 'pointer',
      border: !disabled && `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
      background: !disabled && '#FFFBF5',
      fontWeight: !disabled && '600',
      boxShadow: !disabled && '0px 16px 24px 0px #0000000F;',
    },
    transition: 'all 0.5s',
  },
}));

export const Image = styled.img`
  height: 70px;
  width: 70px;
  position: relative;
`;

interface MessageWrapperProps {
  disabled?: boolean;
  fullWidth?: boolean;
}

export const MessageWrapper = muiStyled(
  ({ disabled, fullWidth, ...props }: MessageWrapperProps) => (
    <Box {...props} />
  ),
)<MessageWrapperProps>(({ fullWidth }) => ({
  height: fullWidth ? '100%' : '50%',
  width: '100%',
  display: 'flex',
  marginLeft: fullWidth ? '16px' : '0',
  alignItems: fullWidth ? 'flex-start' : 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
}));

export const StyledTypography = muiStyled(Typography)<Props>(
  ({ disabled }) => ({
    whiteSpace: 'normal',

    '&.MuiTypography-root': {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeigh: '0',

      color: disabled ? COLORS.MONOCHROMATIC.GRAY3 : COLORS.MONOCHROMATIC.GRAY4,
    },
  }),
);

interface StyledSubTitleProps {
  disabled?: boolean;
}

export const StyledSubTitle = muiStyled(Typography)<StyledSubTitleProps>(
  ({ disabled }) => ({
    fontSize: 14,
    fontWeight: 'inherit',
    color: disabled ? COLORS.MONOCHROMATIC.GRAY3 : COLORS.MONOCHROMATIC.GRAY4,
  }),
);

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0px',

    maxWidth: 556,
    backgroundColor: COLORS.WHITE,

    border: `1px solid ${COLORS.SECONDARY.YELLOW['+1']}`,
    borderRadius: '8px',
  },

  '& .MuiTooltip-arrow::before': {
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.SECONDARY.YELLOW['+1']}`,
  },
}));

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import {
  useDrawerFilter,
  Button,
  Icon,
} from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';
import { SearchField } from '@components';

import { MOVIMENTATION_STEPS_KEYS } from '../../../MovimentationSteps/constants/keys';
import MovimentationStepRouter from '../../../MovimentationSteps/router';
import { MovementsFiltersDrawer } from './components';
import { useMovementsState } from '../../hooks';

export function MovementsSubHeader() {
  const { hasFilters } = useFilterParams();
  const { movementsState, movementsSummaryState } = useMovementsState();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const { filterCount } = useDrawerFilter();
  const navigate = useNavigate();
  const { user } = useAuth();

  const backofficeUser = user?.companyType === UserType.Backoffice;

  const resultLength = movementsState.data?.movements?.length;
  const isLoading = movementsState.isLoading || movementsSummaryState.isLoading;
  const isEmpty = resultLength === 0 && !hasFilters;
  const hasError = movementsState.isError || movementsSummaryState.isError;

  if ((isLoading && !hasFilters) || hasError || isEmpty) return null;

  const filterDisabled =
    (!filterCount && movementsState?.data?.movements.length === 0) ||
    (!filterCount && movementsState.isLoading);

  const handleToggleDrawer = () => {
    setVisibleDrawer(!visibleDrawer);
  };

  const handleNewMovement = () => {
    navigate(
      MovimentationStepRouter.dynamicPath({
        movimentationStep: MOVIMENTATION_STEPS_KEYS.NEW_MOVIMENTATION,
      }),
    );
  };

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={7} md={backofficeUser ? 9 : 6}>
          <SearchField
            currentResultLength={resultLength}
            placeholder="ID Solicitação"
          />
        </Grid>
        <Grid item xs={backofficeUser ? 12 : 6} sm={5} md={3}>
          <Button
            endIcon={!filterCount && <Icon name="filter" size={20} />}
            variant={!filterCount ? 'outlined' : 'contained'}
            onClick={handleToggleDrawer}
            disabled={filterDisabled}
            color="secondary"
            disableRipple
            fullWidth
          >
            {!filterCount ? 'Aplicar filtro' : 'Ver filtros aplicados'}
          </Button>
        </Grid>
        {!backofficeUser && (
          <Grid item xs={6} sm={12} md={3}>
            <Button
              onClick={handleNewMovement}
              variant="contained"
              color="success"
              disableRipple
              fullWidth
            >
              Nova movimentação
            </Button>
          </Grid>
        )}
      </Grid>
      <MovementsFiltersDrawer
        toggleDrawer={handleToggleDrawer}
        visibleDrawer={visibleDrawer}
      />
    </>
  );
}

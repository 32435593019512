import { IAppRoute } from 'routes';

import { ServerError } from './index';

export const ServerErrorRouter: IAppRoute = {
  Page: ServerError,
  path: '/servico-indisponivel',
  name: 'Erro de Servidor',
  Icon: <></>,
  userTypes: [],
  disabled: false,
};

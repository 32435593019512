import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes';

import { BackofficeUserDetailsPage } from '.';

export const BackofficeUserDetailsRouter: IAppDynamicRoute = {
  Page: BackofficeUserDetailsPage,
  path: '/usuarios-backoffice/:id',
  name: 'Editar usuário backoffice',
  dynamicPath: ({ id }) => `/usuarios-backoffice/${id}`,
  Icon: <Icon name="account-multiple" />,
  isPrivate: true,
  collapsedName: 'Editar',
  userTypes: [],
  disabled: false,
};

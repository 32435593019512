import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  Collapse,
  Loading,
  Icon,
  Mask,
  NoSearchResults,
} from '@hapvida/hapvida-core-components';

import { UserDetailsRouter } from '@flows/private/users/pages/UserDetails/router';
import { EmptyResult } from '@components';
import {
  USER_STATUS_ICON_NAME,
  USER_STATUS_COLOR,
  USER_STATUS_NAME,
} from '@constants';
import { useFilterParams, useAuth } from '@hooks';
import { useUsersState } from '@flows/private/users/pages/UserList/hooks/index';

import { ErrorLoading } from '../ErrorLoading';
import {
  userListTableHeadColumns,
  UserListColumnNameEnum,
} from '../../constants';

export function UserListCardTable() {
  const {
    handleTableOrderChange,
    handlePageChange,
    currentPage,
    tableOrder,
    hasFilters,
  } = useFilterParams();
  const { usersState, refreshUsersStates } = useUsersState();
  const { user: loggedUser } = useAuth();
  const navigate = useNavigate();

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleEditUser = (id: string) => {
    navigate(UserDetailsRouter.dynamicPath({ id }));
  };

  if (usersState.isLoading) {
    return (
      <Loading
        padding={5}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (usersState.isError) {
    return <ErrorLoading onReloadClick={refreshUsersStates} />;
  }

  const totalUsersLength = usersState.data.total ?? 0;
  const isEmptyUsers = totalUsersLength === 0 && !hasFilters;

  if (isEmptyUsers) {
    return (
      <EmptyResult
        message={`${
          loggedUser?.name ?? ''
        }, você ainda não tem nenhum usuário cadastrado.`}
      />
    );
  }

  const isSearchResultEmpty = totalUsersLength === 0 && hasFilters;

  if (isSearchResultEmpty) {
    return <NoSearchResults padding={5} />;
  }

  const userListTableRows = usersState.data?.users?.map(user => ({
    id: user.id,
    main: {
      [UserListColumnNameEnum.PERSONAL_DATA]: (
        <Stack>
          <Typography fontWeight={700} variant="subtitle2">
            {user?.name}
          </Typography>
          {user?.document && (
            <Typography variant="smallText">
              {Mask.cpf(user?.document) ?? '-'}
            </Typography>
          )}
        </Stack>
      ),
      // TODO: código comentado para subida prévia de homologação. Será corrigido/validado na US 269725
      // [UserListColumnNameEnum.PROFILE]: (
      //   <ProfileDetailsButton
      //     profileId={user.profile.id}
      //     profileName={user.profile.name}
      //   />
      // ),
      [UserListColumnNameEnum.EMAIL]: (
        <Typography variant="body2" overflow="hidden" textOverflow="ellipsis">
          {user.email}
        </Typography>
      ),
      [UserListColumnNameEnum.STATUS]: (
        <Stack direction="row" alignItems="center" gap={1}>
          <Icon
            name={USER_STATUS_ICON_NAME[user.status]}
            color={USER_STATUS_COLOR[user.status]}
            size={20}
          />
          <Typography
            fontWeight={600}
            variant="smallText"
            color={USER_STATUS_COLOR[user.status]}
          >
            {user.status ? USER_STATUS_NAME[user.status] : '-'}
          </Typography>
        </Stack>
      ),
    },
    dependents: [],
  }));

  const viewedUsersLength =
    9 * (currentPage - 1) + usersState.data.totalPerPage;
  const hasMultiPages = totalUsersLength > 9;

  return (
    <Collapse
      TableHeadColumns={userListTableHeadColumns}
      count={Math.ceil(totalUsersLength / 9)}
      onOrderChange={handleTableOrderChange}
      onPageChange={handleChangePage}
      showFooter={hasMultiPages}
      currentPage={currentPage}
      data={userListTableRows}
      onEdit={handleEditUser}
      from={viewedUsersLength}
      tableOrder={tableOrder}
      to={totalUsersLength}
      baseTableProps={{
        tableContainerHeight: '100%',
        style: {
          padding: 0,
        },
      }}
      hasCellAction
    />
  );
}

import {
  DrawerFilterProvider,
  useSidebar,
  Header,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { HomeStateProvider } from './hooks';
import { HomeLayout } from './layout';
import { HomeRouter } from './router';

export function HomePage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <HomeStateProvider>
        <Header
          toggleDrawer={toggleDrawer}
          drawerOpen={drawerOpen}
          onLogOut={logOut}
          user={user}
          route={{
            icon: HomeRouter.Icon,
            name: HomeRouter.name,
          }}
        >
          <HomeLayout />
        </Header>
      </HomeStateProvider>
    </DrawerFilterProvider>
  );
}

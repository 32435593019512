import { styled as muiStyled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const ContainerTitle = muiStyled(Stack)(() => ({
  paddingTop: 24,
}));

export const ContainerButton = muiStyled(Stack)(() => ({
  padding: '24px 0',
  alignItems: 'flex-end',
}));

import {
  DrawerFilterProvider,
  Header,
  useSidebar,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { CompanyListSubHeader } from './components';
import { CompanyListRouter } from './router';
import { CompanyListLayout } from './layout';

export function CompanyListPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <Header
        route={{ icon: CompanyListRouter.Icon, name: CompanyListRouter.name }}
        subHeader={<CompanyListSubHeader />}
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        onLogOut={logOut}
        user={user}
      >
        <CompanyListLayout />
      </Header>
    </DrawerFilterProvider>
  );
}

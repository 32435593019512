import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, Form } from '@hapvida/hapvida-core-components';

import { AddAccountFormProps } from '@services/UserService';

import { schema } from './control/validation';
import { FormRegisterUser } from './components';

interface AddAccountLayoutProps {
  onSubmit: (data: AddAccountFormProps) => Promise<void>;
}

export const AddAccountLayout = ({ onSubmit }: AddAccountLayoutProps) => {
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { isValid, errors },
  } = useForm<AddAccountFormProps>({
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      confirmedEmail: '',
      phone: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmitForm: SubmitHandler<AddAccountFormProps> = async data => {
    await onSubmit(data);
  };

  return (
    <Form title="Novo usuário" subtitle="Campos com * são obrigatórios">
      <Typography variant="text" pt={2} pb={2}>
        Coloque as informações do novo usuário.
      </Typography>
      <form>
        <Grid container spacing={{ xs: 5, sm: 3 }}>
          <FormRegisterUser
            controlForm={control}
            watchForm={watch}
            errorsForm={errors}
            triggerForm={trigger}
          />

          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!isValid}
              onClick={handleSubmit(onSubmitForm)}
            >
              Criar usuário
            </Button>
          </Grid>
        </Grid>
      </form>
    </Form>
  );
};

import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from '@contexts';
import { IAppRoute } from 'routes';

import { CreateBackofficeUserPage } from '.';

export const CreateBackofficeUserRouter: IAppRoute = {
  Page: CreateBackofficeUserPage,
  path: '/usuarios-backoffice/novo',
  name: 'Novo usuário backoffice',
  Icon: <Icon name="account-multiple" />,
  isPrivate: true,
  collapsedName: 'Novo',
  userTypes: [UserType.Backoffice],
  disabled: false,
};

import { DataGrid } from '@hapvida/hapvida-core-components';

import { useBeneficiaryDetailsState } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/hooks';

import { getBeneficiaryAdditionalInformationRows } from './utils';

export function BeneficiaryAdditionalInformationCard() {
  const { beneficiaryDetailsState } = useBeneficiaryDetailsState();

  if (!beneficiaryDetailsState.isSuccess) {
    return null;
  }

  const beneficiary = beneficiaryDetailsState.data;

  const rows = getBeneficiaryAdditionalInformationRows(beneficiary);

  return <DataGrid title="Informações adicionais" rows={rows} />;
}

import { Divider, Stack, Typography, useTheme } from '@mui/material';

import { useSidebar } from '@hapvida/hapvida-core-components';

import { BackofficeUserListRouter } from '@flows/private/backofficeUser/pages/BackofficeUserList/router';

import { SidebarRouteButton } from '../SidebarRouteButton';

export function BackofficeManagement() {
  const { drawerOpen } = useSidebar();
  const theme = useTheme();

  return (
    <>
      <Stack gap={2}>
        {drawerOpen && (
          <Typography
            color={theme.palette.text.secondary}
            textTransform="uppercase"
            variant="caption"
          >
            Gestão backoffice
          </Typography>
        )}

        <SidebarRouteButton route={BackofficeUserListRouter} />
      </Stack>

      <Divider />
    </>
  );
}

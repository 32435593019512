import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { BillSummariesDTO } from '@services/FinancialService/dtos/GetBillsSummaryDTO';
import { BillListProps } from '@services/FinancialService/dtos/BillsListDTO';
import FinancialService from '@services/FinancialService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';

interface BillsStateContextData {
  billSummariesState: UseQueryResult<BillSummariesDTO[]>;
  billListState: UseQueryResult<BillListProps>;
  handleRefreshBillsStates: () => void;
}

export const BillsStateContext = createContext<BillsStateContextData>(
  {} as BillsStateContextData,
);

function BillsStateProvider({ children }: PropsWithChildren<{}>) {
  const { tableOrder, searchParam, currentPage, mappedFilters } =
    useFilterParams();

  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useAuth();

  const companyId = useMemo(() => selectedCompany?.id ?? '', [selectedCompany]);

  const financialService = useMemo(() => new FinancialService(), []);

  const billSummariesState = useQuery({
    queryKey: ['billSummariesState', mappedFilters, searchParam, companyId],
    queryFn: () =>
      financialService.getBillsSummary({
        companyId,
        ...mappedFilters,
        ...searchParam,
      }),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const billListState = useQuery({
    queryKey: [
      'billListState',
      mappedFilters,
      searchParam,
      currentPage,
      tableOrder,
      companyId,
    ],
    queryFn: () =>
      financialService.getBillsList({
        companyId,
        ...mappedFilters,
        ...searchParam,
        ...tableOrder,
        offset: 9 * (currentPage - 1),
        limit: 9,
      }),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const handleRefreshBillsStates = () => {
    billSummariesState.remove();
    billListState.remove();

    billSummariesState.refetch();
    billListState.refetch();
  };

  const valueBillsStateContextProvider = useMemo(
    () => ({
      handleRefreshBillsStates,
      billSummariesState,
      billListState,
    }),
    [handleRefreshBillsStates, billSummariesState, billListState],
  );

  return (
    <BillsStateContext.Provider value={valueBillsStateContextProvider}>
      {children}
    </BillsStateContext.Provider>
  );
}

function useBillsState() {
  const context = useContext(BillsStateContext);

  if (!context) {
    throw new Error('useBillsState must be used within BillsStateProvider');
  }

  return context;
}

export { useBillsState, BillsStateProvider };

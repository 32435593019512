import Grid from '@mui/material/Grid';
import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import {
  Button,
  DrawerFilter,
  HapDrawer,
  Icon,
  Pill,
  useDrawerFilter,
} from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { COMPANY_USER_REGISTER_STATUS_DATA } from '@services/CompanySetupService/dtos/ListCompanyDTO';

import { useFilterParams } from '@hooks';
import { SearchField } from '@components';
import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';

import { useCompanySetupListState } from '../../hooks';

export function CompanySetupListSubHeader() {
  const { hasFilters } = useFilterParams();
  const [filtersVisible, setFiltersVisible] = useState(false);
  const { applyFilters, filterCount, cleanFilters } = useDrawerFilter();
  const { companySetupListState } = useCompanySetupListState();
  const { user } = useAuth();

  const isBackoffice = user?.companyType === UserType.Backoffice;

  if (!isBackoffice) {
    return null;
  }

  const { isError, isLoading } = companySetupListState;

  const totalCompanySetupListLength =
    companySetupListState?.data?.pages[0].total ?? 0;

  const isResultEmpty = totalCompanySetupListLength === 0;

  const isEmpty = isResultEmpty && !hasFilters;

  const disableButtonSearch = isLoading && hasFilters;

  const filterDisabled =
    (!filterCount && isLoading) || (!filterCount && isResultEmpty);

  if ((!hasFilters && isLoading) || isError || isEmpty) {
    return null;
  }

  const getStatuses = () => {
    return Object.entries(COMPANY_USER_REGISTER_STATUS_DATA).map(
      ([key, value]) => {
        const data = COMPANY_USER_REGISTER_STATUS_DATA as any;

        return {
          label: value.text,
          value: key,
          content: (
            <Pill fitContent text={data[key].text} color={data[key].color} />
          ),
        };
      },
    );
  };
  const getFilters = () => {
    return (
      <DrawerFilter
        filterKey="status"
        label="status"
        title="Status da empresa"
        filters={getStatuses()}
        isMultiple={false}
      />
    );
  };

  return (
    <>
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} sm={7} md={9}>
          <SearchField
            currentResultLength={totalCompanySetupListLength}
            placeholder="Nome ou CNPJ da empresa"
            disableSearch={disableButtonSearch}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={3}>
          <Button
            variant={!filterCount ? 'outlined' : 'contained'}
            color="secondary"
            fullWidth
            disableRipple
            endIcon={!filterCount && <Icon name="filter" size={20} />}
            onClick={() => {
              setFiltersVisible(true);
            }}
            disabled={filterDisabled}
          >
            {!filterCount ? 'Aplicar filtro' : 'Ver filtros aplicados'}
          </Button>
        </Grid>
      </Grid>

      <Box display="none">{getFilters()}</Box>

      <HapDrawer
        anchor="right"
        openDrawer={filtersVisible}
        setDrawerOpen={() => setFiltersVisible(!filtersVisible)}
        title="Filtrar empresas"
      >
        <Stack p={2} gap={2}>
          <Typography variant="smallText">
            Selecione como deseja filtrar suas empresas
          </Typography>

          {getFilters()}

          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button
              sx={{ color: COLORS.PRIMARY.BLUE.MAIN }}
              variant="outlined"
              onClick={() => {
                cleanFilters();
                setFiltersVisible(false);
              }}
            >
              Limpar e fechar
            </Button>
            <Button
              sx={{ backgroundColor: COLORS.PRIMARY.BLUE.MAIN }}
              variant="contained"
              onClick={() => {
                applyFilters();
                setFiltersVisible(false);
              }}
            >
              Filtrar agora
            </Button>
          </Box>
        </Stack>
      </HapDrawer>
    </>
  );
}

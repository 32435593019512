import { Button, Typography } from '@mui/material';

import {
  useDrawerFilter,
  FilterKeyEnum,
  DrawerFilter,
  DateFilter,
  HapDrawer,
  COLORS,
  Gender,
  Pill,
} from '@hapvida/hapvida-core-components';

import { MOVEMENT_STATUS_COLOR, MOVEMENT_STATUS_NAME } from '@constants';
import { MovementActionType } from '@components';
import { useCompany } from '@hooks';
import {
  movementsTypes,
  statuses,
} from '@flows/private/movements/constants/common';

import { FooterDrawer, WrapperFilter } from './styles';

type FilterProps = {
  label: string;
  value: string;
  content: React.ReactElement;
};

const onStatus = () => {
  const statusComponents: FilterProps[] = [];
  statuses.forEach(status => {
    const { value, label } = status;
    const item = (
      <Pill
        text={MOVEMENT_STATUS_NAME[value] ?? '-'}
        color={MOVEMENT_STATUS_COLOR[value]}
      />
    );

    const obj = {
      content: item,
      label,
      value,
    };

    statusComponents.push(obj);
  });

  return statusComponents;
};

const onTypeMovement = () => {
  const typeMovimentationComponents: FilterProps[] = [];
  movementsTypes.forEach(movement => {
    const { label, value } = movement;
    const item = <MovementActionType movementType={value} />;
    const obj = {
      content: item,
      value,
      label,
    };
    typeMovimentationComponents.push(obj);
  });
  return typeMovimentationComponents;
};

interface MovementsFiltersDrawerProps {
  toggleDrawer: () => void;
  visibleDrawer: boolean;
}

export function MovementsFiltersDrawer({
  visibleDrawer,
  toggleDrawer,
}: MovementsFiltersDrawerProps) {
  const { applyFilters, cleanFilters } = useDrawerFilter();
  const { loadCompanyFilterList } = useCompany();

  const isIndividualMovement = false;

  const handleCleanFilters = () => {
    cleanFilters();
    toggleDrawer();
  };

  const handleApplyFilters = () => {
    applyFilters();
    toggleDrawer();
  };

  return (
    <HapDrawer
      title="Filtrar movimentações"
      setDrawerOpen={toggleDrawer}
      openDrawer={visibleDrawer}
      anchor="right"
    >
      <WrapperFilter>
        <Typography variant="smallText">
          Selecione como deseja filtrar suas movimentações
        </Typography>

        {isIndividualMovement && (
          <DrawerFilter
            title="Tipo de movimentação"
            label="tipo de moviemntação"
            filters={onTypeMovement()}
            filterKey="type"
          />
        )}

        <DrawerFilter
          title="Status das movimentações"
          filters={onStatus()}
          filterKey="status"
          label="status"
        />

        <DrawerFilter
          filterKey={FilterKeyEnum.ORGANIZATIONAL_GROUP}
          searchFieldProps={{
            placeholder: 'Empresa, CNPJ ou contrato',
          }}
          loadNextPage={loadCompanyFilterList}
          title="Matriz e coligadas"
          gender={Gender.Feminine}
          label="empresas"
          filters={[]}
        />

        <DateFilter />
      </WrapperFilter>

      <FooterDrawer gap={2}>
        <Button
          sx={{ color: COLORS.PRIMARY.BLUE.MAIN }}
          onClick={handleCleanFilters}
          variant="outlined"
        >
          Limpar e fechar
        </Button>
        <Button
          sx={{ backgroundColor: COLORS.PRIMARY.BLUE.MAIN }}
          onClick={handleApplyFilters}
          variant="contained"
        >
          Filtrar agora
        </Button>
      </FooterDrawer>
    </HapDrawer>
  );
}

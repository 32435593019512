import { Stack, Typography } from '@mui/material';
import { FormState } from 'react-hook-form';

import { CountDown } from '@hapvida/hapvida-core-components';

import { UserForm } from '@flows/private/users/pages/UserDetails/components/UserEditForm/types';
import { useUserActionsState } from '@flows/private/users/pages/UserDetails/components/UserEditForm/hooks';
import { UserActionsOptionsEnum } from '@flows/private/users/pages/UserDetails/components/UserEditForm/constants/userActionsOptions';

import { useResendConfirmationEmail } from './hooks';

interface ResendConfirmationEmailProps {
  formState: FormState<UserForm>;
}

export function ResendConfirmationEmail({
  formState,
}: Readonly<ResendConfirmationEmailProps>) {
  const { dirtyFields } = formState;

  const { handleResendConfirmationEmail } = useResendConfirmationEmail();
  const { getIsDisabled, getIsLoading } = useUserActionsState();

  const isDisabled =
    getIsDisabled(UserActionsOptionsEnum.RESEND_CONFIRMATION_EMAIL) ||
    dirtyFields.email;
  const isLoading = getIsLoading(
    UserActionsOptionsEnum.RESEND_CONFIRMATION_EMAIL,
  );
  return (
    <Stack marginTop={2}>
      <Typography mb={1} variant="link">
        Enviamos um e-mail de confirmação para o usuário
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <CountDown
          available={{
            info: 'Não recebeu o e-mail?',
            clickText: 'Reenviar agora',
          }}
          unavaible={{
            loadingText: 'Aguarde',
            loadingInfo: 'para reenviar o e-mail de confirmação',
          }}
          onClick={isDisabled ? undefined : handleResendConfirmationEmail}
          disabled={isDisabled}
          loading={isLoading}
          timer={60}
        />
      </Stack>
    </Stack>
  );
}

import { Outlet } from 'react-router-dom';
import { Stack, ThemeProvider } from '@mui/material';

import { useCurrentThemeState } from '@hooks';

import { Sidebar } from './components';

export function PrivateTemplate() {
  const { currentTheme } = useCurrentThemeState();
  return (
    <Stack direction="row">
      <ThemeProvider theme={currentTheme}>
        <Sidebar />
      </ThemeProvider>
      <Stack overflow="auto" flex={1} component="main">
        <Outlet />
      </Stack>
    </Stack>
  );
}

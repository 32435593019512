import {
  DrawerFilterProvider,
  Header,
  useSidebar,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { UserListLayout } from './layout';
import { UserListRouter } from './router';

export function UserListPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <Header
        // TODO: código comentado para subida prévia de homologação. Será corrigido/validado na US 269725
        // subHeader={<UserListSubHeader />}
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        onLogOut={logOut}
        user={user}
        route={{
          icon: UserListRouter.Icon,
          name: UserListRouter.name,
        }}
      >
        <UserListLayout />
      </Header>
    </DrawerFilterProvider>
  );
}

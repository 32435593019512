import { KeyboardEvent } from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';

interface SearchFieldProps {
  placeholder: string;
  currentResultLength?: number;
  disableSearch?: boolean;
  disableGutters?: boolean;
}

interface SearchForm {
  search: string;
}

interface SearchFieldLayoutProps {
  placeholder: string;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<SearchForm>;
  formState: FormState<SearchForm>;
  inputRef: React.RefObject<HTMLInputElement>;
  handlePressEnter: ({ key }: KeyboardEvent) => void;
  handleClickMagnifier: () => void;
  handleClickClose: () => void;
  showCloseButton: boolean;
  disableSearch: boolean;
  disableGutters?: boolean;
}

enum KeyboardEvents {
  ENTER = 'Enter',
}

enum SearchLength {
  MIN = 5,
}

export {
  SearchFieldProps,
  SearchFieldLayoutProps,
  KeyboardEvents,
  SearchLength,
  SearchForm,
};

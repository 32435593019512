import { useTheme } from '@mui/material';

import HapvidaLogo from '../../../../assets/images/hapvida-logo-new.svg';
import NdiLogo from '../../../../assets/images/ndi-logo-new.svg';

import HapvidaLogoLight from '../../../../assets/images/hapvida-logo-new-light.svg';
import NdiLogoLight from '../../../../assets/images/ndi-logo-new-light.svg';

import { DrawerHeaderContainer, IconButton } from './styles';
import { useSidebar } from '../../hooks';
import Icon from '../../../Icon';

interface SidebarHeaderProps {
  isColorful?: boolean;
}

export function SidebarHeader({
  isColorful = true,
}: Readonly<SidebarHeaderProps>) {
  const { toggleDrawer, drawerOpen } = useSidebar();
  const theme = useTheme();

  return (
    <DrawerHeaderContainer>
      <IconButton
        onClick={toggleDrawer}
        disableRipple
        style={{ flex: drawerOpen ? 0 : 1 }}
      >
        <Icon
          color={theme.palette.action.primary.active}
          name="drawer"
          size={32}
        />
      </IconButton>
      {drawerOpen && (
        <>
          <img
            src={isColorful ? HapvidaLogo : HapvidaLogoLight}
            alt="Hapvida"
            height={24}
          />
          <img
            src={isColorful ? NdiLogo : NdiLogoLight}
            height={26}
            alt="Ndi"
          />
        </>
      )}
    </DrawerHeaderContainer>
  );
}

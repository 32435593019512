import { useMediaQuery, useTheme } from '@mui/material';

import { SummaryCardNew } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { SUMMARY_DATA } from '../../constants/summary';

interface CompanyDetailsSummaryCardProps {
  companyBeneficiariesSummaryCardData?: { [key: string]: number };
}

export function CompanyBeneficiariesSummaryCard({
  companyBeneficiariesSummaryCardData,
}: CompanyDetailsSummaryCardProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SummaryCardNew.Container success>
      <SummaryCardNew.Content
        startIconColor={COLORS.SECONDARY.GREEN['+1']}
        summaryValues={companyBeneficiariesSummaryCardData ?? {}}
        perLine={isMobile ? 2 : 6}
        summary={SUMMARY_DATA}
        startIconName="heart"
        defaultValue={0}
        pillColor
      />
    </SummaryCardNew.Container>
  );
}

import { Stack, Typography } from '@mui/material';

import { Company, Icon, Mask, Pill } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import {
  BeneficiaryDetailStatus,
  BeneficiaryDetailsProps,
} from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

import { beneficiaryDocument } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/utils/formatDocumentBeneficiary';
import handleGender from 'utils/handleGender';
import {
  CompanyTypeEnumColor,
  CompanyTypeEnumName,
  CompanyTypeEnum,
  BENEFICIARY_TYPE_NAME,
  BENEFICIARY_TYPE_COLOR,
  HealthOperatorEnum,
} from '@constants';
import Plan, { PlanProps } from '@flows/private/beneficiaries/components/Plan';

import { beneficiaryDetailCivilStatusEnum } from '../constants';

const onPlan = (plan: PlanProps) => <Plan {...plan} />;

const onName = (name: string) => (
  <Typography
    sx={{
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '24px',
      color: COLORS.MONOCHROMATIC.GRAY4,
    }}
  >
    {name}
  </Typography>
);

const onSubHeader = (name: string) => {
  return (
    <Typography
      sx={{
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '16',
        color: COLORS.MONOCHROMATIC.GRAY4,
      }}
    >
      {name}
    </Typography>
  );
};
export const getBeneficiarySummaryRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  const plan = onPlan({
    code: beneficiary.contractDetail?.planHealth?.code,
    name: beneficiary.contractDetail?.planHealth?.name,
    ansCode: beneficiary.contractDetail?.planHealth?.ansCode,
  });
  const name = onName(beneficiary.name?.full);

  const healthOperator =
    beneficiary.healthOperator === HealthOperatorEnum.HAPVIDA ||
    beneficiary.healthOperator === HealthOperatorEnum.CCG
      ? beneficiary.contractDetail.partnerCompany
      : beneficiary.contractDetail.number;

  return [
    { label: 'Nome completo', value: name, xs: 6 },
    {
      label: 'Status',
      value: (
        <>
          <Stack flexDirection="row" alignItems="center">
            <Icon
              name={
                BeneficiaryDetailStatus[beneficiary.statusDetail?.type]
                  ?.iconName
              }
              color={
                BeneficiaryDetailStatus[beneficiary.statusDetail?.type]?.color
              }
              size={20}
            />
            <Typography fontSize="12px">
              <b>
                {BeneficiaryDetailStatus[
                  beneficiary.statusDetail?.type
                ]?.label.toUpperCase() ?? '-'}
              </b>
            </Typography>
          </Stack>
        </>
      ),
      xs: 3,
    },
    {
      label: 'Operadora',
      value: <Company company={beneficiary.healthOperator} />,
      xs: 3,
    },
    {
      label: 'Tipo',
      value:
        (
          <Pill
            fitContent
            color={BENEFICIARY_TYPE_COLOR[beneficiary.type]}
            text={BENEFICIARY_TYPE_NAME[beneficiary.type]}
          />
        ) ?? '-',
      xs: 3,
    },
    { label: 'Cód. Beneficiário', value: beneficiary.code, xs: 3 },
    {
      label: 'Número do contrato / Código da empresa conveniada',
      value: healthOperator,
      xs: 3,
    },
    { label: 'Plano', value: plan, xs: 3 },
    {
      label: 'Unidade',
      value: beneficiary.contractDetail?.unitCode ?? '-',
      xs: 3,
    },
    {
      label: 'CPF',
      value: beneficiaryDocument(beneficiary.document?.personalDocument),
      xs: 3,
    },
    {
      label: 'Data de nascimento',
      value: Mask.date(beneficiary.birthDate),
      xs: 3,
    },
    { label: 'Sexo', value: handleGender(beneficiary.gender.type), xs: 3 },

    {
      label: 'Estado Civil',
      value: beneficiaryDetailCivilStatusEnum[beneficiary.civilStatus] ?? '-',
      xs: 3,
    },
    {
      label: 'Código do grupo da carência',
      value: beneficiary.waitingPeriod?.code ?? '-',
      xs: 3,
    },
    {
      label: 'Data de início da carência',
      value: Mask.date(beneficiary.waitingPeriod?.startDate) ?? '-',
      xs: 3,
    },
    {
      label: 'Data de inclusão do beneficário',
      value: Mask.date(beneficiary.startAt) ?? '-',
      xs: 3,
    },

    { label: '', value: onSubHeader('Empresa'), xs: 12 },
    {
      label: 'CNPJ',
      value: Mask.cnpj(beneficiary.contractDetail.unitDocument),
      xs: 3,
    },
    {
      label: 'Razão Social',
      value: beneficiary.contractDetail?.companyName?.legal ?? '-',
      xs: 3,
    },
    {
      label: 'Nome fantasia',
      value: beneficiary.contractDetail?.unitName?.trade ?? '-',
      xs: 3,
    },
    {
      label: 'Tipo',
      value:
        (
          <Pill
            fitContent
            text={
              CompanyTypeEnumName[
                beneficiary.contractDetail?.companyType as CompanyTypeEnum
              ]
            }
            color={
              CompanyTypeEnumColor[
                beneficiary.contractDetail?.companyType as CompanyTypeEnum
              ]
            }
          />
        ) ?? '-',
      xs: 3,
    },
    {
      label: 'Endereço',
      value: beneficiary.contractDetail?.companyAddress?.street ?? '-',
      xs: 6,
    },
    {
      label: 'UF',
      value: beneficiary.contractDetail?.companyAddress?.stateCode ?? '-',
      xs: 3,
    },
    {
      label: 'Cidade',
      value: beneficiary.contractDetail?.companyAddress?.city ?? '-',
      xs: 3,
    },
  ];
};

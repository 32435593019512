import { Button, Typography } from '@mui/material';

import {
  useDrawerFilter,
  FilterKeyEnum,
  DrawerFilter,
  HapDrawer,
  Gender,
  COLORS,
} from '@hapvida/hapvida-core-components';

import { useCompany } from '@hooks';

import { FooterDrawer, WrapperFilter } from './styles';

interface BeneficiariesFiltersDrawerProps {
  toggleDrawer: () => void;
  visibleDrawer: boolean;
}

export function BeneficiariesFiltersDrawer({
  visibleDrawer,
  toggleDrawer,
}: BeneficiariesFiltersDrawerProps) {
  const { applyFilters, cleanFilters } = useDrawerFilter();
  const { loadCompanyFilterList } = useCompany();

  const handleCleanFilters = () => {
    cleanFilters();
    toggleDrawer();
  };

  const handleApplyFilters = () => {
    applyFilters();
    toggleDrawer();
  };

  return (
    <HapDrawer
      title="Filtrar resumo de beneficiários"
      setDrawerOpen={toggleDrawer}
      openDrawer={visibleDrawer}
      anchor="right"
    >
      <WrapperFilter>
        <Typography variant="smallText">
          Selecione como deseja filtrar o resumo de beneficiários
        </Typography>

        <DrawerFilter
          filterKey={FilterKeyEnum.ORGANIZATIONAL_GROUP}
          searchFieldProps={{
            placeholder: 'Empresa, CNPJ ou contrato',
          }}
          loadNextPage={loadCompanyFilterList}
          title="Matriz e coligadas"
          gender={Gender.Feminine}
          label="empresas"
          filters={[]}
        />
      </WrapperFilter>

      <FooterDrawer gap={2}>
        <Button
          sx={{ color: COLORS.PRIMARY.BLUE.MAIN }}
          onClick={handleCleanFilters}
          variant="outlined"
        >
          Limpar e fechar
        </Button>
        <Button
          sx={{ backgroundColor: COLORS.PRIMARY.BLUE.MAIN }}
          onClick={handleApplyFilters}
          variant="contained"
        >
          Filtrar agora
        </Button>
      </FooterDrawer>
    </HapDrawer>
  );
}

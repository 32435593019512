import { useState, KeyboardEvent, useCallback, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';

import { SearchFieldProps, KeyboardEvents, SearchForm } from './types';
import { schema } from './validation';
import { SearchFieldLayout } from './layout';

export function SearchField({
  placeholder,
  currentResultLength,
  disableSearch = false,
  disableGutters,
}: SearchFieldProps) {
  const { handleSearchChange, searchParam } = useFilterParams();
  const { filterCount } = useDrawerFilter();

  const { handleSubmit, formState, register, reset, watch } =
    useForm<SearchForm>({
      defaultValues: {
        search: searchParam.search ?? '',
      },
      resolver: yupResolver(schema),
      mode: 'onSubmit',
    });

  const inputRef = useRef<HTMLInputElement>(null);

  const [prevSearchState, setPrevSearchState] = useState<string>(
    searchParam.search ?? '',
  );

  const isResultEmpty = currentResultLength === 0;
  const hasFilterCount = filterCount > 0;
  const isSearchEmpty = !searchParam.search;

  const disableButtonSearch =
    (isResultEmpty && hasFilterCount && isSearchEmpty) || disableSearch;

  const resetForm = useCallback(() => {
    reset({ search: '' });
  }, [reset]);

  useEffect(() => {
    const isUrlSearchParamEmpty = !searchParam.search;
    if (isUrlSearchParamEmpty) {
      resetForm();
      setPrevSearchState('');
    }
  }, [searchParam.search]);

  const handleSubmitForm = useCallback(
    (data: SearchForm) => {
      const { search } = data;
      if (search === prevSearchState) {
        return;
      }

      setPrevSearchState(search);

      handleSearchChange(search);
    },
    [prevSearchState, handleSearchChange, formState],
  );

  const handlePressEnter = useCallback(
    ({ key }: KeyboardEvent) => {
      const isEnter = key === KeyboardEvents.ENTER;
      if (isEnter) {
        handleSubmit(data => handleSubmitForm(data));
      }
    },
    [KeyboardEvents, handleSubmitForm, handleSubmit],
  );

  const handleClickMagnifier = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const handleClickClose = useCallback(() => {
    resetForm();
    inputRef.current?.focus();
  }, [inputRef, resetForm]);

  return (
    <SearchFieldLayout
      disableSearch={disableButtonSearch}
      formState={formState}
      handleClickClose={handleClickClose}
      handleClickMagnifier={handleClickMagnifier}
      handlePressEnter={handlePressEnter}
      inputRef={inputRef}
      onSubmit={handleSubmit(handleSubmitForm)}
      placeholder={placeholder}
      register={register}
      showCloseButton={Boolean(watch('search'))}
      disableGutters={disableGutters}
    />
  );
}

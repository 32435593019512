import { COLORS, IconName } from '@hapvida/hapvida-core-components';

export enum UserStatusEnum {
  PENDING = 'Pending',
  ACTIVE = 'Active',
}

export const USER_STATUS_NAME: Record<UserStatusEnum, string> = {
  [UserStatusEnum.PENDING]: 'Pendente',
  [UserStatusEnum.ACTIVE]: 'Ativo',
};

export const USER_STATUS_COLOR: Record<UserStatusEnum, string> = {
  [UserStatusEnum.PENDING]: COLORS.PRIMARY.BLUE['+1'],
  [UserStatusEnum.ACTIVE]: COLORS.PRIMARY.GREEN['+1'],
};

export const USER_STATUS_ICON_NAME: Record<UserStatusEnum, IconName> = {
  [UserStatusEnum.ACTIVE]: 'marked-circle-outline',
  [UserStatusEnum.PENDING]: 'alert-circle',
};

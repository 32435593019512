import { Avatar, Box, Typography } from '@mui/material';

import { DataGrid, Mask, Pill } from '@hapvida/hapvida-core-components';

import { COMPANY_USER_REGISTER_STATUS_DATA } from '@services/CompanySetupService/dtos/ListCompanyDTO';

import { useHomeState } from '../../hooks';

export function CompanySummary() {
  const { companyDetailsState } = useHomeState();

  if (!companyDetailsState.isSuccess) return null;

  const { company, user } = companyDetailsState.data;

  return (
    <DataGrid
      containerStyles={{
        margin: 0,
      }}
      customSpacing={3}
      rows={[
        {
          label: 'CNPJ',
          xs: 12,
          value: (
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">
                {company.document && Mask.cnpj(company.document)}
              </Typography>
              <Avatar
                sx={{
                  marginRight: 2,
                }}
              />
            </Box>
          ),
        },
        {
          label: 'Razão social',
          xs: 4,
          value: <b>{company?.name?.legal || ''}</b>,
        },
        {
          label: 'Nome fantasia',
          xs: 4,
          value: <b>{company?.name?.trade || ''}</b>,
        },
        {
          label: 'Status',
          xs: 4,
          value: (
            <Pill
              color={COMPANY_USER_REGISTER_STATUS_DATA[company.status]?.color}
              text={COMPANY_USER_REGISTER_STATUS_DATA[company.status]?.text}
              fitContent
            />
          ),
        },
        {
          label: 'Responsável da empresa',
          xs: 4,
          value: <b>{user?.name || '-'}</b>,
        },
        {
          label: 'E-mail do responsável',
          xs: 4,
          value: user?.email || '-',
        },
        {
          label: 'Número do responsável',
          xs: 4,
          value: user?.phone ? Mask.phone(user?.phone) : '-',
        },
      ]}
    />
  );
}

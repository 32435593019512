import { Stack, StackProps, styled as muiStyled } from '@mui/material';

interface RouteItemInterface {
  selected: boolean;
  disabled: boolean;
}

export const RouteItem = muiStyled(({ ...props }) => (
  <Stack component="li" {...props} />
))<StackProps & RouteItemInterface>(({ selected, disabled, theme }) => ({
  borderRadius: '8px',
  alignItems: 'center',
  padding: 16,
  gap: 8,

  '.MuiTypography-root': {
    color: theme.palette.action.secondary.contrastText,
  },

  svg: {
    color: theme.palette.action.secondary.activeIcon,
  },

  ':hover': {
    cursor: disabled ? 'not-allowed' : 'pointer',
    background: disabled
      ? 'transparent'
      : theme.palette.action.secondary.activeBackground,
  },

  ...(disabled && {
    opacity: 0.5,
    background: 'transparent',
    svg: {
      color: theme.palette.action.secondary.disabled,
    },
    '.MuiTypography-root': {
      color: theme.palette.action.secondary.disabled,
    },
  }),

  ...(selected && {
    background: theme.palette.action.secondary.activeBackground,
    '.MuiTypography-root': {
      fontWeight: 600,
      color: theme.palette.action.secondary.contrastText,
    },
    svg: {
      color: theme.palette.action.secondary.selectedIcon,
    },
  }),
}));

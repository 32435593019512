import { useParams } from 'react-router-dom';

import { Header, useSidebar } from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import BillListRouter from '../List/router';
import { useBillState } from './hooks';
import { BillLayout } from './layout';
import Router from './router';

function BillDetail() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { billDetailsState } = useBillState();
  const { logOut, user } = useAuth();
  const { id } = useParams();

  return (
    <Header
      toggleDrawer={toggleDrawer}
      drawerOpen={drawerOpen}
      subRoute={Router.name}
      onLogOut={logOut}
      user={user}
      route={{
        name: `${billDetailsState.data?.number ?? id}`,
        navigateBackPath: BillListRouter.path,
        icon: Router.Icon,
      }}
    >
      <BillLayout />
    </Header>
  );
}

export default BillDetail;

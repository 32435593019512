import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { formatISO, startOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';

import { FilterKeyEnum } from '@hapvida/hapvida-core-components';

import CompanyService, { CompanyDetailsProps } from '@services/CompanyService';
import { PendenciesProps } from '@services/CompanyService/dtos/PendenciesDTO';
import { BillListProps } from '@services/FinancialService/dtos/BillsListDTO';
import FinancialService from '@services/FinancialService';
import MovimentationService, {
  MovementsSummaryProps,
} from '@services/MovimentationService';
import BeneficiaryService, {
  BeneficiariesSummaryProps,
} from '@services/BeneficiaryService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';

interface HomeStateContextData {
  beneficiariesSummaryState: UseQueryResult<BeneficiariesSummaryProps>;
  homeMovementsSummaryState: UseQueryResult<MovementsSummaryProps[]>;
  companyDetailsState: UseQueryResult<CompanyDetailsProps>;
  billsSummaryState: UseQueryResult<BillListProps>;
  pendenciesState: UseQueryResult<PendenciesProps>;
}

const HomeStateContext = createContext<HomeStateContextData>(
  {} as HomeStateContextData,
);

function HomeStateProvider({ children }: PropsWithChildren<{}>) {
  const { mappedFilters } = useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const { user, selectedCompany } = useAuth();

  const companyService = useMemo(() => new CompanyService(), []);

  const currentMonth = useMemo(
    () => ({
      [FilterKeyEnum.START_DATE]: formatISO(startOfMonth(new Date())),
      [FilterKeyEnum.END_DATE]: formatISO(new Date()),
    }),
    [],
  );

  const backofficeUser = user?.companyType === UserType.Backoffice;
  const companyId = selectedCompany?.id ?? '';

  const pendenciesState = useQuery<PendenciesProps>({
    queryKey: ['pendenciesState', companyId],
    queryFn: () =>
      companyService.fetchPendencies({
        id: companyId,
      }),
    enabled: Boolean(companyId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const companyDetailsState = useQuery<CompanyDetailsProps>({
    queryKey: ['companyDetailsState', companyId],
    queryFn: () =>
      companyService.fetchCompanyDetail({
        id: companyId,
      }),
    enabled: Boolean(companyId) && backofficeUser,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const billsSummaryState = useQuery<BillListProps>({
    queryKey: ['billsSummaryState', currentMonth, companyId],
    queryFn: () => {
      const financialService = new FinancialService();
      return financialService.getBillsList({
        ...currentMonth,
        companyId,
        offset: 0,
        limit: 9,
      });
    },
    enabled: Boolean(companyId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const beneficiariesSummaryState = useQuery<BeneficiariesSummaryProps>({
    queryKey: ['beneficiariesSummaryState', mappedFilters, companyId],
    queryFn: () => {
      const beneficiaryService = new BeneficiaryService();
      return beneficiaryService.fetchBeneficiariesSummary({
        ...mappedFilters,
        companyId,
      });
    },
    enabled: Boolean(companyId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const homeMovementsSummaryState = useQuery<MovementsSummaryProps[]>({
    queryKey: ['homeMovementsSummaryState', currentMonth, companyId],
    queryFn: () => {
      const movementService = new MovimentationService();
      return movementService.fetchMovementsSummary({
        ...currentMonth,
        companyId,
      });
    },
    enabled: Boolean(companyId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const valueHomeStateContextProvider = useMemo(
    () => ({
      beneficiariesSummaryState,
      homeMovementsSummaryState,
      companyDetailsState,
      billsSummaryState,
      pendenciesState,
    }),
    [
      beneficiariesSummaryState,
      homeMovementsSummaryState,
      companyDetailsState,
      billsSummaryState,
      pendenciesState,
    ],
  );

  return (
    <HomeStateContext.Provider value={valueHomeStateContextProvider}>
      {children}
    </HomeStateContext.Provider>
  );
}

function useHomeState() {
  const context = useContext(HomeStateContext);

  if (!context) {
    throw new Error('useHomeState must be used within HomeStateProvider');
  }

  return context;
}

export { useHomeState, HomeStateProvider };

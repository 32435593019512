import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

import BeneficiaryService from '@services/BeneficiaryService';

import { useAuth } from 'hooks/useAuth';
import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { MOVEMENTS_PARAMS_ID } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/constants';

export function useBeneficiaryMovementsState() {
  const { enqueueSnackbar } = useSnackbar();
  const { beneficiaryId = '' } = useParams();
  const { selectedCompany } = useAuth();
  const { currentPage: movementsCurrentPage, tableOrder: movementsTableOrder } =
    useFilterParams({
      paramsId: MOVEMENTS_PARAMS_ID,
    });

  const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

  const companyId = selectedCompany?.id ?? '';

  const beneficiaryMovementsState = useQuery({
    queryKey: [
      'beneficiaryMovementsState',
      companyId,
      beneficiaryId,
      movementsCurrentPage,
      movementsTableOrder,
    ],
    queryFn: () =>
      beneficiaryService.fetchMovementsTableById({
        companyId,
        beneficiaryId,
        limit: 10,
        offset: (movementsCurrentPage - 1) * 10,
        filters: { ...movementsTableOrder },
      }),
    enabled: Boolean(beneficiaryId) && Boolean(companyId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshBeneficiaryMovementsState = () => {
    beneficiaryMovementsState.remove();

    beneficiaryMovementsState.refetch();
  };

  return {
    beneficiaryMovementsState,
    refreshBeneficiaryMovementsState,
  };
}

import * as Yup from 'yup';

export const schema = Yup.object({
  name: Yup.string()
    .min(1, '')
    .required()
    .max(200, 'O nome do usuário deve ter no máximo 200 caracteres')
    .default(''),
  email: Yup.string()
    .min(1, '')
    .email('E-mail inválido.')
    .required()
    .default('')
    .test(
      'hapvida-intermedica-email',
      'O e-mail digitado precisa ser do domínio da Hapvida ou Notre Dame Intermédica. ',
      function validEmail(value) {
        if (value) {
          return /@hapvida\.com\.br$|@intermedica\.com\.br$/.test(value);
        }
        return false;
      },
    ),
  confirmedEmail: Yup.string()
    .min(1, '')
    .email('Confirmação de e-mail inválida.')
    .required()
    .default('')
    .test(
      'email-matching',
      'Confirmação de e-mail inválida.',
      function emailConfirmed(valueEmail) {
        return valueEmail === this.parent.email;
      },
    ),
  phone: Yup.string()
    .min(1, '')
    .required()
    .test('len', 'Número de telefone inválido.', val => {
      if (val) {
        return val.length === 14 || val.length === 15;
      }
      return false;
    })
    .default(''),
  userCreationAllowed: Yup.boolean().oneOf([true]).required(),
});

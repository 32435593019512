import { ButtonBaseProps, Stack, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { CompanyButton, StyledTypography } from './styles';
import { useSidebar } from '../../hooks';

interface SidebarButtonProps extends ButtonBaseProps {
  onClick: () => void;
  mainText?: string;
  subText?: string;
  icon: ReactNode;
}

export function SidebarButton({
  mainText,
  subText,
  onClick,
  icon,
  ...buttonBaseProps
}: Readonly<SidebarButtonProps>) {
  const { drawerOpen } = useSidebar();
  const theme = useTheme();

  return (
    <CompanyButton
      style={{ justifyContent: drawerOpen ? 'flex-start' : 'center' }}
      onClick={onClick}
      {...buttonBaseProps}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        overflow="hidden"
        gap={1.5}
      >
        {icon}

        {drawerOpen && (
          <Stack overflow="hidden" gap={0.5}>
            {mainText && (
              <StyledTypography
                color={theme.palette.text.primary}
                variant="link"
              >
                {mainText}
              </StyledTypography>
            )}
            {subText && (
              <StyledTypography
                color={theme.palette.text.secondary}
                variant="caption"
              >
                {subText}
              </StyledTypography>
            )}
          </Stack>
        )}
      </Stack>
    </CompanyButton>
  );
}

import { Header, useSidebar } from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { ServiceListRouter } from './router';
import { ServiceListLayout } from './layout';

export function ServiceListPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <Header
      toggleDrawer={toggleDrawer}
      drawerOpen={drawerOpen}
      onLogOut={logOut}
      user={user}
      route={{
        icon: ServiceListRouter.Icon,
        name: ServiceListRouter.name,
      }}
    >
      <ServiceListLayout />
    </Header>
  );
}

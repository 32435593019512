export interface PartnerCompanyListMockContentProps {
  partnerCompany: string;
  mainPartnerCompany: string;
  isMainPartnerCompany: boolean;
  profile: {
    id: string;
    name: string;
  };
}

export interface PartnerCompanyListMockProps {
  totalPerPage: number;
  total: number;
  content: PartnerCompanyListMockContentProps[];
}

export const partnerCompanyListMock: PartnerCompanyListMockProps = {
  totalPerPage: 10,
  total: 12,
  content: [
    {
      partnerCompany: '00213',
      mainPartnerCompany: '00213',
      isMainPartnerCompany: true,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Administrador',
      },
    },
    {
      partnerCompany: 'ZZZZ',
      mainPartnerCompany: '00213',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Financeiro',
      },
    },
    {
      partnerCompany: 'YYYY',
      mainPartnerCompany: '00213',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Administrador',
      },
    },
    {
      partnerCompany: 'AAAA',
      mainPartnerCompany: '00213',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Administrador',
      },
    },
    {
      partnerCompany: '32213',
      mainPartnerCompany: '43213',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Administrador',
      },
    },
    {
      partnerCompany: 'Z33ZZ',
      mainPartnerCompany: '43213',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Financeiro',
      },
    },
    {
      partnerCompany: 'FDS3',
      mainPartnerCompany: '43213',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Administrador',
      },
    },
    {
      partnerCompany: 'DS32',
      mainPartnerCompany: '43213',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Administrador',
      },
    },
    {
      partnerCompany: 'FD12',
      mainPartnerCompany: '43213',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Administrador',
      },
    },
    {
      partnerCompany: '43FD',
      mainPartnerCompany: '43FD',
      isMainPartnerCompany: true,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Financeiro',
      },
    },
    {
      partnerCompany: 'DS21',
      mainPartnerCompany: '43FD',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Administrador',
      },
    },
    {
      partnerCompany: 'DD43',
      mainPartnerCompany: '43FD',
      isMainPartnerCompany: false,
      profile: {
        id: '2b5887e4-fb50-4ce9-9754-91bc9b47a4a5',
        name: 'Administrador',
      },
    },
  ],
};

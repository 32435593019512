import { createTheme } from '@mui/material/styles';
import { baseThemeOptions } from './base.theme';

export const darkTheme = createTheme({
  ...baseThemeOptions,
  palette: {
    ...baseThemeOptions.palette,
    divider: '#ACB5BD',
    background: {
      ...baseThemeOptions.palette?.background,
      paper: '#495057',
      highlight: '#575E64',
      default: '#FFFFFF',
    },
    stroke: {
      ...baseThemeOptions.palette?.stroke,
      default: '#4A7CB8',
    },
    text: {
      ...baseThemeOptions.palette?.text,
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
    },
    action: {
      ...baseThemeOptions.palette?.action,
      primary: {
        ...baseThemeOptions.palette?.action?.primary,
        active: '#FFFFFF',
      },
      secondary: {
        ...baseThemeOptions.palette?.action?.secondary,
        disabled: '#ACB5BD',
        contrastText: '#FFFFFF',
        activeBackground: '#5A584E',
        activeIcon: '#F49E00',
        selectedIcon: '#F49E00',
      },
      tertiary: {
        ...baseThemeOptions.palette?.action?.tertiary,
        active: '#0054B8',
      },
    },
  },
});

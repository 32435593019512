import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import RegistrationImage from '@assets/images/registration.svg';
import { CompanyStatusEnum } from '@constants';
import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';

import { useHomeState } from './hooks';
import {
  BeneficiariesSummary,
  MovementsSummary,
  HomeQuickActions,
  CompanySummary,
  BillsSummary,
  HomeAlerts,
} from './components';

export function HomeLayout() {
  const {
    beneficiariesSummaryState,
    homeMovementsSummaryState,
    companyDetailsState,
    billsSummaryState,
    pendenciesState,
  } = useHomeState();
  const { user, selectedCompany } = useAuth();

  const activeCompany = selectedCompany?.status === CompanyStatusEnum.ACTIVE;
  const backofficeUser = user?.companyType === UserType.Backoffice;
  const userName = user?.name ?? '';

  const hasError =
    beneficiariesSummaryState.isError ||
    homeMovementsSummaryState.isError ||
    companyDetailsState.isError ||
    billsSummaryState.isError ||
    pendenciesState.isError;

  const handleRefreshStates = () => {
    if (backofficeUser) companyDetailsState.remove();
    beneficiariesSummaryState.remove();
    homeMovementsSummaryState.remove();
    billsSummaryState.remove();
    pendenciesState.remove();

    if (backofficeUser) companyDetailsState.refetch();
    beneficiariesSummaryState.refetch();
    homeMovementsSummaryState.refetch();
    billsSummaryState.refetch();
    pendenciesState.refetch();
  };

  if (hasError) {
    return <ErrorLoadingPage onReloadClick={handleRefreshStates} />;
  }

  if (pendenciesState.isFetching || companyDetailsState.isFetching) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (!activeCompany && !backofficeUser) {
    return (
      <Stack alignItems="center" gap={3} padding={8}>
        <img alt="Cadastro em andamento" src={RegistrationImage} height={112} />
        <Typography variant="h6" textAlign="center" maxWidth={472}>
          Estamos finalizando a configuração da sua conta, em breve, tudo estará
          pronto para você.
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={3} paddingTop={3} paddingBottom={8}>
      <Typography variant="h6">
        {backofficeUser
          ? 'Resumo da empresa'
          : `${userName}, esse é o resumo da sua organização 👏`}
      </Typography>

      <HomeAlerts />

      {!backofficeUser && <HomeQuickActions />}

      {backofficeUser && <CompanySummary />}

      <BeneficiariesSummary />

      <BillsSummary />

      <MovementsSummary />
    </Stack>
  );
}

import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from '@hapvida/hapvida-core-components/src/components/layouts/DrawerLayout';

import { IAppRoute } from 'routes';

import AddAccountPage from '.';

export const CreateUserRouter: IAppRoute = {
  Page: AddAccountPage,
  path: '/usuarios/novo',
  name: 'Novo usuário',
  Icon: <Icon name="account-multiple" />,
  isPrivate: true,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabled: false,
};

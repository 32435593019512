import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';
import { useSnackbar } from 'notistack';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import { CompanyListProps } from '@services/CompanySetupService/dtos/ListCompanyDTO';
import CompanySetupService from '@services/CompanySetupService';
import AuthService from '@services/AuthService';

import { SalesChannelEnum } from '@constants';
import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';

import { companySetupDrawerFilterValues } from '../../constants/companySetupDrawerFilterValues';

export function useCompanySetupListState() {
  const { searchParam, tableOrder, mappedFilters } = useFilterParams();
  const { getInitialFilter, setActiveFilters } = useDrawerFilter();
  const { enqueueSnackbar } = useSnackbar();
  const initialRender = useRef(true);
  const { user } = useAuth();

  const companySetupService = useMemo(() => new CompanySetupService(), []);
  const authService = useMemo(() => new AuthService(), []);

  const isBackoffice = user?.companyType === UserType.Backoffice;

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      const initialFinancialFilters = getInitialFilter({
        filtersConfig: companySetupDrawerFilterValues,
      });
      setActiveFilters(initialFinancialFilters);
    }
  }, []);

  const fetchSalesChannelCompanies = async () => {
    const response = await authService.fetchSalesChannel({
      salesChannelName: user?.salesChannel[0] ?? SalesChannelEnum.CORPORATE,
    });

    return {
      companies: response[0].companies.map(company => ({
        ...company,
        legal: company.name,
        type: company.type ?? 'Company',
      })),
      total: response[0].companies.length,
      totalPerPage: response[0].companies.length,
    };
  };

  const companySetupListState = useInfiniteQuery<CompanyListProps>({
    queryKey: [
      'companySetupListState',
      mappedFilters,
      isBackoffice,
      searchParam,
      tableOrder,
    ],
    queryFn: ({ pageParam = 1 }) => {
      if (isBackoffice) {
        return companySetupService.fetchCompanies({
          offset: 9 * (pageParam - 1),
          limit: 9,
          ...searchParam,
          ...tableOrder,
          ...mappedFilters,
        });
      }
      return fetchSalesChannelCompanies();
    },
    getNextPageParam: (lastPage, allPages) => {
      if (!isBackoffice) {
        return undefined;
      }

      const companiesHasNextPage = lastPage.total > allPages.length * 9;

      if (companiesHasNextPage) {
        return allPages.length + 1;
      }

      return undefined;
    },
    onError: (error: unknown) => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshCompanySetupListStates = () => {
    companySetupListState.remove();

    companySetupListState.refetch();
  };

  return {
    companySetupListState,
    refreshCompanySetupListStates,
  };
}

import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from 'contexts/AuthContext';
import { IAppRoute } from 'routes';

import { CompanySetupListPage } from '.';

export const CompanySetupListRouter: IAppRoute = {
  Page: CompanySetupListPage,
  path: '/empresas',
  name: 'Empresas',
  isPrivate: true,
  Icon: <Icon name="building" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabled: false,
};

import { styled, Typography } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const LinkModal = styled(Typography)(() => ({
  minWidth: '60px',
  height: '20px',
  cursor: 'pointer',
  color: `${COLORS.PRIMARY.BLUE.MAIN}`,
}));

import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes';

import MovimentationStepPage from '.';

const MovimentationStepRouter: IAppDynamicRoute = {
  Page: MovimentationStepPage,
  path: '/movimentacao/:movimentationStep',
  name: 'Nova movimentação',
  isPrivate: true,
  dynamicPath: ({ movimentationStep }) => `/movimentacao/${movimentationStep}`,
  Icon: <Icon name="clipboard-text-outline" />,
  userTypes: [],
  disabled: false,
  hideSidebar: true,
};

export default MovimentationStepRouter;

import { Mask } from '@hapvida/hapvida-core-components';

import { BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

import { beneficiaryDocument } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/utils/formatDocumentBeneficiary';
import handleGender from 'utils/handleGender';
import { BENEFICIARY_TYPE_NAME } from '@constants';

export const getBeneficiaryRegistrationDataRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  return [
    {
      label: 'Dia vencimento',
      value: Mask.date(beneficiary.expirationDay),
      xs: 4,
    },
    {
      label: 'Município atendimento',
      value: beneficiary.treatment?.municipalityCode,
      xs: 4,
    },
    {
      label: 'UF atendimento',
      value: beneficiary.treatment?.code,
      xs: 4,
    },
    { label: 'Nome completo', value: beneficiary.name?.full, xs: 4 },
    {
      label: 'Nome social',
      value: beneficiary.name?.social,
      xs: 4,
    },
    {
      label: 'Nome registro certidão de nascimento',
      value: beneficiary.name?.full,
      xs: 4,
    },

    {
      label: 'Gênero Social',
      value: handleGender(beneficiary.gender?.socialType),
      xs: 4,
    },
    {
      label: 'Sexo',
      value: handleGender(beneficiary.gender?.type),
      xs: 4,
    },
    {
      label: 'CPF',
      value: beneficiaryDocument(beneficiary.document?.personalDocument),
      xs: 4,
    },
    {
      label: 'Data de nascimento',
      value: Mask.date(beneficiary.birthDate),
      xs: 4,
    },
    {
      label: 'Nome da mãe',
      value: beneficiary.name?.mom,
      xs: 4,
    },
    {
      label: 'Nome do pai',
      value: beneficiary.name?.dad,
      xs: 4,
    },
    {
      label: 'Tipo de beneficiário',
      value: BENEFICIARY_TYPE_NAME[beneficiary.type],
      xs: 4,
    },
    {
      label: 'Código Beneficiário Titular',
      value: beneficiary.holderCode,
      xs: 4,
    },
    {
      label: 'Código Beneficiário',
      value: beneficiary.code,
      xs: 4,
    },
    { label: 'Nacionalidade', value: beneficiary.nationality, xs: 4 },
    {
      label: 'Declaração de nascido vivo',
      value: beneficiary.bornAliveDeclaration,
      xs: 4,
    },
  ];
};

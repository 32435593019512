import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { OutlinedIconButton, StyledCard } from './styles';

interface EditableCardProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  onEdit?: () => void;
}

function EditableCard({
  title,
  subtitle,
  children,
  onEdit,
}: EditableCardProps) {
  return (
    <StyledCard>
      <Stack direction="column" gap={3}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column">
            {title && <Typography variant="subtitle1">{title}</Typography>}
            {subtitle && (
              <Typography variant="smallText">{subtitle}</Typography>
            )}
          </Stack>
          {onEdit && (
            <OutlinedIconButton onClick={onEdit}>
              <Icon name="edit" />
            </OutlinedIconButton>
          )}
        </Stack>
        {children}
      </Stack>
    </StyledCard>
  );
}

export default EditableCard;

import { Typography, Grid, Stack } from '@mui/material';

import { COLORS, Icon, ModalNew } from '@hapvida/hapvida-core-components';

import { ProfileProps } from '@services/UserService';

import { ProfileResourceEnum } from '@constants';

import { ListItemPermissions, ListPermissions } from './styles';
import { ProfileResourceInfos } from './profileResourceConstants';

interface ProfileResourcesModalProps {
  open: boolean;
  profile: ProfileProps;
  onClose: () => void;
}

export function ProfileResourcesModal({
  open,
  profile,
  onClose,
}: Readonly<ProfileResourcesModalProps>) {
  const resources = profile.resources
    ? (Object.keys(profile.resources) as ProfileResourceEnum[])
    : [];
  return (
    <ModalNew.Container open={open} handleCloseModal={onClose}>
      <ModalNew.Content
        subtitle="Funcionalidades disponíveis:"
        title={`Usuário ${profile.name}`}
      >
        <Grid container>
          {resources
            .filter(key => ProfileResourceInfos[key])
            .map(key => {
              const { title, permissionsResource, icon } =
                ProfileResourceInfos[key];
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={resources.length > 2 ? 4 : 6}
                  key={key}
                  mb={2}
                >
                  <Stack flexDirection="row">
                    <Icon
                      name={icon}
                      size={20}
                      color={COLORS.PRIMARY.ORANGE.MAIN}
                    />
                    <Typography variant="text" fontWeight={700} pl={1}>
                      {title}
                    </Typography>
                  </Stack>
                  <ListPermissions>
                    {permissionsResource.map(item => (
                      <ListItemPermissions key={item}>
                        <Typography variant="smallText">{item}</Typography>
                      </ListItemPermissions>
                    ))}
                  </ListPermissions>
                </Grid>
              );
            })}
        </Grid>
      </ModalNew.Content>
      <ModalNew.Footer
        secondaryButtonProps={{
          children: 'Fechar',
          onClick: onClose,
        }}
      />
    </ModalNew.Container>
  );
}

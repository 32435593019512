import Stack from '@mui/material/Stack';

import { Icon } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';

import {
  DependentProps,
  HolderProps,
} from '@services/BeneficiaryService/dtos/ListBeneficiariesDTO';

import { Label } from './styles';

interface DetailProps {
  data: HolderProps | DependentProps;
}

interface IconStatusProps {
  [key: string]: {
    icon: IconName;
    color: string;
    value: string;
  };
}

const iconStatus: IconStatusProps = {
  Canceled: {
    icon: 'progress-alert',
    color: COLORS.SECONDARY.RED['+1'],
    value: 'Cancelado',
  },
  Active: {
    icon: 'marked-circle-outline',
    color: COLORS.SECONDARY.GREEN['+1'],
    value: 'Ativo',
  },
};

const Detail: React.FC<DetailProps> = ({ data }: DetailProps) => {
  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Icon
          size={20}
          name={iconStatus[data.status]?.icon}
          color={iconStatus[data.status]?.color}
        />
        <Label fontSize="12px" fontWeight={700}>
          {iconStatus[data.status]?.value?.toUpperCase()}
        </Label>
      </Stack>
      <Label fontSize="14px" fontWeight={700}>
        {data?.code}
      </Label>
    </Stack>
  );
};

export default Detail;

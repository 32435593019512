import { useState } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { Stack, Typography } from '@mui/material';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import Icon from '../Icon';

import { TimerContent } from './styles';
import { CircularProgress } from '../CircularProgress';

interface CountDownProps {
  available?: {
    info?: string;
    clickText?: string;
  };
  unavaible?: {
    loadingText?: string;
    loadingInfo?: string;
  };

  timer?: number;
  onClick?: () => void | Promise<void>;
  disabled?: boolean;
  loading?: boolean;
}

const CountDown: React.FC<CountDownProps> = ({
  available = {
    info: '',
    clickText: 'Reenviar agora',
  },
  unavaible = {
    loadingText: 'Aguarde',
    loadingInfo: '',
  },
  disabled,
  timer = 60,
  onClick,
  loading = false,
}: CountDownProps) => {
  const [isClicked, setIsClicked] = useState(true);

  const renderDecimal = (time: number): string => {
    if (time.toString().length > 1) {
      return time.toString();
    } else {
      return `0${time}`;
    }
  };

  const rendererConditional = ({
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      return <CompleteTime />;
    } else {
      return (
        <span>
          {renderDecimal(minutes)}:{renderDecimal(seconds)}
        </span>
      );
    }
  };

  const CompleteTime = () => <>{setIsClicked(true)}</>;

  const handleTimerClick = () => {
    if (disabled) {
      return;
    }

    setIsClicked(false);

    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <Stack direction="row" gap={1}>
          {isClicked ? (
            <>
              <Typography variant="text">{available.info}</Typography>
              <Typography
                variant="link"
                color={
                  disabled
                    ? COLORS.MONOCHROMATIC.GRAY3
                    : COLORS.PRIMARY.BLUE.MAIN
                }
                sx={disabled ? undefined : { cursor: 'pointer' }}
                onClick={handleTimerClick}
              >
                {available.clickText}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="text">{unavaible.loadingText}</Typography>
              <TimerContent>
                <Icon name="timer" size={20} color={COLORS.PRIMARY.BLUE.MAIN} />
                <Typography
                  variant="text"
                  color={COLORS.PRIMARY.BLUE.MAIN}
                  sx={{ fontWeight: 600 }}
                >
                  <Countdown
                    date={Date.now() + timer * 1000}
                    renderer={rendererConditional}
                  >
                    <CompleteTime />
                  </Countdown>
                </Typography>
              </TimerContent>
              <Typography variant="text">{unavaible.loadingInfo}</Typography>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default CountDown;

import React from 'react';

import StartMovimentationLayout from './layout';

type StartMovimentationProps = {
  onStepUpdate(step: number, data: any): void;
};

const StartMovimentationStep: React.FC<StartMovimentationProps> = ({
  onStepUpdate,
}) => {
  return <StartMovimentationLayout onStepUpdate={onStepUpdate} />;
};

export default StartMovimentationStep;

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import {
  useConfirmation,
  Icon,
  COLORS,
} from '@hapvida/hapvida-core-components';

import { CircularProgress } from '@hapvida/hapvida-core-components/src/components/CircularProgress';

import { useExportMovementFile } from './useExportMovementFile';
import { ExportButtonProps, ExportModalFormProps } from './types';
import { ModalContent } from './components';

export function ExportButton({ movementInfo }: ExportButtonProps) {
  const { control, handleSubmit } = useForm<ExportModalFormProps>({
    defaultValues: {
      exportType: 'all_lives',
    },
  });
  const { isExportingMovement, handleExportMovementFile } =
    useExportMovementFile();
  const { showConfirmation } = useConfirmation();

  const { reviewLives, totalLives } = movementInfo.summaryLives ?? {};

  const hasProcessedWithReview = reviewLives > 0 && totalLives !== reviewLives;

  const handleExportConfirmation = useCallback(() => {
    return showConfirmation({
      title: 'Exportar movimentação em formato de planilha.',
      onConfirm: handleSubmit(handleExportMovementFile),
      confirmText: 'Exportar beneficiários',
      cancelText: 'Fechar',
      fullWidth: true,
      type: 'default',
      content: (
        <ModalContent
          hasProcessedWithReview={hasProcessedWithReview}
          reviewLives={reviewLives}
          totalLives={totalLives}
          control={control}
        />
      ),
    });
  }, [
    hasProcessedWithReview,
    showConfirmation,
    handleSubmit,
    handleExportMovementFile,
    control,
    reviewLives,
    totalLives,
  ]);

  return (
    <Button
      onClick={handleExportConfirmation}
      disabled={isExportingMovement}
      variant="outlined"
      fullWidth
      endIcon={
        isExportingMovement ? (
          <CircularProgress
            progressColor={COLORS.MONOCHROMATIC.GRAY4}
            size={16}
          />
        ) : (
          <Icon name="file-check" size={20} />
        )
      }
    >
      Exportar
    </Button>
  );
}

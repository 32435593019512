import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { StepLayout } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { toastifyApiErrors } from '@utils';

import { useQuery } from '../../../../hooks/useQuery';
import { useScreenLoading } from '../../../../hooks/useScreenLoading';
import { COMPANY_REGISTER } from '../../../../constants/sessionStorageKeys';
import SplashScreen from './components/SplashScreen';
import { steps } from './registerSteps';
import {
  REGISTER_STEPS_KEYS,
  REGISTER_STEPS_ORDER,
  REGISTER_STEPS_VALUES,
} from './constants/keys';

function RegisterStepsPage() {
  const loginPageUrl = process.env.REACT_APP_LOGIN_URL || '';

  const { enqueueSnackbar } = useSnackbar();
  const { onScreenLoading } = useScreenLoading();

  const query = useQuery();
  const token = query.get('token');

  const navigateTo = useNavigate();
  const routeParams = useParams<{ registerStep: string }>();

  const [companyId, setCompanyId] = useState('');
  const [updatedData, setUpdatedData] = useState<any>();
  const [showFirstScreen, setShowFirstScreen] = useState(true);
  const [currentStep, setCurrentStep] = useState(
    REGISTER_STEPS_VALUES[
      routeParams.registerStep || REGISTER_STEPS_KEYS.COMPANY_INFO
    ],
  );

  const [stepList, setStepList] = useState([
    {
      label: 'Informações da empresa',
      completed: false,
      blocked: false,
    },
    {
      label: 'Responsável da empresa',
      completed: false,
      blocked: false,
    },
    {
      label: 'Confirmação do cadastro',
      completed: false,
      blocked: false,
    },
  ]);

  const validateToken = () => {
    try {
      if (!token) throw new Error('Token inválido');
    } catch (err) {
      window.location.replace(loginPageUrl);
    }
  };

  useEffect(() => validateToken(), []);

  useEffect(() => {
    if (routeParams.registerStep) {
      setCurrentStep(REGISTER_STEPS_VALUES[routeParams.registerStep]);
    }
  }, [routeParams]);

  const handleStepChange = useCallback(
    (step: number) => {
      if (step === 0) {
        return;
      }

      setCurrentStep(step);
      navigateTo(`/cadastro/${REGISTER_STEPS_ORDER[step]}`);
    },
    [navigateTo],
  );

  const handleStepUpdate = useCallback(
    async (step: number, data: any) => {
      try {
        const updatedStepList: any = [...stepList];
        const sessionStorageData = JSON.parse(
          sessionStorage.getItem(COMPANY_REGISTER) ?? '{}',
        );
        updatedStepList[step] = {
          ...stepList[step],
          completed: true,
          blocked: true,
        };

        onScreenLoading(true);

        onScreenLoading(false);

        setUpdatedData({ ...updatedData, ...data });

        sessionStorage.setItem(
          COMPANY_REGISTER,
          JSON.stringify({ ...sessionStorageData, ...updatedData, ...data }),
        );

        setStepList(updatedStepList);
        setCurrentStep(step + 1);
        navigateTo(
          `/cadastro/${REGISTER_STEPS_ORDER[step + 1]}?token=${token}`,
        );
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      } finally {
        onScreenLoading(false);
      }
    },
    [navigateTo, stepList, updatedData, enqueueSnackbar],
  );

  const handleStartRegister = useCallback(
    async accessType => {
      try {
        setUpdatedData({ ...updatedData, accessType });
        setCurrentStep(REGISTER_STEPS_VALUES[REGISTER_STEPS_KEYS.COMPANY_INFO]);
        navigateTo(`/cadastro/${REGISTER_STEPS_KEYS.COMPANY_INFO}`);
      } catch (error) {
        /* empty */
      }
    },
    [navigateTo, updatedData],
  );

  useEffect(() => {
    if (currentStep === 1) {
      const updatedStepList: any = [...stepList];

      updatedStepList[0] = {
        ...stepList[0],
        completed: true,
        blocked: true,
      };

      setStepList(updatedStepList);
    }
  }, []);

  const hideFirstScreen = () => {
    setShowFirstScreen(false);
  };

  return (
    <>
      {showFirstScreen &&
      currentStep ===
        REGISTER_STEPS_VALUES[REGISTER_STEPS_KEYS.COMPANY_INFO] ? (
        <SplashScreen
          title="Comece agora a administrar os beneficiários da sua empresa."
          subtitle="Finalize o cadastro da sua empresa em nosso portal"
          onDone={hideFirstScreen}
        />
      ) : (
        <StepLayout
          steps={stepList}
          hasHeader={false}
          hasFirstStep={false}
          currentStep={currentStep}
          onStepChange={(step: number) => handleStepChange(step)}
        >
          <Stack
            gap={1}
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Typography variant="text">Já tem cadastro?</Typography>
            <a href={process.env.REACT_APP_LOGIN_URL}>
              <Typography variant="link" color={COLORS.PRIMARY.BLUE.MAIN}>
                Efetue o login
              </Typography>
            </a>
          </Stack>
          {steps[currentStep].step({
            onStepUpdate: handleStepUpdate,
            registerData: updatedData,
            onStartRegister: handleStartRegister,
            companyId,
            setCompanyId,
          })}
        </StepLayout>
      )}
    </>
  );
}

export default RegisterStepsPage;

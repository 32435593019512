export const COLORS = {
  PRIMARY: {
    BLUE: {
      MAIN: '#0054B8',
      '+1': '#003D85',
      '-1': '#2568B8',
      '-2': '#4A7CB8',
      '-3': '#00A1CF',
    },
    GREEN: {
      MAIN: '',
      '+1': '#52872C',
    },
    YELLOW: {
      MAIN: '',
    },
    ORANGE: {
      MAIN: '#F49E00',
      '+1': '#F47920',
      '-1': '#F1903B',
      '-2': '#F9D0AC',
    },
    RED: {
      MAIN: '',
      '+1': '#962800',
    },
  },
  SECONDARY: {
    BLUE: {
      MAIN: '#00A1CF',
      '+1': '#0E82A3',
      '-1': '#1976D214',
    },
    GREEN: {
      MAIN: '#4BA907',
      LIGHT: '#2E7D32',
      '+1': '#52872C',
      '-1': '#2E7D321F',
    },
    YELLOW: {
      MAIN: '#FFE200',
      '+1': '#CCB500',
    },
    ORANGE: {
      MAIN: '',
    },
    RED: {
      MAIN: '#C93600',
      '+1': '#962800',
      '-1': '#C95328',
      '-2': '#96280014',
      '-3': '#D32F2F0A',
    },
  },

  BLUE: {
    DARK: '#3A3A54',
  },

  ERROR: {
    MAIN: '#D32F2F',
    LIGHT: '#D32F2F1F',
  },

  MONOCHROMATIC: {
    GRAY1: '#F8F9FA',
    GRAY2: '#DDE2E5',
    GRAY3: '#ACB5BD',
    GRAY4: '#495057',
    GRAY5: '#21212114',
  },
  WHITE: '#fff',
  BLACK: '#000',
  LIGHT: {
    MAIN: '#FEFEFE',
  },
  DARK: {
    MAIN: '#212429',
  },
  FONT_BLACK: '#3C3C3C',
  DISABLED: {
    BACKGROUND: '#F8F9FA',
    BORDER: '#DDE2E5',
  },
};

export const CLASSIC_THEME = {
  CARD_BORDERS: COLORS.MONOCHROMATIC.GRAY3,
  LINE_DIVIDER: COLORS.MONOCHROMATIC.GRAY3,
};

export const DARK_THEME = {
  CARD_BORDERS: COLORS.MONOCHROMATIC.GRAY4,
  LINE_DIVIDER: COLORS.MONOCHROMATIC.GRAY3,
};

import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { useCallback } from 'react';

import {
  ICoreRoute,
  Sidebar as SidebarCore,
} from '@hapvida/hapvida-core-components';

import { IAppSearchRoute } from '@hapvida/hapvida-core-components/src/components/layouts/DrawerLayout';

import { useRoutes } from '../../hooks';

interface SidebarRouteButtonProps {
  route: ICoreRoute & Partial<IAppSearchRoute>;
}

export function SidebarRouteButton({ route }: SidebarRouteButtonProps) {
  const { handleRouteDisabled } = useRoutes();
  const navigate = useNavigate();

  const resolved = useResolvedPath({ pathname: route.path });
  const match = useMatch({ path: resolved.pathname, end: false });

  const isDisabled = handleRouteDisabled(route);

  const currentRoute = window.location.pathname;

  const onClickItem = useCallback(() => {
    if (match?.pathname === currentRoute) {
      window.location.reload();
    } else if (!isDisabled) {
      navigate(route.searchPath ? route.searchPath() : route.path);
    }
  }, [currentRoute, isDisabled, match, navigate, route]);

  return (
    <SidebarCore.Item
      icon={route.Icon.props.name}
      isSelected={Boolean(match)}
      disabled={isDisabled}
      onClick={onClickItem}
      label={route.name}
      key={route.path}
    />
  );
}

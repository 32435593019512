import {
  DrawerFilterProvider,
  useSidebar,
  Header,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { MovementsSubHeader } from './components/MovementsSubHeader';
import { MovementsStateProvider } from './hooks';
import { MovementListRouter } from './router';
import { MovementListLayout } from './layout';

export function MovementListPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <MovementsStateProvider>
        <Header
          subHeader={<MovementsSubHeader />}
          toggleDrawer={toggleDrawer}
          drawerOpen={drawerOpen}
          onLogOut={logOut}
          user={user}
          route={{
            icon: MovementListRouter.Icon,
            name: MovementListRouter.name,
          }}
        >
          <MovementListLayout />
        </Header>
      </MovementsStateProvider>
    </DrawerFilterProvider>
  );
}

import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { UserListProps, UserService } from '@services/UserService';

import { BackofficeUserQueryKeysEnum } from 'constants/private/backofficeUsers';
import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';

export function useBackofficeUserState() {
  const { currentPage, tableOrder } = useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const userService = useMemo(() => new UserService(), []);

  const backofficeCompanyId = user?.companyId ?? '';

  const backofficeUserState = useQuery<UserListProps>({
    queryKey: [
      BackofficeUserQueryKeysEnum.USERS_STATE,
      backofficeCompanyId,
      currentPage,
      tableOrder,
    ],
    queryFn: () =>
      userService.getAccounts({
        ...tableOrder,
        companyId: backofficeCompanyId,
        offset: 9 * (currentPage - 1),
        limit: 9,
      }),
    enabled: Boolean(backofficeCompanyId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshBackofficeUserList = () => {
    backofficeUserState.remove();
    backofficeUserState.refetch();
  };

  return {
    refreshBackofficeUserList,
    backofficeUserState,
  };
}

import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { useConfirmation } from '@hapvida/hapvida-core-components';

import MovimentationService from '@services/MovimentationService';

import { toastifyApiErrors } from '@utils';
import { useScreenLoading } from '@hooks';
import { useAuth } from 'hooks/useAuth';

import { ExportModalFormProps } from './types';

export function useExportMovementFile() {
  const { onScreenLoading } = useScreenLoading();
  const { showConfirmation } = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const { movementId = '' } = useParams();
  const queryClient = useQueryClient();
  const { selectedCompany } = useAuth();

  const isFetchingMovementFile = useIsFetching({
    queryKey: ['movementFileState'],
  });
  const movementService = useMemo(() => new MovimentationService(), []);

  const companyId = selectedCompany?.id ?? '';

  const getMovementFileId = async (check: boolean) => {
    try {
      onScreenLoading(true);
      if (companyId && movementId) {
        const response = await movementService.getMovimentationFileId({
          companyId,
          movementId,
          review: check ? true : undefined,
        });

        return response;
      }
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      onScreenLoading(false);
    }
    return undefined;
  };

  const handleDownloadMovementFile = async (movementFileId: string) => {
    try {
      const movementFileResponse = await queryClient.fetchQuery({
        queryKey: ['movementFileState', movementFileId, companyId, movementId],
        queryFn: () =>
          movementService.getExportedMovementFile({
            fileNameIdentifier: movementFileId,
            movementId,
            companyId,
          }),
        retry: 5,
        retryDelay: 2000,
      });

      window.open(movementFileResponse.content, '_blank');
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    }
  };

  const handleExportMovementFile = async ({
    exportType,
  }: ExportModalFormProps) => {
    const fileId = await getMovementFileId(exportType === 'only_review_lives');

    if (!fileId) return;

    showConfirmation({
      title: 'Parabéns! Sua movimentação foi exportada com sucesso.',
      confirmText: 'Voltar para movimentação',
      fullWidth: true,
      type: 'success',
      content: (
        <Typography variant="body2">
          Em alguns instantes, o arquivo <b>.csv</b> com todos os beneficiários
          será baixado.
        </Typography>
      ),
    });

    handleDownloadMovementFile(fileId);
  };

  return {
    isExportingMovement: isFetchingMovementFile > 0,
    handleExportMovementFile,
  };
}

import { IAppDynamicRoute } from 'routes';

import PasswordDefinitionStepsPage from '.';

const PasswordDefinitionRouter: IAppDynamicRoute = {
  Page: PasswordDefinitionStepsPage,
  path: '/definicao-de-senha/:passwordDefinitionStep',
  name: 'Definição de senha',
  dynamicPath: ({ passwordDefinitionStep }) =>
    `/definicao-de-senha/${passwordDefinitionStep}`,
  Icon: <></>,
  userTypes: [],
  disabled: false,
};

export default PasswordDefinitionRouter;

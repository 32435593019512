import { TableHeadParams } from '@hapvida/hapvida-core-components/src/components/BaseTable';

export enum UserListColumnNameEnum {
  PERSONAL_DATA = 'personalData',
  PROFILE = 'profile',
  EMAIL = 'email',
  STATUS = 'status',
}

export const userListTableHeadColumns: TableHeadParams[] = [
  {
    id: 1,
    name: UserListColumnNameEnum.PERSONAL_DATA,
    label: 'Nome e CPF',
    sortable: false,
  },
  // TODO: código comentado para subida prévia de homologação. Será corrigido/validado na US 269725
  // {
  //   id: 2,
  //   name: UserListColumnNameEnum.PROFILE,
  //   label: 'Perfil de acesso',
  //   sortable: false,
  // },
  {
    id: 3,
    name: UserListColumnNameEnum.EMAIL,
    label: 'E-mail',
    sortable: false,
  },
  {
    id: 4,
    name: UserListColumnNameEnum.STATUS,
    label: 'Status',
    sortable: false,
  },
];

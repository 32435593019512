import { ProfileProps } from '@services/UserService';

import { ProfileResourceEnum } from '@constants';

export const profilesCards = [
  {
    id: 1,
    profiles: [
      { name: 'Master', id: '1' },
      { name: 'Administrador', id: '2' },
      { name: 'Movimentação', id: '3' },
      { name: 'Financeiro', id: '4' },
    ],
    contracts: [
      { partnerCompany: '00213', hasAccess: true, profile: {} },
      { partnerCompany: '05HAP', hasAccess: true, profile: {} },
      { partnerCompany: '12373', hasAccess: true, profile: {} },
      { partnerCompany: '0385H', hasAccess: true, profile: {} },
      { partnerCompany: '09AT4', hasAccess: true, profile: {} },
      { partnerCompany: '0B4F4', hasAccess: true, profile: {} },
      {
        partnerCompany: '0B6MX',
        hasAccess: true,
        profile: { name: 'Financeiro', id: 4 },
      },
      { partnerCompany: 'B2327', hasAccess: true, profile: {} },
    ],
  },
];

export const avalibleProfilesMock = [
  {
    name: 'Master',
    id: '1',
    resources: {
      [ProfileResourceEnum.MOVEMENT]: true,

      [ProfileResourceEnum.BENEFICIARIES]: true,

      [ProfileResourceEnum.FINANCIAL]: true,

      [ProfileResourceEnum.USERS]: true,

      [ProfileResourceEnum.COMPANIES]: true,

      [ProfileResourceEnum.MORE_SERVICES]: true,
    },
  },
  {
    name: 'Administrador',
    id: '2',
    resources: {
      [ProfileResourceEnum.MOVEMENT]: true,

      [ProfileResourceEnum.BENEFICIARIES]: true,

      [ProfileResourceEnum.FINANCIAL]: true,

      [ProfileResourceEnum.USERS]: true,

      [ProfileResourceEnum.COMPANIES]: true,

      [ProfileResourceEnum.MORE_SERVICES]: true,
    },
  },
  {
    name: 'Movimentação',
    id: '3',
    resources: {
      [ProfileResourceEnum.MOVEMENT]: true,

      [ProfileResourceEnum.BENEFICIARIES]: true,

      [ProfileResourceEnum.FINANCIAL]: false,

      [ProfileResourceEnum.USERS]: false,

      [ProfileResourceEnum.COMPANIES]: false,

      [ProfileResourceEnum.MORE_SERVICES]: false,
    },
  },
  {
    name: 'Financeiro',
    id: '4',
    resources: {
      [ProfileResourceEnum.MOVEMENT]: false,

      [ProfileResourceEnum.BENEFICIARIES]: false,

      [ProfileResourceEnum.FINANCIAL]: true,

      [ProfileResourceEnum.USERS]: false,

      [ProfileResourceEnum.COMPANIES]: false,

      [ProfileResourceEnum.MORE_SERVICES]: false,
    },
  },
];

export const initialProfiles: ProfileProps[] = [
  {
    name: 'Master',
    id: '1',
    resources: {
      [ProfileResourceEnum.MOVEMENT]: true,

      [ProfileResourceEnum.BENEFICIARIES]: true,

      [ProfileResourceEnum.FINANCIAL]: true,

      [ProfileResourceEnum.USERS]: true,

      [ProfileResourceEnum.COMPANIES]: true,

      [ProfileResourceEnum.MORE_SERVICES]: true,
    },
  },
  {
    name: 'Financeiro',
    id: '4',
    resources: {
      [ProfileResourceEnum.FINANCIAL]: true,
      [ProfileResourceEnum.MOVEMENT]: false,

      [ProfileResourceEnum.BENEFICIARIES]: false,

      [ProfileResourceEnum.USERS]: false,

      [ProfileResourceEnum.COMPANIES]: false,

      [ProfileResourceEnum.MORE_SERVICES]: false,
    },
  },
];

export const currentPartnerCompaniesMock = [
  { partnerCompany: '00213', hasAccess: false },
  { partnerCompany: '05HAP', hasAccess: false },
  { partnerCompany: '12373', hasAccess: false },
  {
    partnerCompany: '0385H',
    hasAccess: true,
    profile: { name: 'Administrador', id: '2' },
  },
  {
    partnerCompany: '09AT4',
    hasAccess: true,
    profile: { name: 'Movimentação', id: '3' },
  },
  {
    partnerCompany: '0B4F4',
    hasAccess: true,
    profile: { name: 'Financeiro', id: '4' },
  },
  {
    partnerCompany: '0B6MX',
    hasAccess: true,
    profile: { name: 'Financeiro', id: '4' },
  },
  { partnerCompany: 'B2327', hasAccess: false },
];

import { Typography } from '@mui/material';

import { Mask, Company } from '@hapvida/hapvida-core-components';

import { BeneficiaryMovementProps } from '@services/BeneficiaryService';

import SimpleText from '@flows/private/beneficiaries/components/SimpleText';
import { MovementActionType } from '@components';
import Plan from '@flows/private/beneficiaries/components/Plan';

import { BeneficiaryMovementListTableColumnNameEnum } from '../constants/beneficiaryMomentListTable';

export const getBeneficiaryMovementListTableRows = (
  movements: BeneficiaryMovementProps[],
) => {
  return movements.map(movement => ({
    id: movement.id,
    main: {
      [BeneficiaryMovementListTableColumnNameEnum.NUMBER]: (
        <SimpleText text={movement?.number} />
      ),
      [BeneficiaryMovementListTableColumnNameEnum.OPERATION_CODE_TYPE]: (
        <>
          <MovementActionType
            operationCode={movement?.operationCode}
            movementType={movement?.operationCodeType}
          />
          <Typography fontSize={12} pt="4px">
            Controle: <strong>{movement?.controlCode ?? '-'}</strong>
          </Typography>
        </>
      ),
      [BeneficiaryMovementListTableColumnNameEnum.HEALTH_OPERATOR]: (
        <Company company={movement?.healthOperator} />
      ),
      [BeneficiaryMovementListTableColumnNameEnum.CREATED_AT]: (
        <SimpleText text={Mask.date(movement?.createdAt)} />
      ),
      [BeneficiaryMovementListTableColumnNameEnum.HEALTH_PLAN_CODE]: (
        <Plan code={movement?.healthPlanCode} />
      ),
      [BeneficiaryMovementListTableColumnNameEnum.NEW_CONTRACT_NUMBER]: (
        <SimpleText text={movement?.newContractNumber} />
      ),
      [BeneficiaryMovementListTableColumnNameEnum.UNIT_CODE]: (
        <SimpleText text={movement?.unitCode} />
      ),
    },
    dependents: [],
  }));
};

import { Stack, Typography } from '@mui/material';

import {
  FilterKeyEnum,
  DrawerFilter,
  Icon,
} from '@hapvida/hapvida-core-components';

import {
  BENEFICIARY_STATUS_ICON_NAME,
  BENEFICIARY_STATUS_COLOR,
  BENEFICIARY_STATUS_NAME,
  BeneficiaryStatusEnum,
} from '@constants';

export function BeneficiaryStatusDrawerFilter() {
  const beneficiaryStatusFilters = Object.values(BeneficiaryStatusEnum).map(
    beneficiaryStatus => {
      const content = (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Icon
            name={BENEFICIARY_STATUS_ICON_NAME[beneficiaryStatus]}
            color={BENEFICIARY_STATUS_COLOR[beneficiaryStatus]}
            size={16}
          />
          <Typography fontSize={14} fontWeight={700}>
            {BENEFICIARY_STATUS_NAME[beneficiaryStatus].toUpperCase()}
          </Typography>
        </Stack>
      );

      return {
        label: BENEFICIARY_STATUS_NAME[beneficiaryStatus],
        value: beneficiaryStatus,
        content,
      };
    },
  );

  return (
    <DrawerFilter
      filterKey={FilterKeyEnum.BENEFICIARIES_STATUS}
      filters={beneficiaryStatusFilters}
      title="Status do beneficiário"
      label="status"
    />
  );
}

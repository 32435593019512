import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import {
  Button,
  DragAndDrop,
  Icon,
  StepCard,
} from '@hapvida/hapvida-core-components';

import IDragAndDropFile from '@hapvida/hapvida-core-components/src/components/DragAndDrop/dtos/IDragDropFile';

import { usePrompt } from '@hooks';

import {
  MOVIMENTATION_STEPS_KEYS,
  MOVIMENTATION_STEPS_VALUES,
} from '../../constants/keys';
import info from './assets/info.svg';
import download from './assets/download.svg';
import spreadsheet from './assets/spreadsheet.svg';
import { Layout, UploadDescription } from './styles';

type MovimentationFileLayoutProps = {
  onStepUpdate(step: number, data: any): void;
  movimentationFile?: IDragAndDropFile;
};

const MovimentationFileLayout: React.FC<MovimentationFileLayoutProps> = ({
  onStepUpdate,
  movimentationFile,
}) => {
  const movimentationFileModel = `${process.env.REACT_APP_CDN_BASE_URL}/guia_modelo_movimentacao.zip`;

  const [file, setFile] = useState<IDragAndDropFile>();

  usePrompt();

  useEffect(() => {
    if (movimentationFile) setFile(movimentationFile);
  }, []);

  const onSubmit = (fileDragDrop: IDragAndDropFile) => {
    onStepUpdate(
      MOVIMENTATION_STEPS_VALUES[MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE],
      { file: fileDragDrop },
    );
  };

  const onDelete = async () => {
    setFile(undefined);
  };

  return (
    <Layout>
      <Typography variant="h5">Arquivo de movimentação</Typography>
      <DragAndDrop
        showHeader
        model={movimentationFileModel}
        acceptedTypes={['csv']}
        maxSize={1024 ** 2 * 8}
        onDelete={onDelete}
        onChange={setFile}
        value={file}
      >
        <UploadDescription>
          <Typography variant="text">
            Baixe o arquivo modelo, crie o seu arquivo e solicite sua
            movimentação
          </Typography>
          <StepCard
            steps={[
              {
                id: 1,
                img: download,
                content: (
                  <Typography variant="smallText">
                    Baixe o arquivo, verifique os tipos de operação, e crie seu
                    arquivo de movimentação.
                  </Typography>
                ),
                alt: 'Download do arquivo',
              },
              {
                id: 2,
                img: spreadsheet,
                content: (
                  <Typography variant="smallText">
                    Suba sua planilha seguindo as diretrizes de formato e
                    tamanho do arquivo.
                  </Typography>
                ),
                alt: 'Planilha de exemplo',
              },
              {
                id: 3,
                img: info,
                content: (
                  <Typography variant="smallText">
                    Caso algo esteja errado, vamos te avisar no próximo passo.
                  </Typography>
                ),
                alt: 'Próximo passo',
              },
            ]}
          />
          <Typography
            variant="subtitle1"
            sx={{ textAlign: { xs: 'center', sm: 'start' } }}
          >
            Tudo pronto? Vamos começar.
          </Typography>
        </UploadDescription>
      </DragAndDrop>
      <Button
        disabled={!file}
        onClick={() => file && onSubmit(file)}
        variant="contained"
        type="submit"
        color="success"
        endIcon={<Icon name="chevron-right" />}
      >
        Próxima etapa
      </Button>
    </Layout>
  );
};

export default MovimentationFileLayout;

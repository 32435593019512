import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from '@contexts';
import { IAppRoute } from '@routes';

import { MovementListPage } from './index';

export const MovementListRouter: IAppRoute = {
  Page: MovementListPage,
  path: '/movimentacoes',
  name: 'Movimentações',
  isPrivate: true,
  Icon: <Icon name="clipboard-text-outline" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabledOnRoute: '/empresas',
};

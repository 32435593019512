import { BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

export const getBeneficiaryProfessionalInformationRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  const { professionalInformation } = beneficiary;
  return [
    {
      label: 'Número da matrícula/ Identificador Familiar do Beneficiário',
      value: professionalInformation?.registrationNumber,
      xs: 4,
    },
    {
      label: 'Sequencial matrícula/ Sequencial Familiar',
      value: professionalInformation?.sequentialRegistrationNumber,
      xs: 4,
    },
    {
      label: 'Data de Admissão Beneficiário titular',
      value: professionalInformation?.holder?.admissionAt,
      xs: 4,
    },
    {
      label: 'Data Inicio Lotação Beneficiário Titular',
      value: professionalInformation?.holder?.startAt,
      xs: 4,
    },
    {
      label: 'Código da Lotação Beneficiário Titular',
      value: professionalInformation?.lotation?.code,
      xs: 4,
    },
    {
      label: 'Nome da Lotação Beneficiário Titular',
      value: professionalInformation?.lotation?.name,
      xs: 4,
    },
    {
      label: 'Tipo Cargo Beneficiário Titular',
      value: professionalInformation?.position?.typeCode,
      xs: 4,
    },
    {
      label: 'Descrição Tipo Cargo Beneficiário Titular',
      value: professionalInformation?.position?.typeName,
      xs: 4,
    },
    {
      label: 'Código Cargo Beneficiário Titular',
      value: professionalInformation?.position?.code,
      xs: 4,
    },
    {
      label: 'Nome Cargo Beneficiário Titular',
      value: professionalInformation?.position?.name,
      xs: 4,
    },
    {
      label: 'Código do Centro de Custo Beneficiário Titular',
      value: professionalInformation?.costCenter?.code,
      xs: 4,
    },
    {
      label: 'Nome do centro de custo Beneficiário Titular',
      value: professionalInformation?.costCenter?.name,
      xs: 4,
    },
  ];
};

import {
  DrawerFilterProvider,
  Header,
  useSidebar,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { MovementListRouter } from '../MovementList/router';
import { useMovementDetailsState } from './hooks';
import { MovementDetailsLayout } from './layout';
import MovimentationDetailRouter from './router';

export function MovementDetailsPage() {
  const { movementInfoState } = useMovementDetailsState();
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <Header
        subRoute={MovimentationDetailRouter.name}
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        onLogOut={logOut}
        user={user}
        route={{
          name: `Cód. ${
            movementInfoState.data?.content.movementDetails.number ?? '-'
          }`,
          navigateBackPath: MovementListRouter.path,
          icon: MovimentationDetailRouter.Icon,
        }}
      >
        <MovementDetailsLayout />
      </Header>
    </DrawerFilterProvider>
  );
}

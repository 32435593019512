import {
  DrawerFilterProvider,
  useSidebar,
  Header,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { BeneficiariesSubHeader } from './components';
import { BeneficiariesStateProvider } from './hooks';
import { BeneficiaryListRouter } from './router';
import { BeneficiaryListLayout } from './layout';

export function BeneficiaryListPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <BeneficiariesStateProvider>
        <Header
          subHeader={<BeneficiariesSubHeader />}
          toggleDrawer={toggleDrawer}
          drawerOpen={drawerOpen}
          onLogOut={logOut}
          user={user}
          route={{
            icon: BeneficiaryListRouter.Icon,
            name: BeneficiaryListRouter.name,
          }}
        >
          <BeneficiaryListLayout />
        </Header>
      </BeneficiariesStateProvider>
    </DrawerFilterProvider>
  );
}

import { Grid } from '@mui/material';
import { CSSProperties, ReactNode, useCallback } from 'react';
import { UserType } from '../../layouts/DrawerLayout';
import { LineColumn } from '../types';
import ColumnLineItem from './components/ColumnLineItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TableOrderParams } from '../../../constants';

interface LineViewListProps<T> {
  data?: Array<T>;
  columns: {
    [x: string]: LineColumn;
  };
  order?: TableOrderParams;
  hasNextPage: boolean;
  isNextPageLoading?: boolean;
  itemHeight?: number;
  loadNextPage: () => Promise<unknown>;
  onOrderChange?: (params: TableOrderParams) => void;
  renderRow: (data: T, key: number) => ReactNode;
  listContainerSize?: number;
  currentUserType?: UserType;
  listContainerStyle?: CSSProperties;
}

export function LineViewList<T>({
  data = [],
  columns,
  order,
  hasNextPage,
  loadNextPage,
  onOrderChange,
  renderRow,
  listContainerStyle,
}: Readonly<LineViewListProps<T>>) {
  const renderColumns = useCallback(() => {
    const keys = Object.keys(columns);

    return keys.map(key => (
      <ColumnLineItem
        onOrderChange={onOrderChange}
        item={columns[key]}
        columnKey={key}
        order={order}
        key={key}
      />
    ));
  }, [columns, onOrderChange, order]);

  return (
    <Grid container rowGap={2}>
      <Grid container item xs={12} pr={2}>
        {renderColumns()}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          overflowY: 'auto',
          paddingRight: 16,
          maxHeight: 600,
          ...listContainerStyle,
        }}
        id="scrollableDivMain"
      >
        <InfiniteScroll
          scrollableTarget="scrollableDivMain"
          style={{ gap: 24, display: 'flex', flexDirection: 'column' }}
          dataLength={data.length}
          hasMore={hasNextPage}
          next={loadNextPage}
          loader={<></>}
        >
          {data.map((item, index) => renderRow(item, index))}
        </InfiniteScroll>
      </Grid>
    </Grid>
  );
}

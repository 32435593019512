import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  File,
  Button,
  Icon,
  CollapsableCompany,
  CompanyDetails,
  useConfirmation,
  Mask,
} from '@hapvida/hapvida-core-components';

import IDragAndDropFile from '@hapvida/hapvida-core-components/src/components/DragAndDrop/dtos/IDragDropFile';

import CompanyService from '@services/CompanyService';

import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';
import { useScreenLoading } from 'hooks/useScreenLoading';
import { toastifyApiErrors } from '@utils';
import { usePrompt } from '@hooks';

import { MOVIMENTATION_STEPS_KEYS } from '../../constants/keys';
import MovimentationStepRouter from '../../router';
import { Layout } from './styles';
import EditableCard from './components/EditableCard';

type ConfirmationLayoutProps = {
  onStepSubmit(): void;
  movimentationFile: IDragAndDropFile;
  companyId: string;
};

type ICompany = Partial<CompanyDetails>;

const ConfirmationLayout: React.FC<ConfirmationLayoutProps> = ({
  companyId,
  onStepSubmit,
  movimentationFile,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { showConfirmation } = useConfirmation();
  const { onScreenLoading } = useScreenLoading();

  const [companyDetail, setCompanyDetail] = useState<ICompany>();
  const [openedCard, setOpenedCard] = useState('');
  const [file, setFile] = useState<IDragAndDropFile>();

  const [disabledButton, setDisabledButton] = useState(true);

  const [dataSummaryState, setDataSummaryState] = useState<{
    [key: string]: number;
  }>({
    lives: 0,
    holders: 0,
    dependents: 0,
    aggregates: 0,
  });

  useEffect(() => {
    if (movimentationFile) {
      setFile(movimentationFile);
    }
  }, []);

  usePrompt();

  const onSubmit = useCallback(async () => {
    await Promise.resolve(onStepSubmit());
  }, [onStepSubmit]);

  const getSummaryCompany = async () => {
    try {
      if (companyId) {
        const companyService = new CompanyService();
        const response = await companyService.fetchCompanyBeneficiaryNumbers({
          companyId,
        });
        setDataSummaryState(prev => ({ ...prev, ...response }));
      }
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    }
  };

  const getCompanyDetail = async () => {
    onScreenLoading(true);

    try {
      const companyService = new CompanyService();
      const { company } = await companyService.fetchCompanyDetail({
        id: companyId,
      });

      const formattedCompany: ICompany = {
        state: company?.address?.stateCode,
        corporateName: company.name?.legal,
        fantasyName: company.name?.trade,
        city: company?.address?.city,
        document: company.document,
      };

      setCompanyDetail(formattedCompany);
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      onScreenLoading(false);
    }
  };

  useEffect(() => {
    if (file) {
      setDisabledButton(false);
    }
  }, [file]);

  const showConfirm = () => {
    showConfirmation({
      title: 'Você está prestes a processar sua movimentação',
      icon: 'alert',
      type: 'default',
      confirmText: 'Enviar movimentação',
      cancelText: 'Fechar',
      onConfirm: () => onSubmit(),
      content: (
        <Typography variant="text">
          Ao confirmar você estará enviando todas as informações para
          processamento. O processamento pode levar alguns minutos.
          <br />
          <br />
          Vamos te avisar quando tudo estiver pronto.
        </Typography>
      ),
    });
  };

  const numberFormatter = (input: number) =>
    Intl.NumberFormat('pt-BR').format(input);

  useEffect(() => {
    getSummaryCompany();
    getCompanyDetail();
  }, []);

  useEffect(() => {
    if (movimentationFile === undefined) {
      navigate(MovementListRouter.path);
    }
  }, [movimentationFile]);

  return (
    <Layout>
      <Typography variant="h5">Confirmação da solicitação</Typography>
      <EditableCard title="Empresas a serem movimentadas">
        {companyDetail && (
          <CollapsableCompany
            key={companyDetail.document}
            matrix
            company={
              { ...dataSummaryState, ...companyDetail } as CompanyDetails
            }
            fields={[
              {
                label: 'CNPJ',
                key: 'document',
                formatter: (value: string) => Mask.cnpj(value),
              },
              { label: 'Razão Social', key: 'corporateName' },
              { label: 'Nome fantasia', key: 'fantasyName' },
              { label: 'UF', key: 'state' },
              { label: 'Cidade', key: 'city' },
              {
                label: 'Qtd. Beneficiários',
                key: 'lives',
                formatter: numberFormatter,
              },
              {
                label: 'Qtd. Titulares',
                key: 'holders',
                formatter: numberFormatter,
              },
              {
                label: 'Qtd. Dependentes',
                key: 'dependents',
                formatter: numberFormatter,
              },
              {
                label: 'Qtd. Agregados',
                key: 'aggregates',
                formatter: numberFormatter,
              },
            ]}
            openedCard={openedCard}
            setOpenedCard={setOpenedCard}
          />
        )}
      </EditableCard>
      <EditableCard
        title="Arquivo de movimentação"
        onEdit={() =>
          navigate(
            MovimentationStepRouter.dynamicPath({
              movimentationStep: MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE,
            }),
          )
        }
      >
        {file && (
          <File
            date={file.date}
            size={file.size}
            lines={file.lines}
            filename={file.fileName}
            positionTooltip="top"
            maxLabelLength={40}
          />
        )}
      </EditableCard>

      <Button
        type="submit"
        color="success"
        variant="contained"
        onClick={showConfirm}
        disabled={disabledButton}
        endIcon={<Icon name="chevron-right" />}
        sx={{ alignSelf: 'flex-end' }}
      >
        Processar movimentação
      </Button>
    </Layout>
  );
};

export default ConfirmationLayout;

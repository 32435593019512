import { StackProps, Typography } from '@mui/material';

import Icon, { IconName } from '../../../Icon';
import { useSidebar } from '../../hooks';
import { RouteItem } from './styles';

interface SidebarItemProps extends StackProps {
  isSelected?: boolean;
  disabled?: boolean;
  icon?: IconName;
  label: string;
}

export function SidebarItem({
  isSelected = false,
  disabled = false,
  label,
  icon,
  ...stackProps
}: Readonly<SidebarItemProps>) {
  const { drawerOpen } = useSidebar();

  return (
    <RouteItem
      justifyContent={drawerOpen ? 'flex-start' : 'center'}
      direction={drawerOpen ? 'row' : 'column'}
      selected={isSelected}
      disabled={disabled}
      {...stackProps}
    >
      {icon && <Icon name={icon} size={24} />}

      {drawerOpen && <Typography variant="body2">{label}</Typography>}
    </RouteItem>
  );
}

import { IAppRoute } from 'routes';

import { SessionExpired } from './index';

export const SessionExpiredRouter: IAppRoute = {
  Page: SessionExpired,
  path: '/sessao-expirada',
  name: 'Sessão expirada',
  Icon: <></>,
  userTypes: [],
  disabled: false,
};

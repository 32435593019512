import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from '@contexts';
import { IAppRoute } from '@routes';

import { ServiceListPage } from './index';

export const ServiceListRouter: IAppRoute = {
  Page: ServiceListPage,
  path: '/servicos',
  name: 'Mais serviços',
  isPrivate: true,
  Icon: <Icon name="table-view" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabledOnRoute: '/empresas',
};

import Stack from '@mui/material/Stack';

import { Mask } from '@hapvida/hapvida-core-components';

import { User } from '@services/BeneficiaryService/dtos/ListBeneficiariesDTO';

import { Label } from './styles';

interface PersonProps {
  data: User;
}

export const Genders: {
  [x: string]: string;
} = {
  M: 'MASCULINO',
  F: 'FEMININO',
};

function Person({ data }: PersonProps) {
  return (
    <Stack>
      <Label fontSize="12px" fontWeight={400}>
        {Genders[data?.gender?.toUpperCase()] ?? '-'}
      </Label>
      <Label fontSize="14px" fontWeight={700}>
        {data?.name}
      </Label>
      {data?.document?.length === 11 ? (
        <Label fontSize="12px" fontWeight={400}>
          {Mask.cpf(data?.document) ?? '-'}
        </Label>
      ) : (
        <Label fontSize="12px" fontWeight={400}>
          {Mask.cpfNDITenDigits(data?.document) ?? '-'}
        </Label>
      )}
    </Stack>
  );
}

export default Person;

import {
  DrawerFilterProvider,
  useSidebar,
  Header,
} from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { FinancialListSubHeader } from './components';
import { FinancialListLayout } from './layout';
import { BillsStateProvider } from './hooks';
import Router from './router';

const BillList = () => {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <DrawerFilterProvider>
      <BillsStateProvider>
        <Header
          subHeader={<FinancialListSubHeader />}
          toggleDrawer={toggleDrawer}
          drawerOpen={drawerOpen}
          onLogOut={logOut}
          user={user}
          route={{
            icon: Router.Icon,
            name: Router.name,
          }}
        >
          <FinancialListLayout />
        </Header>
      </BillsStateProvider>
    </DrawerFilterProvider>
  );
};

export default BillList;

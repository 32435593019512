import Typography from '@mui/material/Typography';
import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const InfoText = muiStyled(Typography)({
  fontWeight: 700,
  fontSize: '14px',
  color: COLORS.MONOCHROMATIC.GRAY4,
});

export const InfoTextContact = muiStyled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  color: COLORS.MONOCHROMATIC.GRAY4,
});

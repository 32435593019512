import { ButtonBase, Stack, styled } from '@mui/material';

export const DrawerHeaderContainer = styled(Stack)`
  padding-bottom: 20px;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const IconButton = styled(ButtonBase)`
  justify-content: flex-start;
`;

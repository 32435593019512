import { useTheme } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { BorderedLogoContainer } from './styles';

export function BorderedIcon() {
  const theme = useTheme();

  return (
    <BorderedLogoContainer>
      <Icon
        color={theme.palette.action.tertiary.active}
        name="building"
        size={24}
      />
    </BorderedLogoContainer>
  );
}

import { Mask } from '@hapvida/hapvida-core-components';

import { BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

export const getBeneficiaryDocumentsRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  const { document } = beneficiary;
  return [
    { label: 'Número da identidade', value: document.identityCard, xs: 4 },
    {
      label: 'Órgão emissor',
      value: document?.identityCardIssuerCode,
      xs: 4,
    },
    {
      label: 'Data da emissão da Identidade',
      value: Mask.date(document?.identityCardEmissionAt) ?? '-',
      xs: 4,
    },
    { label: 'País', value: '-', xs: 4 },
    {
      label: 'PIS/PASEP/NIT',
      value: document?.numberSocialIntegrationProgram,
      xs: 4,
    },
    {
      label: 'CNS',
      value: document?.nationalHealthCard,
      xs: 4,
    },
  ];
};

/* eslint-disable no-restricted-syntax */
import { splitFileNameAndExtension } from '@utils';
import { FileTypeEnum } from '@constants';
import { HapvidaApi } from '@http';

import MockApi from '../../http/MockApi';
import { SendToProcessRequestDTO } from './dtos/SendToProcessDTO';
import {
  GetCompanyDetailRequestDTO,
  GetCompanyDetailResponseDTO,
} from './dtos/GetCompanyDetailDTO';
import { UpdateResetupRequestDTO } from './dtos/UpdateSetupDTO';
import {
  ListCompaniesResponseDTO,
  ListCompaniesUserRegisterResponseDTO,
} from './dtos/ListCompanyDTO';
import { ListCompanyRequestDTO } from './dtos/ListCompanyRequestDTO';
import { UploadSetupFileRequestDTO } from './dtos/UploadSetupFileDTO';
import {
  ResultIntegrationRequestDTO,
  ResultIntegrationResponseDTO,
} from './dtos/ResultIntegrationDTO';

export default class CompanySetupService {
  private endpoints = { list: '/api/v1/companies' };

  public async fetchCompanies(options: ListCompanyRequestDTO) {
    const filteredParams = Object.fromEntries(
      Object.entries({ ...options, type: 'Company' }).filter(
        ([, value]) => value !== undefined && value !== null && value !== '',
      ),
    );

    const params = new URLSearchParams();

    Object.entries(filteredParams).forEach(([key, value]) => {
      if (typeof value === 'string' && value.includes(',')) {
        const valuesArray = value.split(',');
        valuesArray.forEach(val => {
          params.append(key, val);
        });
      } else {
        params.append(key, String(value));
      }
    });

    const { data } = await HapvidaApi.get<ListCompaniesResponseDTO>(
      this.endpoints.list,
      {
        params,
      },
    );

    return data.content;
  }

  public async uploadSetupFile({
    companyId,
    path,
    fileName,
  }: UploadSetupFileRequestDTO) {
    const [name, extension] = splitFileNameAndExtension(fileName);

    await HapvidaApi.patch(
      `/api/v1/companies/${companyId}/attachments/affiliates`,
      {
        file: path,
        extension,
        fileName: name,
        fileType: FileTypeEnum.CompanyAffiliates,
      },
    );
  }

  public async sendToProccess({ id }: SendToProcessRequestDTO) {
    const { data } = await HapvidaApi.patch<SendToProcessRequestDTO>(
      `/api/v1/companies/${id}/change-in-process`,
    );

    return data;
  }

  public async fetchCompanyDetail({
    id,
  }: GetCompanyDetailRequestDTO): Promise<GetCompanyDetailResponseDTO> {
    const { data } = await HapvidaApi.get<GetCompanyDetailResponseDTO>(
      `/api/v1/companies/${id}`,
    );

    return { content: { ...data.content } };
  }

  public async resultIntegrationCompany({
    id,
    limit,
    offset,
  }: ResultIntegrationRequestDTO): Promise<ResultIntegrationResponseDTO> {
    const { data } = await HapvidaApi.get<ResultIntegrationResponseDTO>(
      `/api/v1/companies/${id}/company-affiliates`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return data;
  }

  public async updateIsReSetup({
    companyId,
    deleteAffiliatesDocuments,
    affiliates,
    parentCompany,
  }: UpdateResetupRequestDTO) {
    const { data } = await HapvidaApi.put(
      `/api/v1/companies/${companyId}/mass-update`,
      {
        deleteAffiliatesDocuments,
        affiliates,
        parentCompany,
      },
    );

    return data;
  }

  public async deleteAffiliates(companyId: string) {
    await HapvidaApi.delete(`/api/v1/companies/${companyId}/affiliates`);
  }

  public async companyListSetupMock(): Promise<
    ListCompaniesUserRegisterResponseDTO | any
  > {
    return MockApi.fetchMock('companyListSetup');
  }

  public async companyDetailListMock({
    id,
  }: GetCompanyDetailRequestDTO): Promise<any> {
    const data = await MockApi.fetchMock('companyDetailSetup');
    return data.find(company => company.id === id);
  }
}

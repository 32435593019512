import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes';
import { UserType } from '@contexts';

import { HomePage } from './index';

export const HomeRouter: IAppRoute = {
  Page: HomePage,
  path: '/home',
  name: 'Início',
  isPrivate: true,
  Icon: <Icon name="home" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabledOnRoute: '/empresas',
};

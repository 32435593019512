import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { Icon } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';

import { Container } from './styles';

interface UserModuleProps {
  iconName: IconName;
  title: ReactNode;
  text: string;
}

export function PermissionItem({
  iconName,
  title,
  text,
}: Readonly<UserModuleProps>) {
  return (
    <Container>
      <Stack direction="row" gap={1} alignItems="center">
        <Icon name={iconName} size={24} color={COLORS.PRIMARY.ORANGE.MAIN} />
        <Typography variant="subtitle1" color={COLORS.MONOCHROMATIC.GRAY4}>
          {title}
        </Typography>
      </Stack>

      <Stack gap={0.5}>
        <Typography variant="body2" fontWeight={700}>
          Acesso Completo
        </Typography>
        <Typography variant="smallText">{text}</Typography>
      </Stack>
    </Container>
  );
}

import { styled } from '@mui/material';

export const Layout = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& > :last-child': {
    marginTop: spacing(3),
    alignSelf: 'flex-end',
  },

  marginBottom: '2rem',
}));

export const UploadDescription = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),
  paddingTop: spacing(3),
}));

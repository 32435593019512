import Stack from '@mui/material/Stack';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import { useBeneficiaryDetailsState } from './hooks';
import {
  BeneficiaryReviewsCard,
  BeneficiarySummaryCard,
  BeneficiaryDependentsAggregatedCard,
  BeneficiaryMovementsCard,
  BeneficiaryRegistrationDataCard,
  BeneficiaryContactsCard,
  BeneficiaryDocumentsCard,
  BeneficiaryProfessionalInformationCard,
  BeneficiaryInactiveCard,
  BeneficiaryBankDataCard,
  BeneficiaryAddressCard,
  BeneficiaryAdditionalInformationCard,
  BeneficiaryComplementaryInformationCard,
} from './components';

export function BeneficiaryDetailsLayout() {
  const {
    beneficiaryDetailsState,
    beneficiaryReviewsState,
    beneficiaryDependentsState,
    refreshBeneficiaryDetailsStates,
  } = useBeneficiaryDetailsState();

  const isLoading =
    beneficiaryDetailsState.isLoading ||
    beneficiaryReviewsState.isLoading ||
    beneficiaryDependentsState.isLoading;

  if (isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const hasError =
    beneficiaryDetailsState.isError ||
    beneficiaryReviewsState.isError ||
    beneficiaryDependentsState.isError;

  if (hasError) {
    return <ErrorLoadingPage onReloadClick={refreshBeneficiaryDetailsStates} />;
  }

  const beneficiaryReviews = beneficiaryReviewsState.data;
  const beneficiaryDependents = beneficiaryDependentsState.data;

  return (
    <Stack spacing={3} paddingTop={3} paddingBottom={10}>
      {beneficiaryReviews && <BeneficiaryReviewsCard />}

      <BeneficiarySummaryCard />

      {beneficiaryDependents && <BeneficiaryDependentsAggregatedCard />}

      <BeneficiaryMovementsCard />

      <BeneficiaryRegistrationDataCard />

      <BeneficiaryContactsCard />

      <BeneficiaryDocumentsCard />

      <BeneficiaryProfessionalInformationCard />

      <BeneficiaryInactiveCard />

      <BeneficiaryBankDataCard />

      <BeneficiaryAddressCard />

      <BeneficiaryAdditionalInformationCard />

      <BeneficiaryComplementaryInformationCard />
    </Stack>
  );
}

import { Grid, Typography } from '@mui/material';
import React from 'react';
import OrderColumn from '../../../../OrderColumn';
import { LineColumn } from '../../../types';
import { Container } from './styles';
import { SearchParamEnum, TableOrderParams } from '../../../../../constants';

interface ColumnLineItemProps {
  onOrderChange?: (params: TableOrderParams) => void;
  order?: TableOrderParams;
  columnKey: string;
  item: LineColumn;
}

const ColumnLineItem: React.FC<ColumnLineItemProps> = ({
  onOrderChange,
  columnKey,
  order,
  item,
}) => {
  const selectedOrder =
    order && columnKey === order[SearchParamEnum.SORT_BY]
      ? order[SearchParamEnum.ORDER_BY]
      : null;

  const handleOrderChange = () => {
    if (onOrderChange && columnKey) {
      if (order && order[SearchParamEnum.SORT_BY] === columnKey) {
        if (order[SearchParamEnum.ORDER_BY] === 'asc') {
          onOrderChange({
            [SearchParamEnum.ORDER_BY]: 'desc',
            [SearchParamEnum.SORT_BY]: columnKey,
          });
        }

        if (order[SearchParamEnum.ORDER_BY] === 'desc') {
          onOrderChange({});
        }
      } else {
        onOrderChange({
          [SearchParamEnum.ORDER_BY]: 'asc',
          [SearchParamEnum.SORT_BY]: columnKey,
        });
      }
    }
  };

  return (
    <Grid item xs={item.size} className="ListItem">
      {!item.hide && (
        <Container
          onClick={item.canOrder ? handleOrderChange : undefined}
          flexDirection="row"
          alignItems="center"
          useOrder={item.canOrder}
          gap={1}
        >
          <Typography>{item.name ?? item.status}</Typography>

          {item.canOrder && <OrderColumn order={selectedOrder} />}
        </Container>
      )}
    </Grid>
  );
};

export default ColumnLineItem;

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import {
  CustomSnackbarProvider,
  ConfirmationProvider,
  SidebarProvider,
} from '@hapvida/hapvida-core-components';

import { BaseThemeProvider } from 'themes';
import { CurrentThemeStateProvider } from '@hooks';

import { ScreenLoadingProvider } from '../hooks/useScreenLoading';
import GlobalStyle from '../themes/globalThemes';
import { AuthProvider } from '../hooks/useAuth';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      retry: 2,
    },
  },
});

export const AppProviders: React.FC = ({ children }) => {
  return (
    <BaseThemeProvider>
      <QueryClientProvider client={queryClient}>
        <SidebarProvider>
          <CustomSnackbarProvider>
            <ConfirmationProvider>
              <ScreenLoadingProvider>
                <GlobalStyle />
                <AuthProvider>
                  <CurrentThemeStateProvider>
                    {children}
                  </CurrentThemeStateProvider>
                </AuthProvider>
              </ScreenLoadingProvider>
            </ConfirmationProvider>
          </CustomSnackbarProvider>
        </SidebarProvider>
      </QueryClientProvider>
    </BaseThemeProvider>
  );
};

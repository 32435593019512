import Stack from '@mui/material/Stack';

import { Pill } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import {
  DependentProps,
  Beneficiary,
} from '@services/BeneficiaryService/dtos/ListBeneficiariesDTO';

import {
  BENEFICIARY_TYPE_COLOR,
  BENEFICIARY_TYPE_NAME,
  BeneficiaryTypeEnum,
} from '@constants';

interface TypeProps {
  dependentsAggregates?: string;
  dependent?: DependentProps;
  data?: Beneficiary;
}

function BeneficiaryType({ data, dependent, dependentsAggregates }: TypeProps) {
  const dependents = data?.dependentsCount || 0;
  const aggregates = data?.aggregatesCount || 0;

  return (
    <Stack direction="row" spacing={1}>
      {dependent ? (
        <Pill
          color={BENEFICIARY_TYPE_COLOR[dependent.type]}
          text={BENEFICIARY_TYPE_NAME[dependent.type]}
        />
      ) : (
        <>
          {data?.holder && (
            <Pill
              color={BENEFICIARY_TYPE_COLOR[data.holder.type]}
              text={BENEFICIARY_TYPE_NAME[data.holder.type]}
            />
          )}

          {data?.beneficiary && (
            <Pill
              color={BENEFICIARY_TYPE_COLOR[data.beneficiary.type]}
              text={BENEFICIARY_TYPE_NAME[data.beneficiary.type]}
            />
          )}
        </>
      )}

      {dependents > 0 && (
        <Pill
          text={dependents.toString()}
          color={COLORS.PRIMARY.ORANGE['+1']}
          rounded
        />
      )}

      {aggregates > 0 && (
        <Pill
          text={aggregates.toString()}
          color={COLORS.SECONDARY.BLUE.MAIN}
          rounded
        />
      )}
      {dependentsAggregates === BeneficiaryTypeEnum.HOLDER && (
        <Pill text="Titular" color={COLORS.PRIMARY.BLUE.MAIN} />
      )}

      {dependentsAggregates === BeneficiaryTypeEnum.AGGREGATE && (
        <Pill text="Agregado" color={COLORS.SECONDARY.BLUE.MAIN} />
      )}

      {dependentsAggregates === BeneficiaryTypeEnum.DEPENDENT && (
        <Pill text="Dependente" color={COLORS.PRIMARY.ORANGE['+1']} />
      )}
    </Stack>
  );
}

export default BeneficiaryType;

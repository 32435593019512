import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import {
  useConfirmation,
  useSidebar,
  Header,
} from '@hapvida/hapvida-core-components';

import { AddAccountFormProps, UserService } from '@services/UserService';

import { useScreenLoading } from 'hooks/useScreenLoading';
import { toastifyApiErrors } from '@utils';
import { useAuth } from 'hooks/useAuth';
import { UserQueryKeysEnum } from '@constants';

import { UserListRouter } from '../UserList/router';
import { CreateUserRouter } from './router';
import { AddAccountLayout } from './layout';
import { Container } from './styles';

const AddAccountPage = () => {
  const { user, logOut, selectedCompany } = useAuth();
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { onScreenLoading } = useScreenLoading();
  const { showConfirmation } = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleAddNewUserSuccess = () => {
    showConfirmation({
      title: 'Acesso atrelado com sucesso',
      icon: 'check',
      confirmText: 'Prosseguir',
      onConfirm: () => navigate(UserListRouter.path),
      content: (
        <Typography variant="text">
          Enviamos um e-mail de confirmação para o usuário.
        </Typography>
      ),
      type: 'success',
    });
  };

  const companyId = selectedCompany?.id;

  const handleAddAccount = async (data: AddAccountFormProps) => {
    try {
      if (companyId) {
        onScreenLoading(true);
        const createAccount = new UserService();
        await createAccount.createUser({
          companyId,
          ...data,
        });

        const usersQueryKey = [UserQueryKeysEnum.USERS_STATE];
        queryClient.removeQueries({ queryKey: usersQueryKey, exact: true });
        await queryClient.refetchQueries({
          queryKey: usersQueryKey,
        });

        handleAddNewUserSuccess();
      }
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      onScreenLoading(false);
    }
  };

  return (
    <Header
      subRoute={UserListRouter.name}
      toggleDrawer={toggleDrawer}
      drawerOpen={drawerOpen}
      onLogOut={logOut}
      user={user}
      route={{
        navigateBackPath: UserListRouter.path,
        icon: CreateUserRouter.Icon,
        name: CreateUserRouter.name,
      }}
    >
      <Container container spacing={3}>
        <Grid xs={6}>
          <AddAccountLayout onSubmit={handleAddAccount} />
        </Grid>
      </Container>
    </Header>
  );
};

export default AddAccountPage;

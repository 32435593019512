import { styled as muiStyled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { Icon } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

interface CustomTableRowProps {
  hasErrorBorder?: boolean;
  hasErrorBg?: boolean;
}

const customTableRowProps = ['hasErrorBorder', 'hasErrorBg'];

export const DateText = muiStyled(Typography)(() => ({
  color: COLORS.MONOCHROMATIC.GRAY4,
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
}));

export const CustomTableRow = muiStyled(TableRow, {
  shouldForwardProp: (propName: string) =>
    !customTableRowProps.includes(propName),
})<CustomTableRowProps>(({ hasErrorBg = false, hasErrorBorder = false }) => ({
  borderBottom: `1px solid ${
    !hasErrorBorder ? COLORS.MONOCHROMATIC.GRAY2 : COLORS.SECONDARY.RED.MAIN
  }`,
  background: !hasErrorBg ? 'none' : 'rgba(201, 54, 0, 0.04)',
  ':hover': {
    background:
      'linear-gradient(0deg, rgba(244, 158, 0, 0.04), rgba(244, 158, 0, 0.04)), #FFFFFF',
  },
}));

export const CustomTableCell = muiStyled(TableCell)(() => ({
  border: 0,
  padding: '18px',
}));

export const IconDiv = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '0.25rem',
  cursor: 'pointer',
  color: COLORS.PRIMARY.BLUE.MAIN,
  '& .MuiTypography-root': {
    color: COLORS.PRIMARY.BLUE.MAIN,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    margin: '2px 0 0 0.5rem',
  },
}));

export const NumberMovimentation = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiTypography-root': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    margin: '2px 0 0 0.5rem',
  },
}));

export const EditIcon = muiStyled(Icon)(() => ({
  color: `${COLORS.MONOCHROMATIC.GRAY4} !important`,
  borderColor: `${COLORS.MONOCHROMATIC.GRAY4} !important`,
  ':hover': {
    color: `${COLORS.PRIMARY.ORANGE.MAIN} !important`,
    borderColor: `${COLORS.PRIMARY.ORANGE.MAIN} !important`,
  },
  cursor: 'pointer',
}));

import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes';
import { UserType } from '@contexts';

import { BillStateProvider } from './hooks';
import BillDetail from '.';

const BillDetailRouter: IAppDynamicRoute = {
  Page: () => (
    <BillStateProvider>
      <BillDetail />
    </BillStateProvider>
  ),
  path: '/financeiro/detalhes/:id',
  dynamicPath: ({ billNumber }) => `/financeiro/detalhes/${billNumber}`,
  name: 'Financeiro',
  isPrivate: true,
  Icon: <Icon name="money" />,
  userTypes: [UserType.Company, UserType.Backoffice],
};

export default BillDetailRouter;

export const PortalRedirectLink = [
  {
    label: 'Hapvida',
    value:
      'https://webhap.hapvida.com.br/pls/webhap/webnewcadastrousuario.login',
  },
  {
    label: 'CCG',
    value: 'https://sigo.sh.srv.br/pls/webccg/webnewcadastrousuario.login',
  },
  {
    label: 'Clinipam',
    value: 'https://sigo.sh.srv.br/pls/webcli/webnewcadastrousuario.login',
  },
];

import { BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

export const getBeneficiaryComplementaryInformationRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  return [
    {
      label: 'Declaração de Saúde Beneficiário',
      value: '-',
      xs: 4,
    },
    {
      label: 'Peso',
      value: '-',
      xs: 4,
    },
    {
      label: 'Altura',
      value: '-',
      xs: 4,
    },
    {
      label: 'Lista de CIDs',
      value: '-',
      xs: 12,
    },
  ];
};

import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes';
import { UserType } from '@contexts';

import BillList from '.';

const BillListRouter: IAppRoute = {
  Page: BillList,
  path: '/financeiro',
  name: 'Financeiro',
  isPrivate: true,
  Icon: <Icon name="money" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabledOnRoute: '/empresas',
};

export default BillListRouter;

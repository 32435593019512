import { useCallback, useState } from 'react';

import { FinancialRedirectModal } from '@components';

import { ServiceCard } from '../ServiceCard';

export function FinancialRedirectCard() {
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = useCallback(() => setOpenModal(!openModal), [openModal]);

  return (
    <>
      <ServiceCard
        title="Relatórios de histórico financeiro"
        onClick={toggleModal}
      />
      <FinancialRedirectModal openModal={openModal} toggleModal={toggleModal} />
    </>
  );
}

import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes';
import { UserType } from '@contexts';

import { BeneficiaryListPage } from './index';

export const BeneficiaryListRouter: IAppRoute = {
  Page: BeneficiaryListPage,
  path: '/beneficiarios',
  name: 'Beneficiários',
  isPrivate: true,
  Icon: <Icon name="clipboard-pulse" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabledOnRoute: '/empresas',
};

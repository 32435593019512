import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import { CountDown, Form } from '@hapvida/hapvida-core-components';

import EmailLogo from './assets/email.svg';
import { StyledForm, Wrapper } from './styles';

interface EmailConfirmationProps {
  registerData: any;
}

const EmailConfirmationLayout: React.FC<EmailConfirmationProps> = ({
  registerData,
}) => {
  return (
    <StyledForm>
      <Form title="Enviamos um link de confirmação para seu e-mail.">
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="text">
              Acesse o link que enviamos para seu e-mail para concluir seu
              cadastro.
            </Typography>
          </Grid>
          <Wrapper>
            <img
              src={EmailLogo}
              alt="Desenho de uma prancheta com uma lista de itens com 'checks' indicando que o cadastro foi finalizado"
            />
          </Wrapper>
          <Grid item sm={12}>
            <Stack>
              <Typography variant="text">
                Cheque a caixa de entrada do e-mail abaixo:
              </Typography>
              <Typography variant="text" fontWeight="bold">
                {registerData?.companyManager.email}
              </Typography>
            </Stack>
          </Grid>
          <Grid item sm={12}>
            <CountDown
              available={{
                info: 'Não recebeu o e-mail?',
                clickText: 'Reenviar agora',
              }}
              unavaible={{
                loadingText: 'Aguarde',
                loadingInfo: 'para reenviar o e-mail de confirmação',
              }}
              timer={60}
            />
          </Grid>
        </Grid>
      </Form>
    </StyledForm>
  );
};

export default EmailConfirmationLayout;

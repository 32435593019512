import { useNavigate } from 'react-router-dom';

import { Mask, Sidebar as SidebarCore } from '@hapvida/hapvida-core-components';

import { CompanySetupListRouter } from '@flows/private/companySetup/pages/CompanySetupList/router';
import { useAuth } from 'hooks/useAuth';

import { BorderedIcon } from './components';

export function CompanyButton() {
  const { selectedCompany } = useAuth();
  const navigate = useNavigate();

  const navigateToCompanySetupList = () => {
    navigate(CompanySetupListRouter.path);
  };

  return (
    <SidebarCore.Button
      mainText={selectedCompany?.name || 'Selecione uma empresa'}
      subText={Mask.cnpj(selectedCompany?.document ?? '')}
      onClick={navigateToCompanySetupList}
      icon={<BorderedIcon />}
    />
  );
}

import { Avatar, Box, Typography } from '@mui/material';

import { DataGrid, Mask, Pill } from '@hapvida/hapvida-core-components';

import { CompanySetupDetails } from '@services/CompanySetupService/dtos/GetCompanyDetailDTO';
import { COMPANY_USER_REGISTER_STATUS_DATA } from '@services/CompanySetupService/dtos/ListCompanyDTO';

import { COMPANY_STATUS_NAME } from '@constants';

import { InfoText } from './styles';

interface CompanySetupDetailsInfoCardProps {
  companySetupDetailsData: CompanySetupDetails;
}

export function CompanySetupDetailsInfoCard({
  companySetupDetailsData,
}: CompanySetupDetailsInfoCardProps) {
  function companyDetailsFormatData(data: string | undefined) {
    if (!data) return '-';

    return data;
  }
  return (
    <DataGrid
      title=""
      customSpacing={3}
      rows={[
        {
          label: 'CNPJ',
          xs: 12,
          value: (
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">
                {Mask.cnpj(
                  companyDetailsFormatData(companySetupDetailsData.document),
                )}
              </Typography>
              <Avatar
                sx={{
                  marginRight: 2,
                }}
              />
            </Box>
          ),
        },
        {
          label: 'Razão Social',
          xs: 4,
          value: (
            <InfoText>
              {companyDetailsFormatData(companySetupDetailsData.name?.legal)}
            </InfoText>
          ),
        },
        {
          label: 'Nome fantasia',
          xs: 4,
          value: (
            <InfoText>
              {companyDetailsFormatData(companySetupDetailsData.name?.trade)}
            </InfoText>
          ),
        },
        {
          label: 'Status',
          xs: 4,
          value: (
            <Pill
              fitContent
              text={COMPANY_STATUS_NAME[companySetupDetailsData.status]}
              color={
                COMPANY_USER_REGISTER_STATUS_DATA[
                  companySetupDetailsData.status
                ]?.color
              }
            />
          ),
        },
      ]}
    />
  );
}

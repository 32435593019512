import { Header, useSidebar } from '@hapvida/hapvida-core-components';

import { UserListRouter } from '@flows/private/users/pages/UserList/router';
import { useAuth } from 'hooks/useAuth';

import { UserDetailsLayout } from './layout';
import { UserDetailsRouter } from './router';

export function UserDetailsPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { logOut, user } = useAuth();

  return (
    <Header
      subRoute={UserListRouter.name}
      toggleDrawer={toggleDrawer}
      drawerOpen={drawerOpen}
      onLogOut={logOut}
      user={user}
      route={{
        navigateBackPath: UserListRouter.path,
        icon: UserDetailsRouter.Icon,
        name: UserDetailsRouter.name,
      }}
    >
      <UserDetailsLayout />
    </Header>
  );
}

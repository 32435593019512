import { LineViewList } from '@hapvida/hapvida-core-components';

import { CompanyProps } from '@services/AuthService/dtos';

import { useAuth } from 'hooks/useAuth';
import { useFilterParams, useScreenLoading } from '@hooks';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { UserType } from '@contexts';

import { CompanyLineItem } from '../index';

interface CompanySetupListViewListProps {
  companySetupListData: CompanyProps[];
  hasNextPage: boolean;
  loadNextPage: () => Promise<unknown>;
}

export function CompanySetupListViewList({
  companySetupListData,
  hasNextPage,
  loadNextPage,
}: Readonly<CompanySetupListViewListProps>) {
  const { isLoading } = useScreenLoading();

  const { tableOrder, handleTableOrderChange } = useFilterParams();
  const { user } = useAuth();
  const { height } = useWindowDimensions();

  const listHeight = height > 1000 ? height - 580 : height - 380;

  const isBackoffice = user?.companyType === UserType.Backoffice;

  return (
    <LineViewList
      onOrderChange={handleTableOrderChange}
      currentUserType={user?.companyType}
      isNextPageLoading={isLoading}
      loadNextPage={loadNextPage}
      data={companySetupListData}
      hasNextPage={hasNextPage}
      order={tableOrder}
      renderRow={(data: CompanyProps, key: number) => (
        <CompanyLineItem company={data} key={key} />
      )}
      columns={{
        name: {
          name: 'Empresa',
          canOrder: isBackoffice,
          size: 6,
        },
        CNPJ: {
          canOrder: false,
          name: 'CNPJ',
          size: 3,
        },
        status: {
          status: 'Status da empresa',
          canOrder: isBackoffice,
          hide: !isBackoffice,
          size: 2,
        },
        action: {
          name: '',
          canOrder: isBackoffice,
          size: 1,
          hide: true,
        },
      }}
      listContainerStyle={{ maxHeight: listHeight }}
    />
  );
}

import { Header, useSidebar } from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';

import { BackofficeUserListRouter } from '../BackofficeUserList/router';
import { CreateBackofficeUserLayout } from './layout';
import { CreateBackofficeUserRouter } from './router';

export function CreateBackofficeUserPage() {
  const { drawerOpen, toggleDrawer } = useSidebar();
  const { user, logOut } = useAuth();

  return (
    <Header
      subRoute={BackofficeUserListRouter.name}
      toggleDrawer={toggleDrawer}
      drawerOpen={drawerOpen}
      onLogOut={logOut}
      user={user}
      route={{
        navigateBackPath: BackofficeUserListRouter.path,
        icon: CreateBackofficeUserRouter.Icon,
        name: CreateBackofficeUserRouter.name,
      }}
    >
      <CreateBackofficeUserLayout />
    </Header>
  );
}

import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from 'contexts/AuthContext';
import { IAppRoute } from 'routes';

import { PartnerCompanyListPage } from '.';

export const PartnerCompanyListRouter: IAppRoute = {
  Page: PartnerCompanyListPage,
  path: '/contratos',
  name: 'Contratos',
  isPrivate: true,
  Icon: <Icon name="building" />,
  userTypes: [UserType.Company, UserType.Backoffice],
  disabled: false,
};

import { Grid } from '@mui/material';

import { useDrawerFilter, DateFilter } from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';
import {
  FiltersDrawerButton,
  HealthOperatorDrawerFilter,
  SearchField,
} from '@components';

import { useBillsState } from '../../hooks';

export function FinancialListSubHeader() {
  const { billSummariesState, billListState } = useBillsState();
  const { filterCount } = useDrawerFilter();
  const { hasFilters } = useFilterParams();

  const resultLength = billListState.data?.bills?.length;
  const isLoading = billListState.isLoading || billSummariesState.isLoading;
  const isEmpty = resultLength === 0 && !hasFilters;
  const hasError = billListState.isError || billSummariesState.isError;

  if ((isLoading && !hasFilters) || hasError || isEmpty) return null;

  const filterDisabled =
    (!filterCount && billListState?.data?.bills.length === 0) ||
    (!filterCount && billListState.isLoading);

  return (
    <Grid container columnSpacing={3} paddingTop={3} paddingBottom={1}>
      <Grid item xs={12} md={7} lg={9}>
        <SearchField
          currentResultLength={resultLength}
          placeholder="Pesquisar Nº da fatura, Contrato ou Cód. Empresa"
        />
      </Grid>
      <Grid item xs={12} md={5} lg={3}>
        <FiltersDrawerButton
          subtitle="Selecione como deseja filtrar suas faturas"
          title="Filtrar faturas"
          disabled={filterDisabled}
        >
          <HealthOperatorDrawerFilter />
          <DateFilter />
        </FiltersDrawerButton>
      </Grid>
    </Grid>
  );
}

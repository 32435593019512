import { styled, Stack } from '@mui/material';

export const DrawerContent = styled(Stack)`
  scrollbar-color: ${({ theme }) => theme.palette.grey[400]} transparent;
  padding: 20px 4px 20px 0;
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.grey[400]};
    border: 4px solid transparent;
    border-radius: 8px;
  }
`;

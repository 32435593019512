import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { UserService, GetUserResponse } from '@services/UserService';

import { toastifyApiErrors } from '@utils';
import { UserQueryKeysEnum } from '@constants';

export function useUserDetailsState() {
  const { enqueueSnackbar } = useSnackbar();
  const { id = '' } = useParams();

  const userService = useMemo(() => new UserService(), []);

  const userDetailsState = useQuery<GetUserResponse, AxiosError>({
    queryKey: [UserQueryKeysEnum.USER_DETAILS_STATE, id],
    queryFn: () => userService.getUserDetail(id),
    enabled: Boolean(id),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshEditUserDetails = () => {
    userDetailsState.remove();

    userDetailsState.refetch();
  };

  return {
    userDetailsState,
    refreshEditUserDetails,
  };
}

import { DataGrid } from '@hapvida/hapvida-core-components';

import { useBeneficiaryDetailsState } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/hooks';

import { getBeneficiaryRegistrationDataRows } from './utils';

export function BeneficiaryRegistrationDataCard() {
  const { beneficiaryDetailsState } = useBeneficiaryDetailsState();

  if (!beneficiaryDetailsState.isSuccess) {
    return null;
  }

  const beneficiary = beneficiaryDetailsState.data;

  const rows = getBeneficiaryRegistrationDataRows(beneficiary);

  return <DataGrid title="Dados cadastrais" rows={rows} />;
}

import { useSnackbar } from 'notistack';

import { Bill } from '@services/FinancialService/dtos/BillsListDTO';
import FinancialService, { BillDetails } from '@services/FinancialService';

import { toastifyApiErrors } from '@utils';

export interface BillFileProps {
  fileName: string;
  path: string;
}

export function useBill() {
  const { enqueueSnackbar } = useSnackbar();

  const getBillFile = async (
    bill: Bill | BillDetails,
  ): Promise<BillFileProps | undefined> => {
    try {
      const financialService = new FinancialService();

      const billPath = await financialService.getBilling({
        billingNumber: bill?.billingNumber,
        personNumber: bill?.personNumber,
      });

      return {
        fileName: 'Boleto',
        path: billPath,
      };
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return undefined;
    }
  };

  const getCoparticipationReportFiles = async (
    bill: Bill | BillDetails,
  ): Promise<BillFileProps[] | undefined> => {
    try {
      const service = new FinancialService();
      const dataCoparticipationAnalytical = await Promise.all(
        bill.coparticipationAnalytical.map(async item => {
          const data = await service.getCoPaymentReport({
            billingExtractId: item.billingExtractId,
            personNumber: bill.personNumber,
            fileName: item.fileName,
          });
          return {
            fileName: item.fileName,
            path: data,
          };
        }),
      );
      return dataCoparticipationAnalytical;
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return undefined;
    }
  };

  return {
    getBillFile,
    getCoparticipationReportFiles,
  };
}

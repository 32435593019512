import { TableHeadParams } from '@hapvida/hapvida-core-components/src/components/BaseTable';

export enum BeneficiaryMovementListTableColumnNameEnum {
  NUMBER = 'Number',
  OPERATION_CODE_TYPE = 'operationCodeType',
  HEALTH_OPERATOR = 'healthOperator',
  CREATED_AT = 'CreatedAt',
  HEALTH_PLAN_CODE = 'healthPlanCode',
  NEW_CONTRACT_NUMBER = 'newContractNumber',
  UNIT_CODE = 'unitCode',
}

export const beneficiaryMovementListTableHeadColumns: TableHeadParams[] = [
  {
    id: 1,
    label: 'Código da operação',
    name: BeneficiaryMovementListTableColumnNameEnum.NUMBER,
  },
  {
    id: 2,
    label: 'Cód/Tipo movimentação',
    name: BeneficiaryMovementListTableColumnNameEnum.OPERATION_CODE_TYPE,
    sortable: false,
  },
  {
    id: 3,
    label: 'Operadora',
    name: BeneficiaryMovementListTableColumnNameEnum.HEALTH_OPERATOR,
    sortable: false,
  },
  {
    id: 4,
    label: 'Data',
    name: BeneficiaryMovementListTableColumnNameEnum.CREATED_AT,
  },
  {
    id: 5,
    label: 'Plano',
    name: BeneficiaryMovementListTableColumnNameEnum.HEALTH_PLAN_CODE,
    sortable: false,
  },
  {
    id: 6,
    label: 'Nº contrato novo',
    name: BeneficiaryMovementListTableColumnNameEnum.NEW_CONTRACT_NUMBER,
    sortable: false,
  },
  {
    id: 7,
    label: 'Unidade',
    name: BeneficiaryMovementListTableColumnNameEnum.UNIT_CODE,
    sortable: false,
  },
];

import { Company } from '@hapvida/hapvida-core-components';

import { BeneficiaryDependentProps } from '@services/BeneficiaryService';

import Plan from '@flows/private/beneficiaries/components/Plan';
import Type from '@flows/private/beneficiaries/components/Type';
import Detail from '@flows/private/beneficiaries/components/Detail';
import Person from '@flows/private/beneficiaries/components/Person';
import DetailContract from '@flows/private/beneficiaries/components/DetailContract';

import { DependentsAggregatedListTableNameEnum } from '../constants/dependentsAggregatedListTable';

export const getBeneficiaryDependentsAggregatedListTableRows = (
  beneficiaryDependents: BeneficiaryDependentProps[],
) => {
  return beneficiaryDependents?.map(dependent => ({
    id: dependent.id,
    main: {
      [DependentsAggregatedListTableNameEnum.DETAIL]: (
        <Detail data={dependent} />
      ),
      [DependentsAggregatedListTableNameEnum.PERSON]: (
        <Person data={dependent.user} />
      ),
      [DependentsAggregatedListTableNameEnum.TYPE]: (
        <Type dependentsAggregates={dependent.type} />
      ),
      [DependentsAggregatedListTableNameEnum.COMPANY]: (
        <Company company={dependent.healthOperator} />
      ),
      [DependentsAggregatedListTableNameEnum.PLAN]: (
        <Plan
          code={dependent.contractDetail.planHealth.code}
          name={dependent.contractDetail.planHealth.coverage}
          ansCode={dependent.contractDetail.planHealth.ansCode}
        />
      ),
      [DependentsAggregatedListTableNameEnum.PARTNER_COMPANY]: (
        <DetailContract data={dependent.contractDetail} />
      ),
    },
    dependents: [],
  }));
};

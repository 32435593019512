import { Text } from './styles';

export interface PlanProps {
  ansCode?: string;
  code?: string;
  name?: string;
}

function Plan({ ansCode: _ansCode, code, name }: PlanProps) {
  return <Text>{`${code ?? ''} - ${name ?? ''}`}</Text>;
}

export default Plan;

import { Button, Stack, Typography } from '@mui/material';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

import { ProfileDetailsButton } from '@components';
import { PartnerCompanyListMockContentProps } from 'infra/mock';
import { StyledChip } from '@flows/private/partnerCompany/pages/PartnerCompanyList/styles';
import { ListGridRowProps } from 'components/ListGrid/types';

import { PartnerCompanyListColumnNameEnum } from './index';

export function getPartnerCompanyListRows(
  partnerCompanyList: PartnerCompanyListMockContentProps[],
): ListGridRowProps[] {
  return partnerCompanyList.map(partnerCompanyData => {
    const {
      isMainPartnerCompany,
      mainPartnerCompany,
      partnerCompany,
      profile,
    } = partnerCompanyData;
    const partnerCompanyChipText = isMainPartnerCompany ? 'Mãe' : 'Filho';

    const mainPartnerCompanyText = isMainPartnerCompany
      ? '-'
      : mainPartnerCompany;

    const chipColor = isMainPartnerCompany
      ? COLORS.SECONDARY.YELLOW.MAIN
      : COLORS.SECONDARY.BLUE['+1'];

    const textColor = isMainPartnerCompany
      ? COLORS.MONOCHROMATIC.GRAY4
      : COLORS.WHITE;

    return {
      id: partnerCompanyData.partnerCompany,
      items: {
        [PartnerCompanyListColumnNameEnum.PARTNER_COMPANY]: (
          <Stack alignItems="center" flexDirection="row" gap={1}>
            <Typography fontWeight={700}>{partnerCompany}</Typography>
            <StyledChip
              textColor={textColor}
              bgColor={chipColor}
              label={partnerCompanyChipText}
            />
          </Stack>
        ),
        [PartnerCompanyListColumnNameEnum.MAIN_PARTNER_COMPANY]: (
          <Typography fontWeight={700}>{mainPartnerCompanyText}</Typography>
        ),
        [PartnerCompanyListColumnNameEnum.PROFILE]: (
          <ProfileDetailsButton
            profileId={profile.id}
            profileName={profile.name}
          />
        ),
        [PartnerCompanyListColumnNameEnum.ACCESS_ACTION]: (
          <Stack alignSelf="flex-end">
            <Button
              variant="outlined"
              endIcon={<Icon name="right" size={20} />}
            >
              Acessar
            </Button>
          </Stack>
        ),
      },
    };
  });
}

import { styled, Grid, css } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

interface BoxItemProps {
  checked: boolean;
  disabled: boolean;
}

export const BoxItem = styled(Grid)<BoxItemProps>`
  padding: 10px 16px;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${COLORS.DISABLED.BACKGROUND};
    `}

  ${({ checked, disabled }) =>
    checked &&
    !disabled &&
    css`
      background-color: ${COLORS.PRIMARY.ORANGE.MAIN}14;
    `}
`;

import { useCallback } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import { EmptyResult } from '@components';
import { useAuth } from 'hooks/useAuth';

import { CompanySetupDetailsInfoCard } from './components/CompanySetupDetailsInfoCard';
import { useCompanySetupDetailsState } from './hooks';
import { CompanySetupListRouter } from '../CompanySetupList/router';
import { CompanySetupDetailsCard } from './components/CompanySetupDetailsCard';

export function CompanySetupDetailsLayout() {
  const { companySetupDetailsState, refreshCompanySetupDetailsStates } =
    useCompanySetupDetailsState();
  const { user } = useAuth();
  const navigateTo = useNavigate();

  const userName = user?.name ?? '';

  const handleRedirectCompanySetupList = useCallback(() => {
    navigateTo(CompanySetupListRouter.path);
  }, [navigateTo]);

  if (companySetupDetailsState.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const emptyState =
    companySetupDetailsState.failureReason?.response?.status === 404;

  if (emptyState) {
    return (
      <EmptyResult
        message={`${userName}, nenhuma empresa foi encontrada.`}
        buttonProps={{
          onClick: handleRedirectCompanySetupList,
          children: 'Voltar para a seleção de empresa',
        }}
      />
    );
  }

  if (companySetupDetailsState.isError) {
    return (
      <ErrorLoadingPage onReloadClick={refreshCompanySetupDetailsStates} />
    );
  }

  const companySetupDetailsData = companySetupDetailsState.data.content;

  return (
    <Stack>
      <CompanySetupDetailsInfoCard
        companySetupDetailsData={companySetupDetailsData}
      />
      <CompanySetupDetailsCard
        companySetupDetailsData={companySetupDetailsData}
      />
    </Stack>
  );
}

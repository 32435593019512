import { IAppDynamicRoute } from 'routes';

import PasswordRecoveryStepsPage from '.';

const PasswordRecoveryRouter: IAppDynamicRoute = {
  Page: PasswordRecoveryStepsPage,
  path: '/recuperacao-de-senha/:passwordDefinitionStep',
  name: 'Recuperação de senha',
  dynamicPath: ({ passwordRecoveryStep }) =>
    `/recuperacao-de-senha/${passwordRecoveryStep}`,
  Icon: <></>,
  userTypes: [],
  disabled: false,
};

export default PasswordRecoveryRouter;

import { Mask } from '@hapvida/hapvida-core-components';

import { BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

export const getBeneficiaryContactsRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  return [
    { label: 'E-mail', value: beneficiary.contact.email, xs: 4 },
    {
      label: 'Permite o envio de email?',
      value: beneficiary?.contact?.allowsSendingEmail ? 'Sim' : 'Não',
      xs: 8,
    },

    {
      label: 'Celular',
      value:
        Mask.phone(
          `${beneficiary?.contact?.mobile?.directDialingFromDistance}${beneficiary.contact.mobile?.number}`,
        ) || '-',
      xs: 4,
    },
    {
      label: 'Permite o envio de sms?',
      value: beneficiary?.contact?.allowsSendingSmS ? 'Sim' : 'Não',
      xs: 8,
    },
    {
      label: 'Telefone fixo',
      value:
        Mask.phone(
          `${beneficiary?.contact.phone?.directDialingFromDistance}${beneficiary.contact.phone?.number}`,
        ) || '-',
      xs: 12,
    },
  ];
};

import { Grid, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, Icon, SummaryCardNew } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { AddAccountFormProps } from '@services/UserService';

import { FormRegisterUser } from '@flows/private/users/pages/CreateUser/components/FormRegisterUser';

import { schema } from './control/validation';
import { ContainerButton, ContainerTitle } from './styles';

interface RegisterUserCompanyProps {
  title: string;
  subtitle: string;
  titleForm: string;
  onSubmit: (data: AddAccountFormProps) => Promise<void>;
}

export const RegisterUserCompany = ({
  title,
  subtitle,
  titleForm,
  onSubmit,
}: RegisterUserCompanyProps) => {
  const {
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { isValid, errors },
  } = useForm<AddAccountFormProps>({
    mode: 'all',
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });

  const onSubmitForm: SubmitHandler<AddAccountFormProps> = async data => {
    await onSubmit(data);
  };

  return (
    <Stack gap={3}>
      <ContainerTitle spacing={1}>
        <Typography variant="h6" color={COLORS.MONOCHROMATIC.GRAY4}>
          {title}
        </Typography>
        <Typography variant="text" color={COLORS.MONOCHROMATIC.GRAY4}>
          {subtitle}
        </Typography>
      </ContainerTitle>

      <SummaryCardNew.Container>
        <Typography variant="subtitle1">{titleForm}</Typography>
        <Grid container spacing={{ xs: 5, sm: 3 }} component="form">
          <FormRegisterUser
            controlForm={control}
            watchForm={watch}
            errorsForm={errors}
            triggerForm={trigger}
          />
        </Grid>
      </SummaryCardNew.Container>

      <ContainerButton>
        <Button
          variant="contained"
          color="success"
          onClick={handleSubmit(onSubmitForm)}
          disabled={!isValid}
          endIcon={<Icon name="right" />}
        >
          Enviar dados
        </Button>
      </ContainerButton>
    </Stack>
  );
};

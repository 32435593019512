import { BeneficiaryDetailsProps } from '@services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

export const getBeneficiaryBankDataRows = (
  beneficiary: BeneficiaryDetailsProps,
) => {
  const { holderBankData } = beneficiary;
  return [
    {
      label: 'Código do Banco do Beneficiário Titular',
      value: holderBankData?.code,
      xs: 12,
    },
    {
      label: 'Número da agência',
      value: holderBankData?.agency,
      xs: 4,
    },
    {
      label: 'Dígito da agência',
      value: holderBankData?.digitAgency,
      xs: 8,
    },
    {
      label: 'Número da conta corrente',
      value: holderBankData?.currentAccountNumber,
      xs: 4,
    },
    {
      label: 'Dígito da conta corrente',
      value: holderBankData?.digitCurrentAccountNumber,
      xs: 8,
    },
  ];
};

/* eslint-disable no-restricted-syntax */
import { SearchParamEnum } from '@hapvida/hapvida-core-components';

import { splitFileNameAndExtension } from '@utils';

import { HapvidaApi } from '../../http/Hapvida';
import {
  ListMovimentationRequest,
  MovementsResponseDTO,
} from './dtos/ListMovimentationsDTO';
import { MovimentationDetailRequest } from './dtos/MovimentationDetailDTO';
import {
  CreateMovimentationRequestDTO,
  CreateMovimentationResponseDTO,
} from './dtos/CreateMovimentationDTO';
import {
  SummaryCardInfo,
  SummaryInfoRequestDTO,
  SummaryInfoResponseDTO,
} from './dtos/SummaryInfoDTO';
import {
  MovementLivesRequestDTO,
  MovementLivesResponseDTO,
} from './dtos/MovementLivesDTO';
import {
  MovementExportFileIDRequestDTO,
  MovementExportFileIDResponseDTO,
  MovementExportFileRequestDTO,
} from './dtos/MovementExportFileDTO';
import { CancelSolicitationMovementationRequestDTO } from './dtos/CancelSolicitationDTO';
import { generateUrlParams } from '../../../utils/generateUrlParams';
import {
  MovementsSummaryRequestDTO,
  MovementsSummaryResponseDTO,
} from './dtos';

export * from './dtos';

export default class MovimentationService {
  translateOrder = (order?: string | null) => {
    if (typeof order === 'string') {
      return order[0].toUpperCase() + order.substring(1);
    }
    return undefined;
  };

  public async fetchMovements({
    companyId,
    filters,
  }: ListMovimentationRequest) {
    const params = generateUrlParams(filters);

    const { data } = await HapvidaApi.get<MovementsResponseDTO>(
      `/api/v1/companies/${companyId}/movements`,
      {
        params,
      },
    );

    return data.content;
  }

  public async getMovementLives({
    movementId,
    companyId,
    filters,
  }: MovementLivesRequestDTO) {
    const { data } = await HapvidaApi.get<MovementLivesResponseDTO>(
      `/api/v1/companies/${companyId}/movements/${movementId}`,
      {
        params: {
          ...filters,
          [SearchParamEnum.ORDER_BY]: this.translateOrder(
            filters?.[SearchParamEnum.ORDER_BY],
          ),
        },
      },
    );
    return data.content;
  }

  public async fetchMovimentationById(id: string) {
    const { data } = await HapvidaApi.get<MovimentationDetailRequest>(
      `/api/v1/movements/${id}`,
    );

    return data;
  }

  public async createMovimentation({
    companyId,
    ...file
  }: CreateMovimentationRequestDTO) {
    const { fileName } = file;

    const [name, extension] = splitFileNameAndExtension(fileName);

    const formattedFile = {
      extension,
      file: file.path,
      fileName: name,
      fileType: 'Beneficiary',
    };

    const { data } = await HapvidaApi.patch<CreateMovimentationResponseDTO>(
      `/api/v1/companies/${companyId}/attachments/beneficiary`,
      formattedFile,
    );
    return data.content.movementId;
  }

  public fetchMovementsSummary = async ({
    movementId,
    companyId,
    ...filters
  }: MovementsSummaryRequestDTO) => {
    const params = generateUrlParams(filters);

    if (movementId !== undefined && movementId !== '') {
      params.append('movement-id', movementId);
    }

    const { data } = await HapvidaApi.get<MovementsSummaryResponseDTO>(
      `/api/v1/companies/${companyId}/movements/summary`,
      {
        params,
      },
    );

    return data.content;
  };

  public fetchSummaryCardInfo = async ({
    movementId,
    companyId,
    ...options
  }: SummaryInfoRequestDTO) => {
    const params = generateUrlParams(options);

    if (movementId !== undefined && movementId !== '') {
      params.append('movement-id', movementId);
    }

    const { data } = await HapvidaApi.get<SummaryInfoResponseDTO>(
      `/api/v1/companies/${companyId}/movements/summary`,
      {
        params,
      },
    );

    let summary: {
      [key: string]: number;
    } = {};

    const summaryKeys: {
      [key: string]: string;
    } = {
      HolderInclusion: 'inclusion',
      DependentInclusion: 'dependent',
      CadastralChange: 'alteration',
      ContractTransfer: 'transfer',
      PlanChange: 'plan',
      Reactivation: 'reactivation',
      Exclusion: 'exclusion',
      ExclusionCancelation: 'cancel-exclusion',
      Retired: 'demission',
    };

    data.content.forEach((item: SummaryCardInfo) => {
      const key = summaryKeys[item.code as any];
      const prev = summary[key] ?? 0;

      summary = {
        ...summary,
        [key]: prev + item.count,
      };
    });

    return summary;
  };

  public async getMovimentationFileId({
    companyId,
    movementId,
    review,
  }: MovementExportFileIDRequestDTO) {
    const { data } = await HapvidaApi.get<MovementExportFileIDResponseDTO>(
      `/api/v1/companies/${companyId}/movements/${movementId}/report`,
      {
        params: {
          review,
        },
      },
    );
    return data.content.fileNameIdentifier;
  }

  public async getExportedMovementFile({
    companyId,
    movementId,
    fileNameIdentifier,
  }: MovementExportFileRequestDTO) {
    const { data } = await HapvidaApi.get(
      `/api/v1/companies/${companyId}/movements/${movementId}/exported/${fileNameIdentifier}`,
    );
    return data;
  }

  public async cancelMovementSolicitation({
    movementId,
    userId,
  }: CancelSolicitationMovementationRequestDTO) {
    const { data } = await HapvidaApi.patch(
      `/api/v1/movements/${movementId}/beneficiary-movement/${userId}/cancel`,
    );
    return data;
  }
}

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

import { UserService, GetUserResponse } from '@services/UserService';

import { BackofficeUserQueryKeysEnum } from '@constants';
import { toastifyApiErrors } from '@utils';

export function useBackofficeUserDetailsState() {
  const { enqueueSnackbar } = useSnackbar();
  const { id = '' } = useParams();

  const userService = useMemo(() => new UserService(), []);

  const backofficeUserDetailsState = useQuery<GetUserResponse, AxiosError>({
    queryKey: [BackofficeUserQueryKeysEnum.USER_DETAILS_STATE, id],
    queryFn: () => userService.getUserDetail(id),
    enabled: Boolean(id),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshBackofficeUserDetails = () => {
    backofficeUserDetailsState.remove();
    backofficeUserDetailsState.refetch();
  };

  return {
    refreshBackofficeUserDetails,
    backofficeUserDetailsState,
  };
}

import React from 'react';

import { MainContent, PageContainer, StyledStack } from './styles';
import PageHeader from '../PageHeader';

export enum UserType {
  Backoffice = 'Backoffice',
  Agency = 'Agency',
  Company = 'Company',
}

interface DrawerLayoutProps {
  route?: {
    name?: string;
    icon?: React.JSX.Element;
    navigateBackPath?: string;
  };
  subRoute?: string;
  user?: {
    profile: string;
    name: string;
    companyType: UserType;
  };
  subHeader?: React.JSX.Element;
  drawerOpen?: boolean;
  toggleDrawer(): void;
  onLogOut?(): void;
  totalQuotationsAndCompany?: number;
}

export const Header: React.FC<DrawerLayoutProps> = ({
  route,
  subRoute,
  user,
  children,
  subHeader,
  onLogOut,
  totalQuotationsAndCompany,
  drawerOpen,
  toggleDrawer,
}) => {
  return (
    <PageContainer>
      <StyledStack>
        <PageHeader
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          user={user}
          route={route}
          subHeader={subHeader}
          totalQuotationsAndCompany={totalQuotationsAndCompany}
          onLogOut={onLogOut}
          subRoute={subRoute}
        />
        <MainContent component="main" paddingX={3}>
          {children}
        </MainContent>
      </StyledStack>
    </PageContainer>
  );
};

import { Control } from 'react-hook-form';

import { ExportModalFormProps } from '../../types';

interface ModalContentProps {
  hasProcessedWithReview: boolean;
  control: Control<ExportModalFormProps>;
  totalLives: number;
  reviewLives: number;
}

const ExportTypes = {
  ONLY_REVIEW_LIVES: {
    KEY: 'only_review_lives',
    VALUE: 'only_review_lives',
  },
  ALL_LIVES: {
    KEY: 'all',
    VALUE: 'all_lives',
  },
};

export { ModalContentProps, ExportTypes };

import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { CompanyProps } from '@services/AuthService/dtos';
import CompanyService from '@services/CompanyService';

import { CompanySetupDetailsRouter } from '@flows/private/companySetup/pages/CompanySetupDetails/router';
import { CompanyStatusEnum } from '@constants';
import { toastifyApiErrors } from '@utils';
import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';
import { HomeRouter } from '@flows/private/home/router';

import { useCompanySetupListState } from '../useCompanySetupListState';

export function useSelectCompany() {
  const { refreshCompanySetupListStates } = useCompanySetupListState();
  const { updateAccount, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleRemoveFromNewCompanies = useCallback(
    async (companyId: string) => {
      try {
        const companyService = new CompanyService();
        await companyService.removeIsNewCompanyStatus({ id: companyId });
        refreshCompanySetupListStates();
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      }
    },
    [],
  );

  const handleSelectCompany = async (company?: CompanyProps) => {
    const updatedAccount = {
      selectedCompany: company,
    };

    if (company?.isNewCompany) {
      handleRemoveFromNewCompanies(company.id);
    }

    updateAccount(updatedAccount);

    const backofficeUser = user?.companyType === UserType.Backoffice;

    if (
      company &&
      backofficeUser &&
      company.status === CompanyStatusEnum.REGISTER_ACCESS
    ) {
      navigate(
        CompanySetupDetailsRouter.dynamicPath({ companyId: company.id }),
      );
      return;
    }

    if (company) {
      navigate(HomeRouter.path);
    }
  };

  return {
    handleSelectCompany,
  };
}

import Stack from '@mui/material/Stack';

import { Mask } from '@hapvida/hapvida-core-components';

import { BeneficiaryContractDetail } from '@services/BeneficiaryService/dtos/ListBeneficiariesDTO';

import { Label } from './styles';

interface DetailContractProps {
  data: BeneficiaryContractDetail;
}

const DetailContract: React.FC<DetailContractProps> = ({
  data,
}: DetailContractProps) => {
  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Label fontSize="12px">
          {(data.partnerCompany ?? data.number)?.toString()}
        </Label>
      </Stack>
      <Label fontSize="14px" fontWeight={700}>
        {Mask.cnpj(data?.unitDocument)}
      </Label>
      <Label fontSize="12px">
        Unidade: <strong>{data.unitCode ?? '-'}</strong>
      </Label>
    </Stack>
  );
};

export default DetailContract;

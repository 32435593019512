import { FC, Fragment, ReactNode } from 'react';
import { PaperProps } from '@mui/material';

import {
  TableHeadCell,
  TableBodyCell,
  TableOrderParams,
} from '@hapvida/hapvida-core-components';

import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import CustomPagination from './components/Pagination';

import {
  Wrapper,
  StyledTable,
  StyledTableRow,
  TotalItemsLabel,
  StyledTableHead,
  StyledTableContainer,
} from './styles';

export type TableHeadParams = {
  id: number;
  label: string;
  name: string;
  lastLabel?: string;
  sortable?: boolean;
  cellWidth?: number | string;
};

export interface TableViewProps extends PaperProps {
  to: number | string;
  from: number | string;
  count: number;
  currentPage?: number;

  data?: ReactNode[];
  tableOrder?: TableOrderParams;
  TableHeadColumns: TableHeadParams[];
  onOrderChange?(params: TableOrderParams): void;
  onPageChange?(event: unknown, newPage: number): void;

  width?: string;
  minWidth?: string;
  tableLayout?: 'fixed' | 'auto';
  tableContainerHeight?: number | string;
  tableContainerWidth?: string;

  hasCellAction?: boolean;
  showFooter?: boolean;
  footerLabel?: string;

  rowColor?: string;
}

const BaseTable: FC<TableViewProps> = ({
  data,
  tableOrder,
  TableHeadColumns,
  onPageChange,
  onOrderChange,
  children,
  count,
  from = 0,
  to = 0,
  currentPage = 1,
  width = '1500px',
  minWidth = '100%',
  tableLayout = 'fixed',
  hasCellAction = false,
  showFooter = true,
  tableContainerWidth = 'auto',
  tableContainerHeight = 540,
  rowColor,
  footerLabel = 'solicitações',
  ...paperProps
}) => {
  const dynamicRow = (item: any) => {
    const cells: any = [];

    TableHeadColumns.forEach((column: TableHeadParams, index) => {
      if (item[column.name]) {
        const cell = (
          <TableBodyCell key={Math.random() * (10 - index) + Math.random()}>
            {item[column.name]}
            {item[column.lastLabel ? column.lastLabel : '']}
          </TableBodyCell>
        );
        cells.push(cell);
      }
    });

    return (
      <StyledTableRow
        key={item.id}
        hover
        sx={{ background: rowColor && rowColor }}
      >
        {cells}
      </StyledTableRow>
    );
  };

  return (
    <Wrapper
      tablecontainerwidth={tableContainerWidth}
      elevation={0}
      {...paperProps}
    >
      <StyledTableContainer tablecontainerheight={tableContainerHeight}>
        <StyledTable
          width={width}
          minwidth={minWidth}
          tablelayout={tableLayout}
        >
          <StyledTableHead>
            <TableRow>
              {TableHeadColumns.map(column => (
                <TableHeadCell
                  key={column.id}
                  label={column.label}
                  lastLabel={column.lastLabel}
                  name={column.name}
                  order={tableOrder}
                  hide={column.sortable === false}
                  onOrderChange={onOrderChange}
                  width={column.cellWidth}
                />
              ))}

              {hasCellAction && (
                <>
                  <TableHeadCell width={100} />
                </>
              )}
            </TableRow>
          </StyledTableHead>
          {children ? (
            children
          ) : (
            <TableBody>
              {data &&
                data.map((item, index) => (
                  <Fragment key={Math.random() * (10 - index) + Math.random()}>
                    {dynamicRow(item)}
                  </Fragment>
                ))}
            </TableBody>
          )}
        </StyledTable>
      </StyledTableContainer>

      {showFooter && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginTop: '20px' }}
          p={2}
        >
          <TotalItemsLabel>
            Visualizando {from} de {to} {footerLabel}
          </TotalItemsLabel>

          <CustomPagination
            count={count}
            currentPage={currentPage}
            onChange={(evt, page) => {
              if (onPageChange) onPageChange(evt, page);
            }}
          />
        </Stack>
      )}
    </Wrapper>
  );
};

export default BaseTable;

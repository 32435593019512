import React, { useCallback, useContext, useMemo, useState } from 'react';

import { LocalStorageKeyEnum } from '@constants';

import {
  AuthContextData,
  AccountProps,
  AuthContext,
} from '../contexts/AuthContext';

const AuthProvider: React.FC = ({ children }) => {
  const [accountState, setAccountState] = useState<AccountProps>(() => {
    const storageData = localStorage.getItem(LocalStorageKeyEnum.ACCOUNT_DATA);

    if (storageData) {
      return JSON.parse(storageData);
    }

    return { selectedCompany: undefined, user: undefined, token: undefined };
  });

  const modifyAccountState = useCallback(
    (account: AccountProps) => {
      setAccountState(account);

      localStorage.setItem(
        LocalStorageKeyEnum.ACCOUNT_DATA,
        JSON.stringify(account),
      );
    },
    [setAccountState],
  );

  const logIn = useCallback((account: AccountProps) => {
    modifyAccountState(account);
  }, []);

  const logOut = useCallback(() => {
    localStorage.clear();
    setAccountState({});
    window.location.replace(process.env.REACT_APP_LOGIN_URL);
  }, [setAccountState]);
  const updateAccount = useCallback(
    (account: AccountProps) => {
      modifyAccountState({
        ...accountState,
        ...account,
      });
    },
    [accountState, modifyAccountState],
  );

  const valueAuthContextProvider = useMemo(
    () => ({
      logOut,
      logIn,
      ...accountState,
      updateAccount,
    }),
    [accountState, logOut, logIn, updateAccount],
  );

  return (
    <AuthContext.Provider value={valueAuthContextProvider}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };

import { Stack, Typography } from '@mui/material';

import { COLORS, StepLayout } from '@hapvida/hapvida-core-components';

import { stepsUserFirstAccess } from './userFirstAccessSteps';
import { useSteps } from './hooks';
import { StyledTypography } from './styles';

export function UserFirstAccessLayout() {
  const { handleStepChange, currentStep, currentStepList } = useSteps();

  return (
    <StepLayout
      currentStep={currentStep}
      onStepChange={handleStepChange}
      steps={currentStepList}
      hasHeader={false}
      hasFirstStep={false}
    >
      <Stack gap={5}>
        <Stack
          gap={1}
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Typography variant="text">Já tem cadastro?</Typography>
          <a href={process.env.REACT_APP_LOGIN_URL}>
            <StyledTypography variant="link" color={COLORS.PRIMARY.BLUE.MAIN}>
              Efetue o login
            </StyledTypography>
          </a>
        </Stack>
        <Stack maxWidth="md" margin="auto">
          {stepsUserFirstAccess[currentStep].step()}
        </Stack>
      </Stack>
    </StepLayout>
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { animated, useSpring } from 'react-spring';
import PageHeader, { HeaderRouteProps } from '../../PageHeader';

import HapvidaNdiLogo from '../../../assets/images/hapvida-ndi-logo-new.svg';
import HapvidaNdiLogoWhite from '../../../assets/images/hapvida-ndi-logo-light-new.svg';

import Steps from './components/Steps';
import {
  PageContainer,
  Drawer,
  LogoContainer,
  FirstStepContent,
  StyledStack,
} from './styles';
import Typography from '../../Typography';
import { UserType } from '../DrawerLayout';
import Icon from '../../Icon';
import { COLORS } from '../../../themes/colors';

interface StepLayoutProps {
  user?: {
    profile: string;
    name: string;
  };
  steps: Array<{
    label: string;
    createdAt?: string;
    completed: boolean;
    blocked?: boolean;
  }>;
  route?: HeaderRouteProps;
  subRoute?: string | null;
  firstStepTitle?: {
    name?: string;
    icon?: React.JSX.Element;
  };
  currentStep: number;
  sideBarTitle?: string;
  sideBarSubtitle?: React.ReactNode;
  startImage?: string;
  hasAnimation?: boolean;
  onLogOut?(): void;
  onStepChange(step: number): void;
  hasHeader?: boolean;
  hasFirstStep?: boolean;
  enableOnClickLogo?: boolean;
  customRedirectRoute?: string;
  firstStepColor?: string;
  textContentColor?: string;
  drawerFirstStep?: boolean;
}

const StepLayout: React.FC<StepLayoutProps> = ({
  user,
  children,
  steps,
  currentStep,
  subRoute,
  route,
  firstStepTitle,
  sideBarTitle,
  sideBarSubtitle,
  startImage,
  hasAnimation,
  onLogOut,
  onStepChange,
  hasHeader = true,
  hasFirstStep = true,
  enableOnClickLogo = false,
  firstStepColor,
  customRedirectRoute,
  textContentColor,
  drawerFirstStep = false,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(!!hasAnimation);
  const [drawerModal, setDrawerModal] = useState(true);
  const contentPageAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: !!hasAnimation ? 2400 : 0,
    config: {
      duration: 800,
    },
  });

  const isFirstStep = () => {
    if (drawerFirstStep) {
      return true;
    }
    if (currentStep === 0 && hasFirstStep) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      setDrawerOpen(false);
    }, 2000);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const handleClick = () =>
    navigate(customRedirectRoute ? customRedirectRoute : '/cotacoes');

  return (
    <PageContainer>
      <Drawer
        isFirstStep={isFirstStep()}
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        showImage={drawerOpen}
        open={drawerModal}
        transitionDuration={400}
        startImage={startImage}
        firstStepColor={firstStepColor}
      >
        <Stack
          alignItems="center"
          sx={
            isMobile
              ? { padding: '16px 16px' }
              : { padding: '16px 80px', maxWidth: 500 }
          }
          gap={6}
        >
          <LogoContainer
            direction="row"
            justifyContent="space-between"
            pt="2px"
          >
            {drawerFirstStep ? (
              <img
                src={HapvidaNdiLogo}
                alt="Hapvida"
                onClick={enableOnClickLogo ? handleClick : undefined}
                width={295}
              />
            ) : (
              <img
                src={isFirstStep() ? HapvidaNdiLogoWhite : HapvidaNdiLogo}
                alt="Hapvida"
                onClick={enableOnClickLogo ? handleClick : undefined}
                width={295}
              />
            )}

            {isMobile && (
              <Icon
                name="close"
                size={32}
                color={COLORS.BLACK}
                onClick={() => setDrawerModal(!drawerModal)}
              />
            )}
          </LogoContainer>
        </Stack>
        {isFirstStep() ? (
          <FirstStepContent gap={2} textContentColor={textContentColor}>
            <Typography variant="h4">{sideBarTitle}</Typography>
            <Typography variant="text">{sideBarSubtitle}</Typography>
          </FirstStepContent>
        ) : (
          <Stack
            sx={
              isMobile
                ? { padding: '16px 16px' }
                : { padding: '16px 80px', maxWidth: 500 }
            }
          >
            <Steps
              activeStep={currentStep}
              steps={steps}
              onStepChange={onStepChange}
            />
          </Stack>
        )}
      </Drawer>
      <StyledStack>
        <animated.div style={contentPageAnimation}>
          {hasHeader && (
            <PageHeader
              user={user}
              onLogOut={onLogOut}
              route={isFirstStep() && firstStepTitle ? firstStepTitle : route}
              subRoute={subRoute}
              drawerOpen={drawerModal}
              toggleDrawer={() => setDrawerModal(!drawerModal)}
              drawerCloseWidth={500}
              drawerOpenWidth={500}
            />
          )}
          <Box paddingX={4} paddingY={5}>
            {children}
          </Box>
        </animated.div>
      </StyledStack>
    </PageContainer>
  );
};

export default StepLayout;

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Icon, Mask, Pill } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { COMPANY_USER_REGISTER_STATUS_DATA } from '@services/CompanySetupService/dtos/ListCompanyDTO';
import { CompanyProps } from '@services/AuthService/dtos';

import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';
import NewCompanyIcon from '@assets/images/new-company-icon.svg';
import { useSelectCompany } from '@flows/private/companySetup/pages/CompanySetupList/hooks';

import { AvatarStyled, EditIcon, Wrapper } from './styles';

interface CompanyLineItemProps {
  company: CompanyProps;
}

export function CompanyLineItem({ company }: CompanyLineItemProps) {
  const { user } = useAuth();
  const isBackoffice = user?.companyType === UserType.Backoffice;
  const { handleSelectCompany } = useSelectCompany();

  const hasCompanyStatus =
    company?.status &&
    COMPANY_USER_REGISTER_STATUS_DATA[company.status] &&
    isBackoffice;

  const showNewCompanyIcon = company.isNewCompany && !isBackoffice;

  return (
    <Wrapper
      key={company.id}
      style={{
        height: '82px',
      }}
    >
      <Grid container item alignItems="center">
        <Grid xs={6} item>
          <Box ml={2} display="flex" alignItems="center" gap={2}>
            {showNewCompanyIcon ? (
              <img
                width={36}
                height={36}
                src={NewCompanyIcon}
                alt="new-company"
              />
            ) : (
              <AvatarStyled alt={company?.legal}>
                <Icon
                  name="building"
                  color={COLORS.PRIMARY.BLUE['-1']}
                  size={23}
                />
              </AvatarStyled>
            )}

            <Typography
              variant="text"
              fontWeight={600}
              color={COLORS.MONOCHROMATIC.GRAY4}
            >
              {company.legal}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={3} item>
          <Typography variant="smallText" color={COLORS.MONOCHROMATIC.GRAY4}>
            {Mask.cnpj(company.document)}
          </Typography>
        </Grid>

        <Grid xs={2} item pl={2}>
          {hasCompanyStatus && (
            <Pill
              fitContent
              text={COMPANY_USER_REGISTER_STATUS_DATA[company.status].text}
              color={COMPANY_USER_REGISTER_STATUS_DATA[company.status].color}
            />
          )}
        </Grid>

        <Grid item xs={1}>
          <Stack alignItems="center">
            <EditIcon
              name="chevron-right"
              variant="outlined"
              size={24}
              onClick={() =>
                handleSelectCompany({
                  ...company,
                  name: company.legal ?? company.name,
                })
              }
            />
          </Stack>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

import ConfirmationStep from './steps/Confirmation/step';
import MovimentationFileStep from './steps/MovimentationFile/step';
import StartMovimentationStep from './steps/StartMovimentation/step';

export interface IMovimentationStep {
  name?: string;
  step(props: any): React.ReactElement;
}

export const steps: Array<IMovimentationStep> = [
  StartMovimentationStep,
  MovimentationFileStep,
  ConfirmationStep,
];

import { IAppDynamicRoute } from 'routes';

import RegisterStepsPage from '.';

const RegisterRouter: IAppDynamicRoute = {
  Page: RegisterStepsPage,
  path: '/cadastro/:registerStep',
  name: 'Cadastrar empresa',
  dynamicPath: ({ registerStep }) => `/cadastro/${registerStep}`,
  Icon: <></>,
  userTypes: [],
};

export default RegisterRouter;

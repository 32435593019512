import { Icon } from '@hapvida/hapvida-core-components';

import { UserType } from 'contexts/AuthContext';
import { IAppDynamicRoute } from 'routes';

import { CompanySetupDetailsPage } from '.';

export const CompanySetupDetailsRouter: IAppDynamicRoute = {
  Page: CompanySetupDetailsPage,
  path: '/empresas/:companyId',
  dynamicPath: ({ companyId }: { companyId: string }) =>
    `/empresas/${companyId}`,
  name: '',
  isPrivate: true,
  Icon: <Icon name="home-work" />,
  userTypes: [UserType.Backoffice],
  disabled: false,
};

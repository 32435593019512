export const changeFilesServiceLinks = [
  {
    label: 'Hapvida',
    value: 'https://webhap.hapvida.com.br/pls/webhap/webNewTrocaArquivo.login',
  },
  {
    label: 'CCG',
    value: 'https://sigo.sh.srv.br/pls/webccg/webNewTrocaArquivo.login',
  },
  {
    label: 'Clinipam',
    value: 'https://sigo.sh.srv.br/pls/webcli/webNewTrocaArquivo.login',
  },
];
